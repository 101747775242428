// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"

function renderDefaultMessage(type) {
  switch (type) {
    case "success":
      return "Success."

    case "error":
      return "An error has occured. Please try again."

    default:
      return ""
  }
}

const initialState = {
  toast: {
    message: null,
    type: null
  },
  system: {
    message: null,
    type: null
  },
  type: null
}

// export function handleToast(message) {
//   dispatch(setSystemAlert(message))
// }

export const alertSlice = createSlice({
  name: "alert",
  initialState: initialState,

  reducers: {
    setToast: (state, action) => {
      state.toast = {
        message: action.payload.message
          ? action.payload.message
          : renderDefaultMessage(action.payload.type),
        type: action.payload.type
      }
    },
    setSystemAlert: (state, action) => {
      state.system.message = action.payload.message
      state.system.type = action.payload.type
    },
    clearReducer: () => initialState
  },
  extraReducers: (builder) => {}
})

export const { clearAlert, setSystemAlert, setToast } = alertSlice.actions

export default alertSlice.reducer
