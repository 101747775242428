import { lazy } from "react";
import { lazyRetry } from "../../utility/Utils";

// USERS IMPORTS
import usersRoutes from "../../views/users/routes";

// USER IMPORTS
import userRoutes from "../../views/user/routes";

// POSITION IMPORTS
import positionRoutes from "../../views/position/routes";

// REPORTS IMPORTS
import reportsRoutes from "../../views/reports/routes";

// OCCUPATIONS IMPORTS
import occupationsRoutes from "../../views/occupations/routes";

// PAYMENT IMPORTS
import paymentRoutes from "../../views/payments/routes";

// PROPOSAL IMPORTS
import proposalRoutes from "../../views/proposal/routes";

// AFFILIATE IMPORTS
import affiliateRoutes from "../../views/affiliate/routes";

// SETTINGS IMPORTS
import settingsRoutes from "../../views/settings/routes";

// MANAGER LEVELS IMPORTS
import managerLevelsRoutes from "../../views/managerLevels/routes";

// PROJECT IMPORTS
import projectRoutes from "../../views/project/routes";

// PROJECTS IMPORTS
import projectsRoutes from "../../views/projects/routes";

// PROPOSALS IMPORTS
import proposalsRoutes from "../../views/proposals/routes";

// POSITIONS IMPORTS

const BrowsePositions = lazy(() =>
  lazyRetry(() =>
    import("../../views/positions/BrowsePositions/BrowsePositions")
  )
);

// OTHER IMPORTS
const Applications = lazy(() =>
  lazyRetry(() =>
    import("../../views/applications/pages/Applications/Applications")
  )
);

const Todos = lazy(() =>
  lazyRetry(() => import("../../views/todos/pages/Todos/Todos"))
);
const Chat = lazy(() => lazyRetry(() => import("../../views/chat/index")));
const Rates = lazy(() => lazyRetry(() => import("../../views/rates/Rates")));

const UpdateCareer = lazy(() =>
  lazyRetry(() => import("../../views/career/updateCareer/Index"))
);

// HOME
const Home = lazy(() => lazyRetry(() => import("../../views/Home")));

const ApplyForProjectManager = lazy(() =>
  lazyRetry(() => import("../../views/auth/pages/applyForProjectManager/Index"))
);

const appRoutes = [
  // HOME
  {
    path: "/dashboard",
    element: <Home />,
    meta: {
      private: true,
      title: "Dashboard",
      roles: [
        "Project Owner",
        "Referrer",
        "Project Manager",
        "Contractor",
        "Admin",
      ],
    },
  },
  // AFFILIATE ROUTES
  ...affiliateRoutes,

  // PROJECT ROUTES
  ...projectRoutes,

  // PROJECT ROUTES
  ...projectsRoutes,

  // PROPOSAL ROUTES
  ...proposalRoutes,

  // PROPOSALS IMPORTS
  ...proposalsRoutes,

  // POSITION ROUTES
  ...positionRoutes,

  {
    path: "/positions",
    element: <BrowsePositions />,
    meta: {
      private: true,
      roles: ["Contractor"],
    },
  },
  // CAREER ROUTES
  {
    path: "/update-career",
    element: <UpdateCareer />,
    meta: {
      private: true,
      roles: ["Contractor", "Project Manager"],
    },
  },
  // SETTINGS ROUTES
  ...settingsRoutes,
  // CHAT ROUTES
  {
    path: "/chat",
    element: <Chat />,
    meta: {
      private: true,
      roles: ["Project Owner", "Project Manager", "Contractor", "Admin"],
      appLayout: true,
      className: "chat-application",
    },
  },
  // APPLICATIONS ROUTES
  {
    path: "/applications",
    element: <Applications />,
    meta: {
      private: true,
      roles: ["Contractor"],
    },
  },
  // PAYMENTS ROUTES
  ...paymentRoutes,

  // USER ROUTES
  ...userRoutes,

  // USERS ROUTES
  ...usersRoutes,

  // REPORTS ROUTES
  ...reportsRoutes,

  // OCCUPATIONS ROUTES
  ...occupationsRoutes,

  ...managerLevelsRoutes,

  // TODOS ROUTES
  {
    path: "/todos",
    element: <Todos />,
    meta: {
      private: true,
      roles: ["Project Owner", "Project Manager", "Contractor"],
      className: "chat-application",
      appLayout: true,
    },
  },
  // RATINGS ROUTES
  {
    path: "/my-ratings",
    element: <Rates />,
    meta: {
      private: true,
      roles: ["Project Manager", "Contractor", "Project Owner"],
    },
  },
  {
    path: "/register-project-manager/apply",
    element: <ApplyForProjectManager />,
    meta: {
      layout: "blank",
      private: true,
      roles: ["Contractor"],
    },
  },
];

export default appRoutes;
