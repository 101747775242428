import axiosClient from "api/client";

export const fetchProject = async ({ id }) => {
  const response = await axiosClient.get(`/projects/${id ? id : ""}`);
  return response;
};

export const writeProjectPosition = async ({
  method,
  id,
  positionId,
  primarySkill,
  secondarySkill,
  additionalSkills,
  position,
  bonus,
}) => {
  let body = {
    position_id: position.value.id,
    seniority_id: position.level.id,
    skills: [
      {
        skill: {
          type: "PRIMARY",
          skill_name: primarySkill.skill.value,
        },
      },
      {
        skill: {
          type: "SECONDARY",
          skill_name: secondarySkill.skill.value,
        },
      },
      ...additionalSkills.map((s) => {
        return {
          skill: { skill_name: s.value, type: "ADDITIONAL" },
        };
      }),
    ],
    completion_bonus: bonus,
  };
  const response =
    method === "post"
      ? await axiosClient.post(`/projects/${id}/positions`, body)
      : method === "put"
      ? await axiosClient.put(`/projects/${id}/positions/${positionId}`, body)
      : "";
  return response;
};

export const countPositionRemainingBudget = async ({
  id,
  when,
  positionId,
  leaverId,
}) => {
  let obj = {
    when: when,
    leaver_id: leaverId,
  };
  const response = await axiosClient.post(
    `/projects/${id}/positions/${positionId}/leave-requests/calculate`,
    obj
  );
  return response;
};

export const getLeaveTypeAvailability = async ({ id, positionId }) => {
  const response = await axiosClient.get(
    `/projects/${id}/positions/${positionId}/leave-requests/whens`
  );
  return response;
};
