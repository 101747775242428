// ** Redux Imports
import { createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axiosClient from "../../../api/client";
import { getCareer } from "../../career/reducer/career.actions";

export const loginUser = async (body) => await axiosClient.post("/login", body);

export const getMe = async () => await axiosClient.get("/me");

export const registerUser = async (body) => {
  return await axiosClient.post("/register", body);
};
export const swapRole = async (role) => {
  return await axiosClient.put("/swap-role", { role_name: role });
};
export const createRole = async (role) => {
  return await axiosClient.post("/auth/roles", { role_name: role });
};
export const createContractorRole = async () => {
  return await axiosClient.post("/auth/roles/contractor");
};
export const createAffiliateRole = async () => {
  return await axiosClient.post("/auth/roles/referrer");
};

export const changeStatusTest = async (status) => {
  return await axiosClient.put("/set-active-status", {
    active_status: status,
  });
};

export const refreshUser = createAsyncThunk(
  "authentication/refreshUser",
  async (id, { dispatch }) => {
    try {
      let response = await getMe();
      let activeRole = response.data?.active_role?.role_name
        ? response.data.active_role.role_name
        : null;

      return { userData: response.data, selectedRole: activeRole };
    } catch (err) {
      console.log(err);
      return err.response.data;
    }
  }
);

export const changeStatus = createAsyncThunk(
  "authentication/changeStatus",
  async (status) => {
    try {
      // available
      // busy
      // not_available
      const response = await axiosClient.put("/set-active-status", {
        active_status: status,
      });

      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const updateUser = async (body) => {
  return await axiosClient.post("/me", body);
};
export const updateUserAvatar = async (body) => {
  return await axiosClient.post("/me", body);
};
export const resendEmail = async () => {
  return await axiosClient.post("/email/verify/resend");
};
export const verifyEmail = async (token) => {
  return await axiosClient.get(`/email/verify/${token}`);
};
export const validateOauth = async (type, token, referralCode) => {
  return await axiosClient.get(
    `/validate/${type}${token}${
      referralCode ? `?referral_code=${referralCode}` : ""
    }`
  );
};
export const sendResetEmail = async (email, token) => {
  return await axiosClient.post("/password/email", {
    email: email,
    "g-recaptcha-response": token,
  });
};
export const cancelContractorRole = async () => {
  return await axiosClient.delete(`/auth/roles/4`);
};
export const passwordReset = async (token, password, confirmPassword) => {
  return await axiosClient.post("/password/reset", {
    token: token,
    password: password,
    password_confirmation: confirmPassword,
  });
};

export const handleUpdate = createAsyncThunk(
  "authentication/update",
  async (body, { dispatch, state }) => {
    try {
      axiosClient
        .put("/me", body)
        .then((res) => {
          if (
            state.selectedRole === "Project Manager" ||
            state.selectedRole === "Contractor"
          ) {
            dispatch(getCareer(state.selectedRole));
          }
          localStorage.setItem("userData", JSON.stringify(res.data.user));
          return { userData: res.data.user };
        })
        .catch((err) => {
          return err.message;
        });
    } catch (err) {
      return err.message;
    }
  }
);
export const handleLogin = createAsyncThunk(
  "authentication/login",
  async (user, { dispatch }) => {
    try {
      let activeRole = user?.active_role?.role_name
        ? user.active_role.role_name
        : null;
      if (
        activeRole &&
        (activeRole === "Project Manager" || activeRole === "Contractor")
      ) {
        dispatch(getCareer(activeRole));
      }
      return { userData: user, selectedRole: activeRole };
    } catch (err) {
      return err.message;
    }
  }
);

export const switchUserRole = createAsyncThunk(
  "authentication/switchRole",
  async (role, { dispatch }) => {
    try {
      swapRole(role)
        .then((res) => {
          if (
            res.data.user.active_role.role_name &&
            (res.data.user.active_role.role_name === "Project Manager" ||
              res.data.user.active_role.role_name === "Contractor")
          ) {
            dispatch(getCareer(res.data.user.active_role.role_name));
          }
          return {
            userData: res.data.user,
            selectedRole: res.data.user.active_role.role_name,
          };
        })
        .catch((err) => {
          return err.message;
        });
    } catch (err) {
      return err.message;
    }
  }
);
