// ** React Imports
import { NavLink } from "react-router-dom"

// ** Third Party Components
import classnames from "classnames"
import { useTranslation } from "react-i18next"

// ** Reactstrap Imports
import { Badge } from "reactstrap"
import { useSelector } from "react-redux"

const VerticalNavMenuLink = ({ item, activeItem }) => {
  const newChats = useSelector((state) => state.chat.chat.new)
  const newTodos = useSelector((state) => state.todos.new)

  // ** Conditional Link Tag, if item has newTab or externalLink props use <a> tag else use NavLink
  const LinkTag = item.externalLink ? "a" : NavLink

  // ** Hooks
  const { t } = useTranslation()

  return (
    <li
      className={classnames({
        "nav-item": !item.children,
        disabled: item.disabled,
        active: item.navLink === activeItem
      })}
    >
      <LinkTag
        className="d-flex align-items-center"
        target={item.newTab ? "_blank" : undefined}
        /*eslint-disable */
        {...(item.externalLink === true
          ? {
              href: item.navLink || "/"
            }
          : {
              to: item.navLink || "/",
              className: ({ isActive }) => {
                if (isActive && !item.disabled) {
                  return "d-flex align-items-center active"
                }
              }
            })}
        onClick={(e) => {
          if (
            item.navLink.length === 0 ||
            item.navLink === "#" ||
            item.disabled === true
          ) {
            e.preventDefault()
          }
        }}
      >
        {item.icon}
        <span className="menu-item text-truncate">{t(item.title)}</span>

        {item.id == "chat" && newChats ? (
          <Badge
            pill
            color="danger"
            className="mx-1"
            style={{ height: "22px" }}
          >
            {newChats}
          </Badge>
        ) : (
          ""
        )}
        {item.id == "todos" && newTodos ? (
          <Badge
            pill
            color="danger"
            className="mx-1"
            style={{ height: "22px" }}
          >
            {newTodos}
          </Badge>
        ) : (
          ""
        )}

        {item.badge && item.badgeText ? (
          <Badge className="ms-auto me-1" color={item.badge} pill>
            {item.badgeText}
          </Badge>
        ) : null}
      </LinkTag>
    </li>
  )
}

export default VerticalNavMenuLink
