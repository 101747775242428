import { DefaultRoute } from "../router/routes";
import moment from "moment";
import styles from "../assets/scss/exports.module.scss";

export function convertUnicode(input) {
  return input
    .replace("&amp;", /&/g)
    .replace("&lt;", /</g)
    .replace("&gt;", />/g);
}

const hasRefreshed = () => {
  return JSON.parse(
    window.sessionStorage.getItem("retry-lazy-refreshed") || "false"
  );
};

// lazyRetry function prevents chunkLoad Errors if app is updated and client's page is not refreshed
// this works only with route-based code splitting
export const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    let refreshed = hasRefreshed();
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem("retry-lazy-refreshed", "false"); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!refreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem("retry-lazy-refreshed", "true"); // we are now going to refresh
          // return window.location.reload(); // refresh the page
        }
        // TO DO
        reject(error); // there was an error
      });
  });
};

export function htmlDecode(input) {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => {
  if (!obj || Object.keys(obj).length === 0) {
    return true;
  } else {
    return false;
  }
};

export const isObjEqual = (obj1, obj2) => {
  if (
    obj1 &&
    obj2 &&
    typeof obj1 === "object" &&
    typeof obj2 === "object" &&
    Object.keys(obj1).length === Object.keys(obj2).length
  ) {
    let matchedKeys = [];
    Object.keys(obj1).forEach((key1) => {
      let keyMatch = false;
      Object.keys(obj2).forEach((key2) => {
        if (key1 === key2 && obj1[key1] === obj2[key2]) {
          keyMatch = true;
        }
      });
      if (!keyMatch) {
        return false;
      } else {
        matchedKeys.push(key1);
      }
    });

    if (Object.keys(obj1).length === matchedKeys.length) {
      return true;
    }
  }
  return false;
};

export const isStringEmpty = (string) =>
  typeof string === "object" ||
  !string ||
  string.length === 0 ||
  string.trim().length === 0
    ? true
    : false;

export const validateStringLength = (string, min, max) =>
  typeof string === "object" ||
  !string ||
  string.length >= max ||
  string.length < min
    ? false
    : true;

export function capitalizeString(str) {
  const arr = str.split(" ");

  //loop through each element of the array and capitalize the first letter.

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  //Join all the elements of the array back into a string
  //using a blankspace as a separator
  const str2 = arr.join(" ");
  return str2;
}

export const isPasswordValid = (string) => {
  let regex = /^(?=.*\d)(?=.*[!@#$%^&.,*-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

  return typeof string === "object" ||
    !string ||
    string.length === 0 ||
    string.trim().length === 0 ||
    !regex.test(string)
    ? false
    : true;
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

// ** Returns K format from a number
export const kFormatter = (num) => {
  return num > 999 ? `${(num / 1000).toFixed(1)}k` : num;
};

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

export const getTodaysDate = () => {
  return new Date().toISOString().slice(0, 10);
};

export function checkAdultDate() {
  let year = new Date().getFullYear();
  let month = new Date().getMonth();
  let day = new Date().getDate();
  return new Date(year - 18, month, day);

  // boolean for years
  // return new Date(year + 18, month - 1, day) <= new Date()
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */

// Create our number formatter.
const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  // minimumFractionDigits: 0 // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const formatPrice = (value) => {
  if (value && !isNaN(value)) {
    return formatter.format(value);
  }
  return "$" + value;
};

export function roundNumber(n) {
  return Math.round((n + Number.EPSILON) * 100) / 100;
}

export const formatMessageDate = (value) => {
  if (!value) return value;
  let fullFormatting = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  let formatting = {
    month: "short",
    day: "numeric",
  };

  if (new Date().toDateString() == new Date(value).toDateString()) {
    return getTime(value);
  }

  if (new Date().getFullYear() == new Date(value).getFullYear()) {
    return (
      new Intl.DateTimeFormat("en-US", formatting).format(new Date(value)) +
      ", " +
      getTime(value)
    );
  }

  return (
    new Intl.DateTimeFormat("en-US", fullFormatting).format(new Date(value)) +
    ", " +
    getTime(value)
  );
};

export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

export const getTime = (value) => {
  if (!value) return value;
  let date = new Date(value);

  let hours = date.getHours();
  let minutes = date.getMinutes();

  return `${hours < 10 ? `0${hours}` : hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  }`;
};

export const reverseFormatDate = (value) => {
  if (!value) return value;
  const testDate = moment(new Date(value)).format("yyyy-MM-DD");

  return testDate;
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData");
export const getUserData = () => JSON.parse(localStorage.getItem("userData"));
export const getUserToken = () => {
  let token = localStorage.getItem("accessToken");
  return token ? JSON.parse(token) : null;
};

export const roleExists = (role, roles) => {
  for (let i = 0; i < roles.length; i++) {
    if (roles[i].role_name === role) {
      return true;
    }
  }
  return false;
};

export const isUrlValid = (url) => {
  if (url.includes("http://") || url.includes("https://")) {
    return true;
  } else {
    return false;
  }
};
export const renderUrl = (url) => {
  if (url.includes("http://") || url.includes("https://")) {
    return url;
  } else {
    return `https://${url}`;
  }
};

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = () => {
  return DefaultRoute;
};

export const checkStepsForUserRole = (user, selectedRole, career) => {
  switch (selectedRole) {
    case "Project Owner":
      if (
        !user.name ||
        !user.date_of_birth ||
        !user.country_id ||
        !user.languages.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    case "Project Manager":
      if (
        !user.name ||
        !user.date_of_birth ||
        !user.languages.length > 0 ||
        !career.career_description ||
        !career.experience
      ) {
        return true;
      } else {
        return false;
      }
    case "Admin":
      return false;
    case "Contractor":
      if (
        !user.date_of_birth ||
        !user.languages.length > 0 ||
        !career.occupation_id ||
        career.skills.length === 0
      ) {
        return true;
      } else {
        return false;
      }
    default:
      return undefined;
  }
};
export const getRouteForLoggedInUser = (userRole) => {
  if (userRole === "Project Owner") {
    return "/register-project-owner/complete";
  } else if (userRole === "Contractor") {
    return "/register-contractor/complete";
  } else if (userRole === "Project Manager") {
    return "/register-project-manager/complete";
  } else {
    return getHomeRouteForLoggedInUser();
  }
};

export const getProjectState = (project) =>
  project.status
    ? project.status.status_name
    : project.project_visibility
    ? project.project_visibility.project_visibility_name
    : null;
export const getProjectStateColor = (project) =>
  project?.status?.color
    ? project.status.color
    : project?.project_visibility?.color
    ? project.project_visibility.color
    : null;

// ** React Select Theme Colors
export const selectThemeColors = (theme) => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary25: styles.primary, // for option hover bg-color
      primary: styles.primary, // for selected option bg-color
      neutral10: styles.primary, // for tags bg-color
      neutral20: "#ededed", // for input border-color
      neutral30: "#ededed", // for input hover border-color
    },
  };
};

// takes a {} object and returns a FormData object
export var objectToFormData = function (obj, form, namespace) {
  var fd = form || new FormData();
  var formKey;

  for (var property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + "[" + property + "]";
      } else {
        formKey = property;
      }
      if (Array.isArray(obj[property]) && !(obj[property] instanceof File)) {
        // if array
        for (var i = 0; i < obj[property].length; i++) {
          let arrFormKey = formKey + "[" + i + "]";
          var el = obj[property][i];

          if (el instanceof File || typeof obj[property][i] !== "object") {
            fd.append(arrFormKey, el);
          } else {
            objectToFormData(el, fd, arrFormKey);
          }
          // for (var prop in arr) {
          //   let arrPropFormKey = arrFormKey + "[" + prop + "]"
          //   if (typeof arr[prop] === "object" && !(arr[prop] instanceof File)) {
          //     // if the property is an object, but not a File,
          //     // use recursivity.
          //     objectToFormData(arr[prop], fd, prop)
          //   } else {
          //     // if it's a string or a File object
          //     fd.append(arrPropFormKey, arr[prop])
          //   }
          //   // fileData.append(`myArr[${i}][${prop}]`, arr[prop])
          // }
        }
      } else {
        if (
          typeof obj[property] === "object" &&
          !(obj[property] instanceof File)
        ) {
          // if the property is an object, but not a File,
          // use recursivity.
          objectToFormData(obj[property], fd, formKey);
        } else {
          // if it's a string or a File object
          fd.append(formKey, obj[property]);
        }
      }
    }
  }

  return fd;
};

export function getPositionReward(milestonePositions) {
  if (!milestonePositions) {
    return 0;
  }
  let reward = 0;
  for (let i = 0; i < milestonePositions.length; i++) {
    reward += parseInt(milestonePositions[i].reward);
  }
  return reward;
}

/**
 * Check if the given item has the given url
 * in one of its children
 *
 * @param item
 * @param activeItem
 */
export const hasActiveChild = (item, currentUrl, selectedRole) => {
  const { children } = item;

  if (!children) {
    return false;
  }

  for (const child of children) {
    if (child.children) {
      if (hasActiveChild(child, currentUrl)) {
        return true;
      }
    }

    // Check if the child has a link and is active
    if (
      child &&
      child.navLink &&
      currentUrl &&
      (child.navLink === currentUrl || currentUrl.includes(child.navLink)) &&
      (!child?.roles?.length > 0 ||
        (child.roles?.length > 0 &&
          child.roles.some((r) => r === selectedRole)))
    ) {
      return true;
    }
  }

  return false;
};

export function setCookie(cName, cValue, expDays) {
  let date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}

export function getCookie(cName) {
  const name = cName + "=";
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded.split("; ");
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
}
