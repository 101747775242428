import { lazy } from "react";
import { lazyRetry } from "utility/Utils";

const PositionPage = lazy(() =>
  lazyRetry(() => import("../pages/PositionPage/PositionPage"))
);
const PublicPositionPage = lazy(() =>
  lazyRetry(() => import("../pages/PublicPositionPage/PublicPositionPage"))
);
const PositionClosedPage = lazy(() =>
  lazyRetry(() => import("../pages/PositionClosedPage/PositionClosedPage"))
);

const positionRoutes = [
  // POSITION ROUTES
  {
    path: "/project/:id/position/:positionId",
    element: <PositionPage />,
    meta: {
      private: true,
      roles: ["Contractor", "Project Manager"],
    },
  },
  {
    path: "/jobs/:positionId",
    element: <PublicPositionPage />,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: false,
    },
  },
  {
    path: "/position-closed",
    element: <PositionClosedPage />,
    meta: {
      layout: "blank",
      private: true,
      roles: ["Contractor", "Project Manager"],
    },
  },
];

export default positionRoutes;
