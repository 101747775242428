import { createAsyncThunk } from "@reduxjs/toolkit"
import axiosClient from "../../../api/client"

export const getPosition = createAsyncThunk(
  "position/get",
  async ({ id, positionId }) => {
    try {
      const response = await axiosClient.get(
        `/projects/${id}/positions/${positionId}`
      )
      return response.data.position
    } catch (err) {
      return err.response.data.message
    }
  }
)
export const refreshPosition = createAsyncThunk(
  "position/refresh",
  async ({ id, positionId }) => {
    try {
      const response = await axiosClient.get(
        `/projects/${id}/positions/${positionId}`
      )
      return response.data.position
    } catch (err) {
      return err.response.data.message
    }
  }
)

export const getPublicPosition = createAsyncThunk(
  "position/get",
  async ({ positionId }) => {
    try {
      const response = await axiosClient.get(`/public/positions/${positionId}`)
      return response.data.position
    } catch (err) {
      return err.response.data.message
    }
  }
)

export const sendPositionApplication = async (
  projectId,
  positionId,
  description
) =>
  await axiosClient.post(
    `/projects/${projectId}/positions/${positionId}/apply`,
    {
      description: description
    }
  )

export const getPositionsCount = async () =>
  await axiosClient.get(`/projects/contractors/browse/count`)
