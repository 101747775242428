import { createSlice } from "@reduxjs/toolkit"
import { getTodos, getTodosCount } from "./todos.actions"

const initialState = {
  todos: null,
  page: 0,
  new: 0,
  loading: false,
  error: null
}

export const todosSlice = createSlice({
  name: "todos",
  initialState,
  reducers: {
    clearTodos: (state) => {
      return { ...initialState, new: state.new }
    },
    newTodo: (state, action) => {
      state.new = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getTodos.pending, (state) => {
        state.loading = true
      })
      .addCase(getTodos.fulfilled, (state, action) => {
        state.loading = false
        state.todos = action.payload
      })
      .addCase(getTodos.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(getTodosCount.fulfilled, (state, action) => {
        state.new = action.payload
      })
  }
})

// Action creators are generated for each case reducer function
export const { clearTodos, newTodo } = todosSlice.actions

export default todosSlice.reducer
