const instructionsEUR = {
  bank_name: "Banking Circle S.A.",
  bank_address: "2, Boulevard de la Foire L-1528 LUXEMBOURG",
  routing: "",
  account_number: "",
  swift_code: "",
  iban: "LU604080000097684159",
  bic: "BCIRLULL",
  account_type: "",
  beneficiary_name: "Quantum Workflow doo Beograd"
}
const instructionsUSD = {
  bank_name: "First Century Bank",
  bank_address: "1731 N Elm St  Commerce, GA 30529 USA",
  routing: "061120084",
  account_number: "4020359708810",
  account_type: "CHECKING",
  beneficiary_name: "Quantum Workflow doo Beograd"
}
const instructionsGBP = {
  bank_name: "Barclays",
  bank_address: "",
  routing: "",
  sort_code: "231486",
  account_number: "14488764",
  account_type: "",
  beneficiary_name: "Quantum Workflow doo Beograd"
}
const instructionsAED = {
  bank_name: "Standard Chartered",
  bank_address:
    "Standard Chartered Tower, Emaar Square Dubai, United Arab Emirates",
  routing: "",
  swift_code: "SCBLAEADXXX",
  iban: "AE510446420010001047728",
  account_number: "",
  account_type: "",
  beneficiary_name: "Quantum Workflow doo Beograd"
}
export const jobValueOptions = [
  {
    value: "400",
    label: "$400 +"
  },
  {
    value: "1000",
    label: "$1,000 +"
  },
  {
    value: "3000",
    label: "$3,000 +"
  },
  {
    value: "10000",
    label: "$10,000 +"
  }
]
export const milestonesNumberOptions = [
  {
    value: "1",
    label: "1"
  },
  {
    value: "2",
    label: "2"
  },
  {
    value: "3",
    label: "3"
  },
  {
    value: "4,5",
    label: "4-5"
  },
  {
    value: "6,8",
    label: "6-8"
  },
  {
    value: "9+",
    label: "9+"
  }
]
export const proposalsNumberOptions = [
  {
    value: "0",
    label: "No active proposals"
  },
  {
    value: "2",
    label: "2 or less"
  },

  {
    value: "5",
    label: "5 or less"
  },
  {
    value: "9",
    label: "9 or less"
  }
]
export const rateOptions = [
  {
    value: "9.5",
    label: "9.5 and higher"
  },
  {
    value: "9",
    label: "9 and higher"
  },
  {
    value: "8",
    label: "8 and higher"
  },
  {
    value: "7",
    label: "7 and higher"
  },
  {
    value: "6",
    label: "6 and higher"
  }
]
export const averageValueOptions = [
  {
    value: ",1000",
    label: "Less than $1000"
  },
  {
    value: "1000,3000",
    label: "$1000 - $3000"
  },
  {
    value: "3000,6000",
    label: "$3000 - $6000"
  },
  {
    value: "6000,10000",
    label: "$6000 - $10000"
  },
  {
    value: "10000,15000",
    label: "$10000 -$15000"
  },
  {
    value: "15000,20000",
    label: "$15000 - $20 000"
  },
  {
    value: "20000",
    label: "$20 000+"
  }
]

export const managerStatusOptions = [
  {
    value: "0",
    label: "Only with no active projects"
  },
  {
    value: "1",
    label: "Active on 1 project or less"
  },
  {
    value: "2",
    label: "Active on 2 projects or less"
  },
  {
    value: "3",
    label: "Active on 3 projects or less"
  }
]

export const levelOptions = [
  { value: "Basic", label: "Basic" },
  { value: "Independent", label: "Independent" },
  { value: "Proficient", label: "Proficient" },
  { value: "Native", label: "Native" }
]

export const requiredWorkersOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4-5", label: "4-5" },
  { value: "6-8", label: "6-8" },
  { value: "9+", label: "9+" }
]
