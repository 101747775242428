import { createSlice } from "@reduxjs/toolkit"
import {
  exploreProjects,
  getAdminProjects,
  getProjects
} from "./projects.actions"

const initialState = {
  projects: null,
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null
}

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    clearProjects: (state, action) => {
      state.projects = null
      state.status = "loading"
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getProjects.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getProjects.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.projects = action.payload
      })
      .addCase(getProjects.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(getAdminProjects.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getAdminProjects.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.projects = action.payload
      })
      .addCase(getAdminProjects.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(exploreProjects.pending, (state) => {
        state.status = "loading"
      })
      .addCase(exploreProjects.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.projects = action.payload
      })
      .addCase(exploreProjects.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
  }
})

// Action creators are generated for each case reducer function
export const { clearProjects } = projectsSlice.actions

export default projectsSlice.reducer
