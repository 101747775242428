// ** React Imports
import { Suspense } from "react";
import { Navigate } from "react-router-dom";

import { useSelector } from "react-redux";

// ** Utils
import { getHomeRouteForLoggedInUser, isObjEmpty } from "utility/Utils";

const PublicRoute = ({ children, route }) => {
  const user = useSelector((state) => state.authentication.userData);
  const selectedRole = useSelector(
    (state) => state.authentication.selectedRole
  );

  const restrictedRoute = route.meta && route.meta.restricted;

  if (!isObjEmpty(user) && restrictedRoute) {
    {
      return <Navigate to={getHomeRouteForLoggedInUser()} />;
    }
  }

  return <Suspense fallback={null}>{children}</Suspense>;
};

export default PublicRoute;
