// ** Redux Imports
import { createAsyncThunk } from "@reduxjs/toolkit"
import axiosClient from "../../../api/client"
import { notify, notifyContact } from "./chat"

export const chatAuth = createAsyncThunk("appChat/auth", async () => {
  const response = await axiosClient.post("/chat/auth")
  return response.data
})

// export const getUserProfile = createAsyncThunk(
//   "appChat/getUserProfile",
//   async (id) => {
//     const response = await axiosClient.post("/idInfo", {
//       id: id,
//       type: "user"
//     })
//     return response.data
//   }
// )

async function fetchMessages({ id, type, page }) {
  return await axiosClient.post("/chat/fetch-messages", {
    id: id,
    type: type,
    page: page
  })
}

export const getChatCount = createAsyncThunk(
  "appChat/getChatCount",
  async () => {
    const response = await axiosClient.get("/chat/count")
    return response.data.count
  }
)

export const getChatContacts = createAsyncThunk(
  "appChat/getChatContacts",
  async () => {
    const response = await axiosClient.get("/chat/contacts")
    return response.data
  }
)

export const getChatMessages = createAsyncThunk(
  "appChat/getChatMessages",
  async (obj) => {
    const response = await fetchMessages(obj)
    return { chat: response.data, type: obj.type }
  }
)

export const loadChatMessages = createAsyncThunk(
  "appChat/loadChatMessages",
  async (obj) => {
    const response = await fetchMessages(obj)
    return { chat: response.data, type: obj.type, offset: obj?.offset || null }
  }
)

export const seenChatMessages = createAsyncThunk(
  "appChat/seenChatMessages",
  async (id) => {
    const response = await axiosClient.post(`/chat/seen/${id}`)
    return id
  }
)

export const selectUserChat = createAsyncThunk(
  "appChat/selectUserChat",
  async (id, { getState, dispatch }) => {
    if (getState().authentication.userData.id === id) {
      return null
    }
    let contacts = getState().chat.contacts

    let c = null
    if (contacts && contacts.some((c) => c.id === id)) {
      for (let i = 0; i < contacts.length; i++) {
        if (contacts[i].id === id) {
          c = contacts[i]
        }
      }
    }
    const contactsResponse = await axiosClient.get(`/users/${id}`)

    dispatch(getChatMessages({ id: id, type: "user", page: 1 }))

    return { user: contactsResponse.data.user, contact: c }
  }
)

export const selectGroupChat = createAsyncThunk(
  "appChat/selectGroupChat",
  async (id, { getState, dispatch }) => {
    if (getState().project.project.id === id) {
      dispatch(getChatMessages({ id: id, type: "project", page: 1 }))
    }
  }
)

export const selectProjectChat = createAsyncThunk(
  "appChat/selectProjectChat",
  async (id, { getState, dispatch }) => {
    if (getState().project.project.id === id) {
      dispatch(getChatMessages({ id: id, type: "project", page: 1 }))
    }
    return getState().project.project
  }
)

export const getProjectChatContacts = createAsyncThunk(
  "appChat/getProjectChatContacts",
  async (id, { getState, dispatch }) => {
    if (getState().project.project.id === id) {
      const response = await axiosClient.get(`/projects/${id}/participants`)

      return response.data.participants
    }
  }
)

export const sendMsg = createAsyncThunk("appChat/sendMsg", async (obj) => {
  const response = await axiosClient.post("/chat/send-message", {
    id: obj.id,
    type: obj.type,
    message: obj.message,
    temporaryMsgId: obj.temp
  })
  return response.data.message
})

export const receiveMsg = createAsyncThunk(
  "appChat/receiveMsg",
  async (msg, { dispatch, getState }) => {
    let chat = getState().chat

    if (msg && chat?.contacts?.length > 0) {
      dispatch(notifyContact(msg))
      dispatch(notify())
    } else {
      dispatch(notify())
    }

    return msg
  }
)

export const receiveProjectMsg = createAsyncThunk(
  "appChat/receiveProjectMsg",
  async (msg, { dispatch, getState }) => {
    let chat = getState().chat

    if (chat?.project?.id === msg.to_id) {
      //   dispatch(notifyContact(msg))
      // } else {
      //   dispatch(notify())
    }

    return msg
  }
)
