import { createSlice } from "@reduxjs/toolkit";
import {
  approveManagerApplication,
  changeManagerLevel,
  getAffiliateUsers,
  getContractors,
  getManagerApplications,
  getManagers,
  getUsers,
  markAsHighRisk,
  rejectManagerApplication,
  suspendUser,
  unsuspendUser,
} from "./users.actions";

const initialState = {
  users: {
    loading: false,
    processing: false,
    value: null,
    error: false,
  },
  requests: {
    loading: false,
    processing: false,
    value: null,
    error: false,
  },
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    clearUsers: (state, action) => {
      state.users = initialState.users;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getManagers.pending, (state) => {
        state.users.loading = true;
      })
      .addCase(getManagers.fulfilled, (state, action) => {
        state.users.loading = false;
        state.users.value = action.payload;
      })
      .addCase(getManagers.rejected, (state, action) => {
        state.users.error = true;
        state.error = action.error.message;
      })
      .addCase(getContractors.pending, (state) => {
        state.users.loading = true;
      })
      .addCase(getContractors.fulfilled, (state, action) => {
        state.users.loading = false;
        state.users.value = action.payload;
      })
      .addCase(getContractors.rejected, (state, action) => {
        state.users.error = true;
        state.error = action.error.message;
      })
      .addCase(getUsers.pending, (state) => {
        state.users.loading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users.loading = false;
        state.users.value = action.payload;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.users.error = true;
        state.error = action.error.message;
      })
      .addCase(getAffiliateUsers.pending, (state) => {
        state.users.loading = true;
      })
      .addCase(getAffiliateUsers.fulfilled, (state, action) => {
        state.users.loading = false;
        state.users.value = action.payload;
      })
      .addCase(getAffiliateUsers.rejected, (state, action) => {
        state.users.loading = false;
        state.users.error = true;
        state.error = action.error.message;
      })
      .addCase(getManagerApplications.pending, (state) => {
        state.requests.loading = true;
      })
      .addCase(getManagerApplications.fulfilled, (state, action) => {
        state.requests.loading = false;
        state.requests.value = action.payload;
      })
      .addCase(getManagerApplications.rejected, (state, action) => {
        state.requests.loading = false;
        state.requests.error = action.error.message;
      })
      .addCase(suspendUser.fulfilled, (state, action) => {
        if (state?.users?.value?.data?.length > 0) {
          state.users.value.data = state.users.value.data.map((u) => {
            if (u.id === action.payload.id) {
              return { ...u, suspended_at: action.payload.suspended_at };
            } else {
              return u;
            }
          });
        }
      })
      .addCase(unsuspendUser.fulfilled, (state, action) => {
        if (state?.users?.value?.data?.length > 0) {
          state.users.value.data = state.users.value.data.map((u) => {
            if (u.id === action.payload.id) {
              return {
                ...u,
                suspended_at: action.payload.suspended_at,
              };
            } else {
              return u;
            }
          });
        }
      })
      .addCase(markAsHighRisk.fulfilled, (state, action) => {
        if (state?.users?.value?.data?.length > 0) {
          state.users.value.data = state.users.value.data.map((u) => {
            if (u.id === action.payload.id) {
              return {
                ...u,
                high_risked_at: action.payload.high_risked_at,
              };
            } else {
              return u;
            }
          });
        }
      })
      .addCase(changeManagerLevel.fulfilled, (state, action) => {
        if (state?.users?.value?.data?.length > 0) {
          state.users.value.data = state.users.value.data.map((u) => {
            if (u.id === action.payload.id) {
              return {
                ...action.payload,
              };
            } else {
              return u;
            }
          });
        }
      })
      .addCase(approveManagerApplication.pending, (state) => {
        state.requests.processing = true;
      })
      .addCase(approveManagerApplication.rejected, (state) => {
        state.requests.processing = false;
      })
      .addCase(approveManagerApplication.fulfilled, (state, action) => {
        state.requests.processing = false;
        if (state?.requests?.value?.data?.length > 0) {
          state.requests.value.data = state.requests.value.data.map((u) => {
            if (u.id === action.payload.id) {
              return {
                ...action.payload,
              };
            } else {
              return u;
            }
          });
        }
      })
      .addCase(rejectManagerApplication.pending, (state) => {
        state.requests.processing = true;
      })
      .addCase(rejectManagerApplication.rejected, (state) => {
        state.requests.processing = false;
      })
      .addCase(rejectManagerApplication.fulfilled, (state, action) => {
        state.requests.processing = false;
        if (state?.requests?.value?.data?.length > 0) {
          state.requests.value.data = state.requests.value.data.map((u) => {
            if (u.id === action.payload.id) {
              return {
                ...action.payload,
              };
            } else {
              return u;
            }
          });
        }
      });
  },
});

// Action creators are generated for each case reducer function
export const { clearUsers } = usersSlice.actions;

export default usersSlice.reducer;
