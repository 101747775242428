import { useRef } from "react";
import ReactDOM from "react-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import ComponentSpinner from "../spinner/Loading-spinner";

const InfiniteScrollWrapper = ({
  children,
  fetch,
  loading,
  style,
  className,
  hasMore,
}) => {
  const scrollContainer = useRef(null);

  function handleScrollY() {
    const scrollContainerElement = ReactDOM.findDOMNode(
      scrollContainer.current
    );

    if (scrollContainerElement) {
      if (
        // hasMore &&
        !loading &&
        scrollContainerElement.clientHeight >=
          scrollContainerElement.scrollHeight - scrollContainerElement.scrollTop
      ) {
        fetch();
      }
    }
  }

  return (
    <PerfectScrollbar
      ref={scrollContainer}
      component="div"
      style={{ ...style }}
      onScrollY={() => handleScrollY()}
      className={className || ""}
      options={{
        wheelPropagation: false,
      }}
    >
      <div className="mb-1">
        {children}
        {loading ? <ComponentSpinner /> : ""}
      </div>
    </PerfectScrollbar>
  );
};

export default InfiniteScrollWrapper;
