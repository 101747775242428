import { createSlice } from "@reduxjs/toolkit";
import { getAffiliateRewards, getAffiliateStats } from "./affiliate.actions";

const initialState = {
  stats: {
    loading: false,
    value: null,
    error: null,
  },
  rewards: {
    loading: false,
    value: null,
    error: null,
  },
  error: null,
};

export const affiliateSlice = createSlice({
  name: "affiliate",
  initialState,
  reducers: {
    clearAffiliate: (state, action) => initialState,
    clearAffiliateStats: (state, action) => {
      state.stats = initialState.stats;
    },
    newPayment: (state, action) => {
      state.new = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAffiliateStats.pending, (state) => {
        state.stats.loading = true;
      })
      .addCase(getAffiliateStats.fulfilled, (state, action) => {
        state.stats.loading = false;
        state.stats.value = action.payload;
      })
      .addCase(getAffiliateStats.rejected, (state, action) => {
        state.stats.loading = false;
        state.stats.error = action.error.message;
      })
      .addCase(getAffiliateRewards.pending, (state) => {
        state.rewards.loading = true;
      })
      .addCase(getAffiliateRewards.fulfilled, (state, action) => {
        state.rewards.loading = false;
        state.rewards.value = action.payload;
      })
      .addCase(getAffiliateRewards.rejected, (state, action) => {
        state.rewards.loading = false;
        state.rewards.error = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
export const { clearAffiliate, clearAffiliateStats, newPayment } =
  affiliateSlice.actions;

export default affiliateSlice.reducer;
