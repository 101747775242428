// ** Redux Imports
import rootReducer from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  reducer: rootReducer,
  devTools:
    process.env.REACT_APP_ENV === "local" ||
    process.env.REACT_APP_ENV === "development"
      ? true
      : false,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

// globalInterceptor(store)

export { store };
