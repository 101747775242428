import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../../api/client";
import { setToast } from "../../../redux/globalReducers/alert";

export const getAffiliateStats = createAsyncThunk(
  "affiliate/getAffiliateStats",
  async ({ id }) => {
    try {
      const response = await axiosClient.get(`/referrals/statistics/${id}`);
      return response.data;
    } catch (err) {
      return err.response.data.message;
    }
  }
);
export const getAffiliateRewards = createAsyncThunk(
  "affiliate/getAffiliateRewards",
  async ({ id, page }) => {
    try {
      const response = await axiosClient.get(`/referrals/rewards/${id}`);
      return response.data;
    } catch (err) {
      return err.response.data.message;
    }
  }
);
