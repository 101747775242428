import { lazy } from "react";
import { lazyRetry } from "utility/Utils";

const ManagerLevels = lazy(() =>
  lazyRetry(() => {
    return import("../pages/ManagerLevels");
  })
);

const managerLevelsRoutes = [
  {
    path: "/manager-levels",

    element: <ManagerLevels />,
    meta: {
      private: true,
      roles: ["Admin"],
    },
  },
];

export default managerLevelsRoutes;
