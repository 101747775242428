import AdminProfile from "assets/images/portrait/small/QW-support.png";

import { isObjEmpty, isStringEmpty, roleExists } from "utility/Utils";
import styles from "assets/scss/exports.module.scss";
import {
  formatMilestonePositionChanges,
  renderMilestonePositionState,
} from "views/project/common/project.functions";

export function redirectOauth(url) {
  window.location.href = `${process.env.REACT_APP_BASENAME}${url}`;
}

export function randomId() {
  return Math.random().toString(36).substr(2, 5);
}

export function writeAllRoles(user, roles, selectedRole) {
  let tempRoles = roles.map((r) => r.role_name);
  if (roleExists("Contractor", roles) && user.occupation) {
    for (let i = 0; i < tempRoles.length; i++) {
      if (tempRoles[i] === "Contractor") {
        tempRoles[i] = user.occupation.occupation_name;
        break;
      }
    }
  }
  let filteredRoles = tempRoles
    ? selectedRole === "Admin"
      ? tempRoles
      : tempRoles.filter((r) => r !== "Admin")
    : [];
  // let filteredRoles = tempRoles
  //   ? selectedRole === "Admin"
  //     ? tempRoles.join(", ")
  //     : tempRoles.filter((r) => r !== "Admin").join(", ")
  //   : ""
  return filteredRoles.map((r, i) => {
    return (
      <span key={i}>
        {r}
        {i === filteredRoles.length - 1 ? "" : ", "}
      </span>
    );
  });
}

export function parseLinks({ actions, colors, description, meta }) {
  // count total number of actionable words between the two *
  let asterixCount = (description.split("*").length - 1) / 2;
  let percCount = (description.split("%").length - 1) / 2;

  let asterixAvailable = false;
  let percAvailable = false;

  let asterixCounter = 0;
  let percCounter = 0;

  let lastSlicePosition = 0;

  let lastAsterixPosition = null;
  let currentAsterix = 0;

  let lastPercPosition = null;
  let currentPerc = 0;

  let objArray = [];

  // check if the count above matches an actual number of actions in description
  if (
    actions &&
    actions.length > 0 &&
    asterixCount > 0 &&
    actions.length === asterixCount
  ) {
    asterixAvailable = true;
  }

  // check if the count above matches an actual number of colors in description
  if (
    colors &&
    colors.length > 0 &&
    percCount > 0 &&
    colors.length === percCount
  ) {
    percAvailable = true;
  }

  // make an array out of actionable words
  for (let j = 0; j < description.length; j++) {
    let wordObj = {};

    if (description[j] === "*" && asterixAvailable) {
      currentAsterix++;

      // checking if current asterix is odd or even in order to determine position where to slice
      let even = currentAsterix % 2 === 0;
      let position = even ? j + 1 : j;

      wordObj = {
        text: description.slice(lastSlicePosition, position),
      };
      if (even) {
        if (lastAsterixPosition !== null) {
          asterixCounter++;
          wordObj = {
            ...wordObj,
            text: description.slice(lastAsterixPosition, position),
            action: actions[asterixCounter - 1],
          };
        }
      }

      objArray.push(wordObj);
      lastSlicePosition = position;
      lastAsterixPosition = position;
    } else if (percAvailable && description[j] === "%") {
      currentPerc++;

      let even = currentPerc % 2 === 0;
      let position = even ? j + 1 : j;

      wordObj = {
        text: description.slice(lastSlicePosition, position),
      };

      if (even) {
        if (lastPercPosition !== null) {
          percCounter++;
          wordObj = {
            ...wordObj,
            text: description.slice(lastPercPosition, position),
            color: colors[percCounter - 1],
          };
        }
      }

      objArray.push(wordObj);

      lastSlicePosition = position;
      lastPercPosition = position;
    } else {
      if (j === description.length - 1) {
        // add remaining part of original sentence
        wordObj = {
          text: description.slice(lastSlicePosition, description.length),
        };
        objArray.push(wordObj);
      }
    }
  }

  // iterate throught words array
  return objArray.length > 0
    ? objArray.map((d, i) => {
        // remove * symbol
        let text = d.text;
        if (d.action) {
          text = text.replaceAll("*", "");
        }
        if (d.color) {
          text = text.replaceAll("%", "");
        }
        const Tag = d.action ? (meta ? "span" : "a") : "span";
        let tagObj = d.action
          ? meta
            ? {
                className: "cursor-pointer text-primary",
                onClick: () => console.log(meta),
              }
            : { href: d.action }
          : {};
        if (d.color) {
          tagObj = {
            ...tagObj,
            style: {
              fontWeight: "bold",
              color: `${d.color?.solid || "grey"}`,
            },
          };
        }

        return (
          <Tag key={i} {...tagObj}>
            {text}
          </Tag>
        );
      })
    : description;
}

export function calculateProjectManagerCareerCompletion(career) {
  let profileCompletion = 0;
  if (career) {
    if (career.testimonials.length > 0) {
      profileCompletion = profileCompletion + 25;
    }
    if (career.achievements.length > 0) {
      profileCompletion = profileCompletion + 25;
    }
    if (!isObjEmpty(career.experience)) {
      profileCompletion = profileCompletion + 25;
    }
    if (!isStringEmpty(career.career_description)) {
      profileCompletion = profileCompletion + 25;
    }
  }
  return profileCompletion;
}

export function calculateContractorCareerCompletion(career) {
  let profileCompletion = 0;
  if (career) {
    if (career.skills.length > 0) {
      profileCompletion = profileCompletion + 20;
    }
    if (career.testimonials.length > 0) {
      profileCompletion = profileCompletion + 20;
    }
    if (career.achievements.length > 0) {
      profileCompletion = profileCompletion + 20;
    }
    if (career.occupation) {
      profileCompletion = profileCompletion + 20;
    }
    if (career.career_description) {
      profileCompletion = profileCompletion + 20;
    }
  }
  return profileCompletion;
}

export function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  let croppedImage = new File([u8arr], filename, { type: mime });
  return croppedImage;
}

export function getCroppedImg(image, crop) {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  const reader = new FileReader();
  canvas.toBlob((blob) => {
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      return dataURLtoFile(reader.result, "cropped.jpg");
    };
  });
}

export function getMilestonePosition(milestonePositions, activePositions) {
  for (let i = 0; i < milestonePositions.length; i++) {
    for (let j = 0; j < activePositions.length; j++) {
      if (
        activePositions[j] &&
        milestonePositions[i].project_position_id == activePositions[j].id
      ) {
        return formatMilestonePositionChanges({
          ...milestonePositions[i],
          state: renderMilestonePositionState(milestonePositions[i]),
        });
      }
    }
  }
  return null;
}

export function getMilestonePositionFromMilestoneId(
  milestoneId,
  milestonePositions
) {
  if (!milestonePositions?.length) return null;
  for (let k = 0; k < milestonePositions.length; k++) {
    if (milestonePositions[k].milestone_id === milestoneId) {
      return formatMilestonePositionChanges({
        ...milestonePositions[k],
        state: renderMilestonePositionState(milestonePositions[k]),
      });
    }
  }

  return null;
}

export function getMilestoneColor(m, mp, isProject) {
  if (!isProject) {
    if (mp) {
      return {
        solid: styles.primary,
        transparent: styles.primaryTransparent,
      };
    } else {
      return null;
    }
  }
  if (m?.milestone_status?.color) {
    return m.milestone_status.color;
  }

  return null;
}

export function renderChatAvatar(user) {
  if (user?.roles?.length && user?.roles.some((r) => r.id === 1)) {
    return AdminProfile;
  } else {
    return user.avatar;
  }
}
export function renderChatName(user) {
  if (user?.roles?.length && user?.roles.some((r) => r.id === 1)) {
    return "Customer Support";
  } else {
    return user.name;
  }
}
export function renderChatRole(user) {
  if (user?.roles?.length && user?.roles.some((r) => r.id === 1)) {
    return "Client Service";
  }
  return null;
}

// this function formats file for dispatch in case when both existing and new files are sent to backend
export function formatFileDispatch(file) {
  if (!file) {
    return {};
  }
  if (file?.file) {
    return { data: file.file };
  } else if (file?.id) {
    return { id: file.id };
  }
  return {};
}
