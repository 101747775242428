import { lazy } from "react";
import { lazyRetry } from "utility/Utils";

const ManagersList = lazy(() =>
  lazyRetry(() => import("../pages/ManagersList/ManagersList"))
);
const ContractorsList = lazy(() =>
  lazyRetry(() => import("../pages/ContractorsList/ContractorsList"))
);
const UserList = lazy(() =>
  lazyRetry(() => import("../pages/userList/UserList"))
);
const AdminManagers = lazy(() =>
  lazyRetry(() => import("../admin/AdminManagers/AdminManagers"))
);
const ManagerRequests = lazy(() =>
  lazyRetry(() => import("../admin/ManagerRequests/ManagerRequests"))
);
const AdminAffiliates = lazy(() =>
  lazyRetry(() => import("../admin/AdminAffiliates/AdminAffiliates"))
);

const usersRoutes = [
  {
    path: "/managers",
    element: <ManagersList />,
    meta: {
      private: true,
      roles: ["Project Owner"],
    },
  },
  {
    path: "/contractors",
    element: <ContractorsList />,
    meta: {
      private: true,
      roles: ["Project Manager"],
    },
  },
  {
    path: "/admin/users",
    element: <UserList />,
    meta: {
      private: true,
      roles: ["Admin"],
    },
  },
  {
    path: "/admin/users/affiliates",
    element: <AdminAffiliates />,
    meta: {
      private: true,
      roles: ["Admin"],
    },
  },
  {
    path: "/admin/users/managers",
    element: <AdminManagers />,
    meta: {
      private: true,
      roles: ["Admin"],
    },
    children: [
      {
        index: true,
        element: <ManagersList />,
        meta: {
          private: true,
          roles: ["Admin"],
        },
      },
      {
        path: "requests",
        element: <ManagerRequests />,
        meta: {
          private: true,
          roles: ["Admin"],
        },
      },
    ],
  },
];

export default usersRoutes;
