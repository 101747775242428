import { lazy } from "react";
import { lazyRetry } from "utility/Utils";

const ProposalsListWrapper = lazy(() =>
  lazyRetry(() =>
    import("../wrappers/ProposalsListWrapper/ProposalsListWrapper")
  )
);
const ProposalsList = lazy(() =>
  lazyRetry(() => import("../pages/ProposalsList/ProposalsList"))
);

const proposalsRoutes = [
  {
    path: "/my-proposals",
    element: <ProposalsListWrapper />,
    meta: {
      private: true,
      roles: ["Project Manager"],
    },
    children: [
      {
        path: "",
        element: <ProposalsList status={"pending"} />,
        meta: {
          private: true,
          roles: ["Project Manager"],
        },
      },
      {
        path: "expired",
        element: <ProposalsList status={"expired"} />,
        meta: {
          private: true,
          roles: ["Project Manager"],
        },
      },
      {
        path: "withdrawn",
        element: <ProposalsList status={"withdrawn"} />,
        meta: {
          private: true,
          roles: ["Project Manager"],
        },
      },
      {
        path: "accepted",
        element: <ProposalsList status={"accepted"} />,
        meta: {
          private: true,
          roles: ["Project Manager"],
        },
      },
      {
        path: "rejected",
        element: <ProposalsList status={"rejected"} />,
        meta: {
          private: true,
          roles: ["Project Manager"],
        },
      },
    ],
  },
];

export default proposalsRoutes;
