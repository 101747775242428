// ** React Imports
import { Link, useNavigate } from "react-router-dom";

// ** Redux actions
import { handleLogout } from "views/auth/reducer/authentication";

// ** Third Party Components
import {
  User,
  Settings,
  Power,
  Circle,
  Repeat,
  Plus,
  Info,
} from "react-feather";

// ** Reactstrap Imports
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import UserAvatar from "views/components/UserAvatar/UserAvatar";
import {
  changeStatus,
  handleLogin,
  swapRole,
} from "views/auth/reducer/auth.actions";
import { isObjEmpty, roleExists } from "utility/Utils";
import toast from "react-hot-toast";
import { useState } from "react";

const UserDropdown = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.authentication.userData);
  const selectedRole = useSelector(
    (state) => state.authentication.selectedRole
  );

  function switchProfile(role) {
    swapRole(role)
      .then((res) => {
        dispatch(handleLogin(res.data.user));
        navigate("/");
        toast.success(`Successfully switched to ${role}`);
      })
      .catch((err) => {
        toast.error(`Error switching to ${role}`);
      });
  }

  function handleChangeStatus(status) {
    dispatch(changeStatus(status));
  }

  const [open, setOpen] = useState(false);

  function navigateToUpdateCareer() {
    setOpen(!open);
    navigate("/update-career");
  }

  function toggleMenu(e) {
    if (e?.target?.id === "career-not-verified") {
      setOpen(false);
    } else {
      setOpen(!open);
    }
  }

  if (isObjEmpty(user)) {
    return <></>;
  }

  return (
    <Dropdown
      isOpen={open}
      toggle={(e) => toggleMenu(e)}
      tag="li"
      className="dropdown-user nav-item"
    >
      <DropdownToggle tag="div" className="d-flex cursor-pointer">
        <div
          className="user-nav d-sm-flex flex-column align-items-end d-none"
          style={{ marginRight: "0.8rem" }}
        >
          <span
            className="user-name fw-bold"
            style={{ marginBottom: "0.435rem" }}
          >
            {user.name}
          </span>
          <span className="user-status" style={{ fontSize: "smaller" }}>
            {selectedRole === "Contractor" && user.occupation
              ? user.occupation.occupation_name
              : selectedRole}{" "}
            {selectedRole === "Contractor" &&
            user.occupation &&
            !user.occupation.occupation_is_system ? (
              <Info
                id="career-not-verified"
                size={14}
                className="text-danger"
                onClick={() => navigateToUpdateCareer()}
                style={{ cursor: "help" }}
              />
            ) : (
              ""
            )}
          </span>
        </div>
        <UserAvatar
          img={user.avatar}
          content={user.name}
          // imgHeight="40"
          // imgWidth="40"
          status={user.is_online ? user.active_status : "offline"}
        />
      </DropdownToggle>
      <DropdownMenu end>
        <p className="text-uppercase help-text mb-0 px-1">Chat availability</p>
        <DropdownItem
          tag={"div"}
          onClick={(e) => handleChangeStatus("available")}
        >
          {user.active_status === "available" ? (
            <i className={`fa-solid fa-circle text-primary me-75`}></i>
          ) : (
            <Circle size={12} className="text-primary me-75" />
          )}
          <span className="align-middle">Available</span>
        </DropdownItem>
        <DropdownItem tag={"div"} onClick={(e) => handleChangeStatus("busy")}>
          {user.active_status === "busy" ? (
            <i className={`fa-solid fa-circle text-warning me-75`}></i>
          ) : (
            <Circle size={12} className="text-warning me-75" />
          )}
          <span className="align-middle">Busy</span>
        </DropdownItem>
        <DropdownItem
          tag={"div"}
          onClick={(e) => handleChangeStatus("not_available")}
        >
          {user.active_status === "not_available" ? (
            <i className={`fa-solid fa-circle text-danger me-75`}></i>
          ) : (
            <Circle size={12} className="text-danger me-75" />
          )}
          <span className="align-middle">Not available</span>
        </DropdownItem>

        <DropdownItem divider />
        {/* CHECK IF CONTRACTOR IS ABLE TO SWITCH TO MANAGER */}
        {user?.roles?.length > 1 &&
        selectedRole !== "Admin" &&
        roleExists("Admin", user.roles) ? (
          <DropdownItem
            className="w-100"
            onClick={() => switchProfile("Admin")}
          >
            <Repeat size={14} className="me-75" />
            <span className="align-middle">To admin profile</span>
          </DropdownItem>
        ) : (
          ""
        )}
        {user?.roles?.length > 1 &&
        roleExists("Project Manager", user.roles) &&
        selectedRole !== "Project Manager" ? (
          <DropdownItem
            className="w-100"
            onClick={() => switchProfile("Project Manager")}
          >
            <Repeat size={14} className="me-75" />
            <span className="align-middle">To manager profile</span>
          </DropdownItem>
        ) : (
          ""
        )}
        {user?.roles?.length > 1 &&
        roleExists("Project Owner", user.roles) &&
        selectedRole !== "Project Owner" ? (
          <DropdownItem
            className="w-100"
            onClick={() => switchProfile("Project Owner")}
          >
            <Repeat size={14} className="me-75" />
            <span className="align-middle">To project owner profile</span>
          </DropdownItem>
        ) : (
          ""
        )}
        {user?.roles?.length > 1 &&
        roleExists("Contractor", user.roles) &&
        selectedRole !== "Contractor" ? (
          <DropdownItem
            className="w-100"
            onClick={() => switchProfile("Contractor")}
          >
            <Repeat size={14} className="me-75" />
            <span className="align-middle">To contractor profile</span>
          </DropdownItem>
        ) : (
          ""
        )}
        {user?.roles?.length > 1 &&
        roleExists("Referrer", user.roles) &&
        selectedRole !== "Referrer" ? (
          <DropdownItem
            className="w-100"
            onClick={() => switchProfile("Referrer")}
          >
            <Repeat size={14} className="me-75" />
            <span className="align-middle">To affiliate profile</span>
          </DropdownItem>
        ) : (
          ""
        )}
        {!roleExists("Contractor", user.roles) &&
        selectedRole === "Project Manager" ? (
          <DropdownItem
            tag={Link}
            className="w-100"
            to={`/register-contractor/complete`}
          >
            <Plus size={14} className="me-75" />
            <span className="align-middle">Create contractor profile</span>
          </DropdownItem>
        ) : (
          ""
        )}

        <DropdownItem tag={Link} to={`/profiles/${user.id}`}>
          <User size={14} className="me-75" />
          <span className="align-middle">My profile</span>
        </DropdownItem>
        <DropdownItem tag={Link} to="/settings">
          <Settings size={14} className="me-75" />
          <span className="align-middle">Settings</span>
        </DropdownItem>
        <DropdownItem
          className="w-100"
          onClick={() => dispatch(handleLogout())}
        >
          <Power size={14} className="me-75" />
          <span className="align-middle">Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default UserDropdown;
