// ** React Imports
import { Fragment, useEffect, memo } from "react";

// ** Third Party Components
import classnames from "classnames";

// ** Store & Actions
import { useSelector, useDispatch } from "react-redux";
import {
  handleContentWidth,
  handleMenuCollapsed,
  handleMenuHidden,
} from "layouts/reducer/layout.reducer";

// ** Styles
import "animate.css/animate.css";
// import "../../../@core/scss/base/core/layouts/_content.scss"

const LayoutWrapper = (props) => {
  // ** Props
  const { children, routeMeta } = props;

  // ** Store Vars
  const dispatch = useDispatch();
  const store = useSelector((state) => state);

  const navbarStore = store.navbar;
  const layoutStored = store.layout;
  const contentWidth = store.layout.contentWidth;
  const transition = store.layout.routerTransition;
  //** Vars
  const appLayoutCondition =
    (layoutStored.layout === "horizontal" && !routeMeta) ||
    (layoutStored.layout === "horizontal" && routeMeta && !routeMeta.appLayout);
  const Tag = appLayoutCondition ? "div" : Fragment;

  return (
    <div
      className={classnames("app-content content overflow-hidden", {
        [layoutStored.className]: layoutStored.className,
        "show-overlay": navbarStore.query.length,
      })}
    >
      <div className="content-overlay"></div>
      <div className="header-navbar-shadow" />
      <div
        className={classnames({
          "content-wrapper h-screen": layoutStored.pageType !== "chat",
          "content-area-wrapper": layoutStored.pageType === "chat",
          "container-xxl p-0": contentWidth === "boxed",
          [`animate__animated `]: transition !== "none" && transition.length,
        })}
      >
        <Tag {...(appLayoutCondition ? { className: "content-body" } : {})}>
          {children}
        </Tag>
      </div>
    </div>
  );
};

export default memo(LayoutWrapper);
