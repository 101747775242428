import { lazy } from "react"
import { lazyRetry } from "../../../utility/Utils"

const OccupationsAdminWrapper = lazy(() =>
  lazyRetry(() =>
    import("../components/OccupationsAdminWrapper/OccupationsAdminWrapper")
  )
)
const AllOccupations = lazy(() =>
  lazyRetry(() => import("../pages/AllOccupations/AllOccupations"))
)
const OccupationRequests = lazy(() =>
  lazyRetry(() => import("../pages/OccupationRequests/OccupationRequests"))
)

const occupationsRoutes = [
  {
    path: "/admin/occupations",
    element: <OccupationsAdminWrapper />,
    meta: {
      private: true,
      roles: ["Admin"]
    },
    children: [
      {
        index: true,
        element: <AllOccupations />,
        meta: {
          private: true,
          roles: ["Admin"]
        }
      },
      {
        path: "requests",
        element: <OccupationRequests />,
        meta: {
          private: true,
          roles: ["Admin"]
        }
      }
    ]
  }
]

export default occupationsRoutes
