import { createSlice } from "@reduxjs/toolkit"
import {
  // getUserProfile,
  getChatContacts,
  getChatMessages,
  selectUserChat,
  selectGroup,
  chatAuth,
  selectProjectChat,
  seenChatMessages,
  sendMsg,
  receiveMsg,
  receiveProjectMsg,
  loadChatMessages,
  getChatCount,
  getProjectChatContacts
} from "./chat.actions"

const initialState = {
  auth: null,
  chat: {
    selectedUser: null,
    selectedContact: null,
    type: "",
    status: "idle",
    messages: null,
    new: 0,
    group: null,
    project: null
  },
  contacts: null,
  userProfile: {}
}

export const appChatSlice = createSlice({
  name: "appChat",
  initialState: initialState,
  reducers: {
    addMessage: (state, action) => {
      if (state.chat) {
        return {
          ...state,
          chat: {
            ...state.chat,
            messages: state.chat.messages
              ? {
                  ...state.chat.messages,
                  messages: [action.payload, ...state.chat.messages.messages]
                }
              : null
          }
        }
      }
    },
    notifyContact: (state, action) => {
      return {
        ...state,
        chat: {
          ...state.chat,
          selectedContact: state.chat.selectedContact
            ? state.chat.selectedContact.id == action.payload.from_id
              ? {
                  ...state.chat.selectedContact,
                  unseen_count: state.chat.selectedContact.unseen_count + 1,
                  last_message: action.payload
                }
              : state.chat.selectedContact
            : state.chat.selectedContact
        },
        contacts:
          state.contacts &&
          state.contacts.map((c) => {
            if (c.id == action.payload.from_id) {
              return {
                ...c,
                unseen_count: c.unseen_count + 1,
                last_message: action.payload
              }
            } else {
              return c
            }
          })
      }
    },
    notify: (state) => {
      return {
        ...state,
        chat: {
          ...state.chat,
          new: state.chat.new + 1
        }
      }
    },
    clearChat: (state) => {
      return {
        ...initialState,
        auth: state.auth,
        chat: { ...initialState.chat, new: state.chat.new }
      }
    },
    clearChatMessages: (state) => {
      return {
        ...state,
        chat: { ...initialState.chat, new: state.chat.new }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      // .addCase(getUserProfile.fulfilled, (state, action) => {
      //   state.userProfile = action.payload
      // })
      .addCase(getChatCount.fulfilled, (state, action) => {
        state.chat.new = action.payload
      })
      .addCase(getChatContacts.fulfilled, (state, action) => {
        state.contacts = action.payload.contacts
      })
      .addCase(getChatMessages.pending, (state, action) => {
        state.chat = {
          ...state.chat,
          status: "pending"
        }
      })
      .addCase(getChatMessages.fulfilled, (state, action) => {
        state.chat = {
          ...state.chat,
          status: "fulfilled",
          messages: action.payload.chat,
          type: action.payload.type
        }
      })
      .addCase(loadChatMessages.pending, (state, action) => {
        state.chat = {
          ...state.chat,
          status: "loading"
        }
      })
      .addCase(loadChatMessages.fulfilled, (state, action) => {
        state.chat = {
          ...state.chat,
          status: "loaded",
          messages: {
            ...state.chat.messages,
            offset: action.payload.offset,
            messages: [
              ...state.chat.messages.messages,
              ...action.payload.chat.messages
            ]
          }
        }
      })
      .addCase(selectUserChat.pending, (state, action) => {
        if (action.payload) {
          state.chat.status = "pending"
        }
      })
      .addCase(selectUserChat.fulfilled, (state, action) => {
        if (action.payload) {
          state.chat.selectedUser = action.payload.user
          state.chat.selectedContact = action.payload.contact
          state.chat.type = "user"
        }
      })
      .addCase(selectProjectChat.pending, (state, action) => {
        if (action.payload) {
          state.chat = {
            ...state.chat,
            status: "pending"
          }
        }
      })
      .addCase(selectProjectChat.fulfilled, (state, action) => {
        if (action.payload) {
          state.chat.project = action.payload
          state.chat.type = "project"
        }
      })
      .addCase(getProjectChatContacts.fulfilled, (state, action) => {
        if (action.payload) {
          state.contacts = action.payload
        }
      })
      .addCase(chatAuth.fulfilled, (state, action) => {
        state.auth = action.payload
      })
      .addCase(sendMsg.fulfilled, (state, action) => {
        if (state.chat) {
          return {
            ...state,
            contacts: state.contacts
              ? state.contacts.map((c) => {
                  if (c.id === action.payload.to_id) {
                    return {
                      ...c,
                      last_message: action.payload
                    }
                  }
                  return c
                })
              : state.contacts,
            chat: {
              ...state.chat,
              status: "sent",
              messages: state.chat.messages
                ? {
                    ...state.chat.messages,
                    messages: [action.payload, ...state.chat.messages.messages]
                  }
                : null
            }
          }
        }
      })
      .addCase(receiveMsg.fulfilled, (state, action) => {
        if (state.chat) {
          return {
            ...state,
            contacts: state.contacts
              ? state.contacts.map((c, i) => {
                  if (c.id === action.payload.from_id) {
                    return {
                      ...c,
                      last_message: action.payload
                    }
                  }
                  return c
                })
              : state.contacts,
            chat: {
              ...state.chat,
              status: "received",
              messages:
                state.chat?.selectedUser?.id === action.payload.from_id
                  ? {
                      ...state.chat.messages,
                      messages: [
                        action.payload,
                        ...state.chat.messages.messages
                      ]
                    }
                  : state.chat.messages
            }
          }
        }
      })
      .addCase(receiveProjectMsg.fulfilled, (state, action) => {
        if (state.chat) {
          return {
            ...state,
            chat: {
              ...state.chat,
              status: "received",
              messages:
                state.chat?.project &&
                state.chat?.project?.id === action.payload.to_id
                  ? {
                      ...state.chat.messages,
                      messages: [
                        action.payload,
                        ...state.chat.messages.messages
                      ]
                    }
                  : state.chat.messages
            }
          }
        }
      })
      .addCase(seenChatMessages.fulfilled, (state, action) => {
        let contactCount = state.chat.selectedContact.unseen_count
        let newCount = state.chat.new - contactCount
        state.chat.new = newCount >= 0 ? newCount : 0
        state.contacts = state.contacts.map((c) => {
          if (c.id === action.payload) {
            return {
              ...c,
              unseen_count: 0
            }
          }
          return c
        })
        state.chat.selectedContact = {
          ...state.chat.selectedContact,
          unseen_count: 0
        }
      })
  }
})

export const {
  clearChat,
  clearChatMessages,
  addMessage,
  notifyContact,
  notify
} = appChatSlice.actions

export default appChatSlice.reducer
