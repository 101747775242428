import { createSlice } from "@reduxjs/toolkit";
import {
  acceptProposal,
  createProposal,
  getProjectForProposal,
  getProposal,
  refreshProposal,
  rejectProposal,
  updateProposal,
} from "./proposal.actions";
import { apiErrorValue } from "redux/constants";

const initialState = {
  proposal: {
    value: null,
    loading: false,
    processing: false,
    error: apiErrorValue,
  },
  project: {
    value: null,
    loading: false,
    error: apiErrorValue,
  },
  form: {
    value: null,
    loading: false,
    processing: false,
    error: apiErrorValue,
  },
};

export const proposalSlice = createSlice({
  name: "proposal",
  initialState,
  reducers: {
    clearProposalCreate: (state, action) => {
      state.project = initialState.project;
      state.form = initialState.form;
    },
    setEditProposal: (state, action) => {
      state.form = {
        ...state.form,
        value: action.payload,
      };
    },
    failProposalCreate: (state, action) => {
      state.form.error = {
        isError: true,
        errorCode: action.payload.code || null,
        message: action.payload.message,
      };
    },
    clearProposal: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getProposal.pending, (state) => {
        state.proposal.loading = true;
      })
      .addCase(getProposal.fulfilled, (state, action) => {
        state.proposal.loading = false;
        state.proposal.value = action.payload;
      })
      .addCase(getProposal.rejected, (state, { payload }) => {
        state.proposal.loading = false;
        state.proposal.error = {
          isError: true,
          statusCode: payload.status,
          message: payload.data.message,
        };
      })
      .addCase(refreshProposal.fulfilled, (state, action) => {
        state.proposal.loading = false;
        state.proposal.value = action.payload;
      })
      .addCase(createProposal.pending, (state) => {
        state.form.processing = true;
      })
      .addCase(createProposal.fulfilled, (state, action) => {
        state.form.processing = false;
        // state.form.value = action.payload;
      })
      .addCase(createProposal.rejected, (state, { payload }) => {
        state.form.loading = false;
        state.form.error = {
          isError: true,
          statusCode: payload.status,
          message: payload.data.message,
        };
      })
      .addCase(updateProposal.pending, (state) => {
        state.form.processing = true;
      })
      .addCase(updateProposal.fulfilled, (state, action) => {
        state.form.processing = false;
        // state.form.value = action.payload;
      })
      .addCase(updateProposal.rejected, (state, { payload }) => {
        state.form.loading = false;
        state.form.error = {
          isError: true,
          statusCode: payload.status,
          message: payload.data.message,
        };
      })
      .addCase(getProjectForProposal.pending, (state) => {
        state.project.loading = true;
      })
      .addCase(getProjectForProposal.fulfilled, (state, action) => {
        state.project.loading = false;
        state.project.value = action.payload;
      })
      .addCase(getProjectForProposal.rejected, (state, { payload }) => {
        state.project.loading = false;
        state.project.error = {
          isError: true,
          statusCode: payload.status,
          message: payload.data.message,
        };
      })
      .addCase(acceptProposal.pending, (state) => {
        state.proposal.processing = true;
      })
      .addCase(acceptProposal.fulfilled, (state, action) => {
        state.proposal.processing = false;
      })
      .addCase(acceptProposal.rejected, (state, { payload }) => {
        state.proposal.processing = false;
        state.proposal.error = {
          isError: true,
          statusCode: payload.status,
          code: payload?.data?.code,
          message: payload?.data?.message,
        };
      })
      .addCase(rejectProposal.pending, (state) => {
        state.proposal.processing = true;
      })
      .addCase(rejectProposal.fulfilled, (state, action) => {
        state.proposal.processing = false;
      })
      .addCase(rejectProposal.rejected, (state, { payload }) => {
        state.proposal.processing = false;
        state.proposal.error = {
          isError: true,
          statusCode: payload.status,
          code: payload?.data?.code,
          message: payload?.data?.message,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  clearProposal,
  clearProposalCreate,
  failProposalCreate,
  setEditProposal,
} = proposalSlice.actions;

export default proposalSlice.reducer;
