import axiosClient from "../../../api/client"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { objectToFormData } from "../../../utility/Utils"

export const updateCareer = async (selectedRole, body) => {
  let role = null
  if (selectedRole === "Project Manager") {
    role = "managers"
  }
  if (selectedRole === "Contractor") {
    role = "contractors"
  }
  if (role) {
    return await axiosClient.post(`/careers/${role}`, body)
  } else {
    return false
  }
}

export const fetchCareer = async (userRole) => {
  return await axiosClient.get(
    `/careers/${
      userRole === "Project Manager"
        ? "managers"
        : userRole === "Contractor"
        ? "contractors"
        : ""
    }/my`
  )
}

export const managerApply = async (body) => {
  let formData = objectToFormData(body)

  return await axiosClient.post(`/careers/managers/apply`, formData)
}

export const getCareer = createAsyncThunk("career/get", async (userRole) => {
  try {
    const response = await axiosClient.get(
      `/careers/${
        userRole === "Project Manager"
          ? "managers"
          : userRole === "Contractor"
          ? "contractors"
          : ""
      }/my`
    )
    return response.data.career
  } catch (err) {
    return err.message
  }
})
