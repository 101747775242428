import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../../api/client";
import { setToast } from "../../../redux/globalReducers/alert";
import { isStringEmpty } from "../../../utility/Utils";

// INPUT
// search: "",
// rate: "",
// level: "",
// projectValue: "",
// status: "",
// location: "",
// speakingLanguage: "",
// timezone: "",
// industry: ""
export const getManagers = createAsyncThunk(
  "users/managers",
  async ({ page, input }) => {
    try {
      let params = {};
      input &&
        Object.keys(input).forEach((key) => {
          if (input[key]) {
            if (key === "rate") {
              params = { ...params, avg_rate: input[key].value };
            }
            if (key === "level") {
              params = { ...params, level: input[key].id };
            }
            if (key === "projectValue") {
              params = { ...params, avg_project_value: input[key].value };
            }
            if (key === "status" && !isStringEmpty(input[key].value)) {
              params = { ...params, project_count: input[key].value };
            }
            if (key === "location" && input[key].length > 0) {
              params = { ...params, country_id: input[key].map((s) => s.id) };
            }
            if (key === "timezone") {
              params = { ...params, timezone_id: input[key].id };
            }
            if (key === "industry" && input[key].length > 0) {
              params = { ...params, industry: input[key].map((s) => s.id) };
            }
            if (key === "speakingLanguage" && input[key].length > 0) {
              params = {
                ...params,
                language: input[key].map((s) => s.id),
              };
            }
            if (key === "search") {
              params = { ...params, name: input[key] };
            }
            if (key === "budget") {
              params = { ...params, project_budget_id: input[key] };
            }
          }
        });

      const response = await axiosClient.get(
        `/users/managers${page ? `?page=${page}` : ""}`,
        {
          params: params,
        }
      );
      return response.data.users;
    } catch (err) {
      return err.message;
    }
  }
);
export const getContractors = createAsyncThunk(
  "users/contractors",
  async ({ page, input }) => {
    try {
      let params = {};
      input &&
        Object.keys(input).forEach((key) => {
          if (input[key]) {
            if (key === "occupations" && input[key].length > 0) {
              params = { ...params, occupations: input[key].map((s) => s.id) };
            }
            if (key === "skills" && input[key].length > 0) {
              params = { ...params, skills: input[key].map((s) => s.id) };
            }
            if (key === "seniorities" && input[key].length > 0) {
              params = { ...params, seniorities: input[key].map((s) => s.id) };
            }
            if (key === "rate") {
              params = { ...params, contractor_avg_rate: input[key].value };
            }

            if (key === "status" && !isStringEmpty(input[key].value)) {
              params = {
                ...params,
                contractor_project_count: input[key].value,
              };
            }
            if (key === "location" && input[key].length > 0) {
              params = { ...params, country_id: input[key].map((s) => s.id) };
            }

            if (key === "timezone" && input[key].length > 0) {
              params = { ...params, timezone_id: input[key].map((s) => s.id) };
            }
            if (key === "industry" && input[key].length > 0) {
              params = {
                ...params,
                contractor_industries: input[key].map((s) => s.id),
              };
            }
            if (key === "speakingLanguage" && input[key].length > 0) {
              params = {
                ...params,
                language: input[key].map((s) => s.id),
              };
            }
            if (key === "search") {
              params = { ...params, name: input[key] };
            }
          }
        });

      const response = await axiosClient.get(
        `/users/contractors${page ? `?page=${page}` : ""}`,
        {
          params: params,
        }
      );
      return response.data.users;
    } catch (err) {
      return err.message;
    }
  }
);

export const getUsers = createAsyncThunk(
  "users/all",
  async ({ page, input }) => {
    try {
      let params = {};
      input &&
        Object.keys(input).forEach((key) => {
          if (input[key]) {
            if (key === "rate") {
              params = { ...params, avg_rate: input[key].value };
            }
            if (key === "level") {
              params = { ...params, level: input[key].id };
            }
            if (key === "projectValue") {
              params = { ...params, avg_project_value: input[key].value };
            }
            if (key === "status" && !isStringEmpty(input[key].value)) {
              params = { ...params, project_count: input[key].value };
            }
            if (key === "location" && input[key].length > 0) {
              params = { ...params, country_id: input[key].map((s) => s.id) };
            }
            if (key === "timezone") {
              params = { ...params, timezone_id: input[key].id };
            }
            if (key === "industry" && input[key].length > 0) {
              params = { ...params, industry: input[key].map((s) => s.id) };
            }
            if (key === "speakingLanguage" && input[key].length > 0) {
              params = {
                ...params,
                language: input[key].map((s) => s.id),
              };
            }
            if (key === "search") {
              params = { ...params, name: input[key] };
            }
          }
        });
      const response = await axiosClient.get(
        `/admin/users${page ? `?page=${page}` : ""}`,
        {
          params,
        }
      );
      return response.data.users;
    } catch (err) {
      return err.message;
    }
  }
);

export const suspendUser = createAsyncThunk(
  "users/suspend",
  async (id, { dispatch }) => {
    try {
      const response = await axiosClient.put(`/admin/users/${id}/suspend`);
      dispatch(setToast({ type: "", message: "User successfully banned." }));

      return response.data.user;
    } catch (err) {
      return err.message;
    }
  }
);
export const unsuspendUser = createAsyncThunk(
  "users/unsuspend",
  async (id, { dispatch }) => {
    try {
      const response = await axiosClient.put(`/admin/users/${id}/unsuspend`);
      dispatch(setToast({ type: "", message: "User successfully unbanned." }));
      return response.data.user;
    } catch (err) {
      return err.message;
    }
  }
);

export const markAsHighRisk = createAsyncThunk(
  "users/markAsHighRisk",
  async (id) => {
    try {
      const response = await axiosClient.put(`/admin/users/${id}/high-risk`);

      return response.data.user;
    } catch (err) {
      return err.message;
    }
  }
);

export const changeManagerLevel = createAsyncThunk(
  "users/changeManagerLevel",
  async ({ id, managerLevelId }) => {
    try {
      const response = await axiosClient.put(
        `/admin/users/${id}/update-manager-level`,
        {
          project_manager_level_id: managerLevelId,
        }
      );

      return response.data.user;
    } catch (err) {
      return err.message;
    }
  }
);

export const deleteUser = createAsyncThunk("users/deleteUser", async (id) => {
  try {
    const response = await axiosClient.delete(`/users/delete/${id}`);
    toast.success("User successfully deleted.");
    return response.data;
  } catch (err) {
    toast.error("You don't have privileges to delete this user.");
  }
});

export const getManagerApplications = createAsyncThunk(
  "users/getManagerApplications",
  async (page) => {
    try {
      const response = await axiosClient.get(
        `/admin/manager-applications${page ? `?page=${page}` : ""}`
      );
      return response.data.applications;
    } catch (err) {
      return err.message;
    }
  }
);
export const getAffiliateUsers = createAsyncThunk(
  "users/getAffiliateUsers",
  async (page) => {
    try {
      const response = await axiosClient.get(
        `/admin/users/referrers${page ? `?page=${page}` : ""}`
      );
      return response.data.users;
    } catch (err) {
      return err.message;
    }
  }
);

export const approveManagerApplication = createAsyncThunk(
  "users/approveManagerApplication",
  async ({ id }, { dispatch }) => {
    try {
      const response = await axiosClient.put(
        `/admin/manager-applications/${id}/approve`
      );
      dispatch(setToast({ type: "success", message: response.message }));

      return response.data;
    } catch (err) {
      dispatch(
        setToast({
          type: "error",
          message: err.response.data.message,
        })
      );

      return err.response.data;
    }
  }
);
export const rejectManagerApplication = createAsyncThunk(
  "users/rejectManagerApplication",
  async ({ id }, { dispatch }) => {
    try {
      const response = await axiosClient.put(
        `/admin/manager-applications/${id}/reject`
      );
      dispatch(setToast({ type: "success", message: response.message }));

      return response.data;
    } catch (err) {
      dispatch(
        setToast({
          type: "error",
          message: err.response.data.message,
        })
      );

      return err.response.data;
    }
  }
);
