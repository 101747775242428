import axios from "axios";
import { authHeader } from "./auth-header";
import * as Sentry from "@sentry/react";

const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASENAME}`,
});

axiosClient.defaults.headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: authHeader(),
};

const globalInterceptor = (store, navigate) => {
  axiosClient.interceptors.request.use(
    (conf) => {
      // you can add some information before send it.
      // conf.headers['Auth'] = 'some token'
      return conf;
    },
    (error) => {
      if (!navigator.onLine) {
        navigate("/no-internet");
      }
      return Promise.reject(error);
    }
  );
  axiosClient.interceptors.response.use(
    (next) => {
      return Promise.resolve(next);
    },
    (error) => {
      // You can handle error here and trigger warning message without get in the code inside
      Sentry.captureException(error);
      // store.dispatch(setErrorCode(error.response.status))

      switch (error.response.status) {
        case 400:
        case 403:
        case 422:
          break;
        case 401:
          navigate("/auth/not-auth");
          break;
        case 404:
          // navigate("/not-found");
          break;
        case 500:
          navigate("/error");
          break;

        default:
          break;
      }

      return Promise.reject(error);
    }
  );
};

const authInterceptor = (store) => {
  axiosClient.interceptors.request.use(
    (conf) => {
      // you can add some information before send it.
      // conf.headers['Auth'] = 'some token'
      return conf;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosClient.interceptors.response.use(
    (next) => {
      return Promise.resolve(next);
    },
    (error) => {
      // You can handle error here and trigger warning message without get in the code inside
      Sentry.captureException(error);
      // store.dispatch(
      //   setToast({
      //     type: "error",
      //     message:
      //       error?.response?.data?.message ||
      //       "An error has occured. Please try again."
      //   })
      // )
      return Promise.reject(error);
    }
  );
};

export { globalInterceptor, authInterceptor };

export default axiosClient;

// export axiosAppClient for intercepting and isolate error toasts from every request
// export default axiosAppClient

export function setToken() {
  axiosClient.defaults.headers = {
    Authorization: authHeader(),
  };
}
