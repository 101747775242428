// ** React Imports
import { lazy } from "react";
import { lazyRetry } from "utility/Utils";

const Login = lazy(() => lazyRetry(() => import("../pages/login/Login")));

const RegisterProjectOwner = lazy(() =>
  lazyRetry(() => {
    return import("../pages/registerProjectOwner/RegisterProjectOwner");
  })
);
const RegisterAffiliate = lazy(() =>
  lazyRetry(() => {
    return import("../pages/registerAffiliate/RegisterAffiliate");
  })
);
const RegisterContractor = lazy(() =>
  lazyRetry(() => {
    return import("../pages/registerContractor/RegisterContractor");
  })
);
const ForgotPassword = lazy(() =>
  lazyRetry(() => import("../pages/forgotPassword/ForgotPassword"))
);
const ForgotPasswordController = lazy(() =>
  lazyRetry(() => import("../pages/forgotPassword/ForgotPasswordController"))
);
const OauthController = lazy(() =>
  lazyRetry(() => import("../pages/oauth/OauthController"))
);

const VerifyEmail = lazy(() =>
  lazyRetry(() => import("../pages/verifyEmail/VerifyEmail"))
);
const VerifyEmailController = lazy(() =>
  lazyRetry(() => import("../pages/verifyEmail/VerifyEmailController"))
);
// EMAIL VERIFICATION SUCCESS
const VerifyEmailConfirmation = lazy(() =>
  lazyRetry(() => import("../pages/verifyEmail/VerifyEmailConfirmation"))
);

// PROFILE COMPLETION IMPORTS
const CompleteProjectOwnerProfile = lazy(() =>
  lazyRetry(() => {
    return import(
      "../pages/completeProjectOwnerProfile/CompleteProjectOwnerProfile"
    );
  })
);
const CompleteContractorProfile = lazy(() =>
  lazyRetry(() => {
    return import(
      "../pages/completeContractorProfile/CompleteContractorProfile"
    );
  })
);
const CompleteProjectManagerProfile = lazy(() =>
  lazyRetry(() => {
    return import(
      "../pages/completeProjectManagerProfile/CompleteProjectManagerProfile"
    );
  })
);
// SELECT ROLE IMPORTS
const SelectRole = lazy(() =>
  lazyRetry(() => import("../pages/selectRole/SelectRole"))
);

// MANAGER APPROVAL IMPORTS
const ManagerApproval = lazy(() =>
  lazyRetry(() => import("../pages/managerApplication/ManagerApproval"))
);
const ManagerReview = lazy(() =>
  lazyRetry(() => import("../pages/managerApplication/ManagerReview"))
);
const ManagerDenied = lazy(() =>
  lazyRetry(() => import("../pages/managerApplication/ManagerDenied"))
);

const NotFound = lazy(() => lazyRetry(() => import("../../NotFound")));
const NotAuthorized = lazy(() =>
  lazyRetry(() => import("../pages/NotAuthorized/NotAuthorized"))
);

const AuthenticationRoutes = [
  {
    path: "/login",
    element: <Login />,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true,
    },
  },
  {
    path: "/register-project-owner",
    element: <RegisterProjectOwner />,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true,
    },
  },
  {
    path: "/register-affiliate",
    element: <RegisterAffiliate />,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true,
    },
  },
  {
    path: "/register-contractor",
    element: <RegisterContractor />,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true,
    },
  },

  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true,
    },
  },
  {
    path: "/forgot-password/:id",
    element: <ForgotPasswordController />,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true,
    },
  },
  {
    path: "/validate/:type",
    element: <OauthController />,
    meta: {
      layout: "blank",
      restricted: true,
    },
  },
  {
    path: "/auth/not-auth",
    element: <NotAuthorized />,
    meta: {
      layout: "blank",
      public: true,
    },
  },
  // SELECT ROLE ROUTES
  {
    path: "/select-role",
    element: <SelectRole />,
    meta: {
      layout: "blank",
      shield: true,
    },
  },
  // EMAIL VERIFICATION ROUTES
  {
    path: "/verify-email",
    element: <VerifyEmail />,
    meta: {
      layout: "blank",
      shield: true,
    },
  },
  {
    path: "/verify-email/:token",
    element: <VerifyEmailController />,
    meta: {
      layout: "blank",
      public: true,
    },
  },
  {
    path: "/verify-email/success",
    element: <VerifyEmailConfirmation />,
    meta: {
      layout: "blank",
      public: true,
    },
  },
  // PROFILE COMPLETION ROUTES
  {
    path: "/register-project-owner/complete",
    element: <CompleteProjectOwnerProfile />,
    meta: {
      layout: "blank",
      shield: true,
      roles: ["Project Owner"],
    },
  },
  {
    path: "/register-contractor/complete",
    element: <CompleteContractorProfile />,
    meta: {
      layout: "blank",
      shield: true,
      roles: ["Contractor", "Project Manager"],
    },
  },
  {
    path: "/register-project-manager/complete",
    element: <CompleteProjectManagerProfile />,
    meta: {
      layout: "blank",
      shield: true,
      roles: ["Contractor", "Project Manager"],
    },
  },
  // MANAGER APPLICATION ROUTES
  {
    path: "/register-project-manager/approval",
    element: <ManagerApproval />,
    meta: {
      layout: "blank",
      shield: true,
      roles: ["Contractor", "Project Manager"],
    },
  },
  {
    path: "/register-project-manager/review",
    element: <ManagerReview />,
    meta: {
      layout: "blank",
      shield: true,
      roles: ["Contractor", "Project Manager"],
    },
  },
  {
    path: "/register-project-manager/denied",
    element: <ManagerDenied />,
    meta: {
      layout: "blank",
      shield: true,
      roles: ["Contractor", "Project Manager"],
    },
  },

  {
    path: "/not-found",
    element: <NotFound />,
    meta: {
      layout: "blank",
    },
  },
];

export default AuthenticationRoutes;
