import { createSlice } from "@reduxjs/toolkit"
import { getUser } from "./user.actions"

const initialState = {
  user: null,
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUser: (state, action) => {
      state.user = null
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getUser.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.user = action.payload
      })
      .addCase(getUser.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
  }
})

// Action creators are generated for each case reducer function
export const { clearUser } = userSlice.actions

export default userSlice.reducer
