import { createSlice } from "@reduxjs/toolkit"
import {
  getCSR,
  getPPR,
  getCSRCount,
  getProblemReportsCount
} from "./reports.actions"

const initialState = {
  ppr: {
    loading: false,
    value: null,
    error: null,
    new: 0
  },
  csr: {
    loading: false,
    value: null,
    error: null,
    new: 0
  },
  new: 0,
  error: null
}

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    clearReports: (state, action) => initialState,
    newPayment: (state, action) => {
      state.new = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getCSR.pending, (state) => {
        state.csr.loading = true
      })
      .addCase(getCSR.fulfilled, (state, action) => {
        state.csr.loading = false
        state.csr.value = action.payload
        state.new = 0
      })
      .addCase(getCSR.rejected, (state, action) => {
        state.csr.loading = false
        state.csr.error = action.error.message
      })
      .addCase(getPPR.pending, (state) => {
        state.ppr.loading = true
      })
      .addCase(getPPR.fulfilled, (state, action) => {
        state.ppr.loading = false
        state.ppr.value = action.payload
        state.new = 0
      })
      .addCase(getPPR.rejected, (state, action) => {
        state.ppr.loading = false
        state.ppr.error = action.error.message
      })
  }
})

// Action creators are generated for each case reducer function
export const { clearReports, newPayment } = reportsSlice.actions

export default reportsSlice.reducer
