// ** Reducers Imports
import alert from "./globalReducers/alert";
import api from "./globalReducers/api";
import globalFilters from "./globalReducers/globalFilters";
import layout from "../layouts/reducer/layout.reducer";
import navbar from "./navbar";
import authentication from "../views/auth/reducer/authentication";
import projects from "../views/projects/reducer/projects.reducer";
import project from "views/project/reducer/project.reducer";
import career from "../views/career/reducer/career";
import users from "../views/users/reducer/users.reducer";
import user from "../views/user/reducer/user";
import projectCreate from "../views/projectCreate/reducer/projectCreate";
import proposal from "../views/proposal/reducer/proposal.reducer";
import proposals from "../views/proposals/reducer/proposals.reducer";
import positions from "../views/positions/reducer/positions";
import position from "../views/position/reducer/position";
import applications from "../views/applications/reducer/applications";
import todos from "../views/todos/reducer/todos";
import rates from "../views/rates/reducer/rates";
import notifications from "../views/notifications/reducer/notifications";
import chat from "../views/chat/reducer/chat";
import settings from "../views/settings/reducer/settings";
import payments from "../views/payments/reducer/payments.reducer";
import reports from "../views/reports/reducer/reports";
import occupations from "../views/occupations/reducer/occupations";
import affiliate from "../views/affiliate/reducer/affiliate.reducer";

const rootReducer = {
  navbar,
  layout,
  alert,
  globalFilters,
  api,
  authentication,
  career,
  projects,
  project,
  users,
  user,
  projectCreate,
  proposals,
  proposal,
  positions,
  position,
  applications,
  todos,
  rates,
  notifications,
  chat,
  settings,
  payments,
  reports,
  occupations,
  affiliate,
};

export default rootReducer;
