import { createSlice } from "@reduxjs/toolkit";
import {} from "./settings.actions";

const initialState = {
  settings: null,
  loading: true,
  error: null,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers(builder) {},
});

// Action creators are generated for each case reducer function
export const {} = settingsSlice.actions;

export default settingsSlice.reducer;
