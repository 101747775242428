import { lazy } from "react";
import { lazyRetry } from "utility/Utils";

const Projects = lazy(() => lazyRetry(() => import("../Projects")));
const AllProjects = lazy(() =>
  lazyRetry(() => import("../admin/AllProjects/AllProjects"))
);
const BrowseProjects = lazy(() =>
  lazyRetry(() => import("../BrowseProjects/BrowseProjects"))
);

const projectsRoutes = [
  {
    path: "/admin/projects",
    element: <AllProjects />,
    meta: {
      private: true,
      title: "Projects",
      roles: ["Admin"],
    },
  },
  {
    path: "/projects",
    element: <Projects />,
    meta: {
      private: true,
      title: "Projects",
      roles: ["Project Owner", "Project Manager", "Contractor"],
    },
  },
  {
    path: "/browse-projects",
    element: <BrowseProjects />,
    meta: {
      private: true,
      title: "Browse projects",
      roles: ["Project Manager"],
    },
  },
  {
    path: "/projects/inactive",
    element: <Projects type="inactive" />,
    meta: {
      private: true,
      title: "Browse",
      roles: ["Project Manager"],
    },
  },
];

export default projectsRoutes;
