import { createSlice } from "@reduxjs/toolkit"
import {
  getNotifications,
  getNotificationsCount,
  loadNotifications
} from "./notifications.actions"

const initialState = {
  notifications: null,
  page: 0,
  new: 0,
  loading: false,
  loadingMore: false,
  error: null
}

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    clearNotifications: (state, action) => {
      state.notifications = null
    },
    newNotification: (state, action) => {
      state.new = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getNotifications.pending, (state) => {
        state.loading = true
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.loading = false
        state.notifications = action.payload
        state.new = 0
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(getNotificationsCount.fulfilled, (state, action) => {
        state.new = action.payload
      })
      .addCase(loadNotifications.pending, (state, action) => {
        state.loadingMore = true
      })
      .addCase(loadNotifications.fulfilled, (state, action) => {
        state.loadingMore = false
        state.page =
          action.payload && action.payload.length > 0
            ? state.page + 1
            : state.page
        state.notifications =
          state.notifications && Array.isArray(state.notifications)
            ? state.notifications.concat(action.payload)
            : action.payload
      })
  }
})

// Action creators are generated for each case reducer function
export const { clearNotifications, newNotification } =
  notificationsSlice.actions

export default notificationsSlice.reducer
