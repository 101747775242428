// ** React Imports
import { Suspense } from "react";

import { Navigate } from "react-router-dom";

import RoleMiddleware from "../middlewares/roleMiddleware/RoleMiddleware";

import { useSelector } from "react-redux";
import {
  checkStepsForUserRole,
  getRouteForLoggedInUser,
  isObjEmpty,
} from "utility/Utils";

const PrivateRoute = ({ children, route }) => {
  const user = useSelector((state) => state.authentication.userData);
  const selectedRole = useSelector(
    (state) => state.authentication.selectedRole
  );
  const career = useSelector((state) => state.career.career);

  if (isObjEmpty(user)) {
    return <Navigate to="/login" />;
  } else if (!user.email_verified_at) {
    return <Navigate to="/verify-email" />;
  } else if (
    (user.roles?.length == 0 && user.provider_type) ||
    !user.active_role
  ) {
    if (user.has_manager_application) {
      return <Navigate to="/register-project-manager/review" />;
    }
    return <Navigate to="/select-role" />;
  } else if (checkStepsForUserRole(user, user.active_role.role_name, career)) {
    if (user.active_role.role_name === "Contractor") {
      if (user.has_manager_application) {
        return <Navigate to={"/register-project-manager/approval"} />;
      }
    }
    return (
      <Navigate to={getRouteForLoggedInUser(user.active_role.role_name)} />
    );
  }

  return (
    <RoleMiddleware route={route} selectedRole={selectedRole}>
      <Suspense fallback={null}>{children}</Suspense>
    </RoleMiddleware>
  );
};

export default PrivateRoute;
