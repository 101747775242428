import { lazy } from "react";
import { lazyRetry } from "utility/Utils";

const ProjectWrapper = lazy(() =>
  lazyRetry(() => import("../wrappers/ProjectWrapper/ProjectWrapper"))
);

const ProjectState = lazy(() =>
  lazyRetry(() => import("../components/ProjectState/ProjectState"))
);
const ProjectLog = lazy(() =>
  lazyRetry(() => import("../pages/ProjectLog/ProjectLog"))
);
const ProjectChat = lazy(() =>
  lazyRetry(() => import("../pages/ProjectChat/ProjectChat"))
);
const ManageProjectPositions = lazy(() =>
  lazyRetry(() =>
    import("../pages/ManageProjectPositions/ManageProjectPositions")
  )
);
const ProjectChangesRequest = lazy(() =>
  lazyRetry(() =>
    import("../pages/ProjectChangesRequest/ProjectChangesRequest")
  )
);
const ManageProjectMilestones = lazy(() =>
  lazyRetry(() =>
    import("../pages/ManageProjectMilestones/ManageProjectMilestones")
  )
);
const MilestoneTab = lazy(() =>
  lazyRetry(() => {
    return import("../../components/MilestoneTab/MilestoneTab");
  })
);
const ManageProjectContractors = lazy(() =>
  lazyRetry(() =>
    import("../pages/ManageProjectContractors/ManageProjectContractors")
  )
);
const ProjectCreate = lazy(() =>
  lazyRetry(() => import("../../projectCreate/ProjectCreate"))
);

const projectRoutes = [
  {
    path: "/project/:id",
    element: <ProjectWrapper />,
    meta: {
      private: true,
      roles: ["Project Owner", "Project Manager", "Contractor", "Admin"],
    },
    children: [
      {
        path: "",
        element: <ProjectState />,
        meta: {
          private: true,
          roles: ["Project Owner", "Project Manager", "Contractor", "Admin"],
        },
        children: [
          {
            path: "milestone/:milestoneId",
            element: <MilestoneTab />,
            meta: {
              private: true,
              roles: [
                "Project Owner",
                "Project Manager",
                "Contractor",
                "Admin",
              ],
            },
          },
        ],
      },

      {
        path: "log",
        element: <ProjectLog />,
        meta: {
          private: true,
          roles: ["Project Owner", "Project Manager", "Contractor", "Admin"],
          className: "chat-application",
          appLayout: true,
        },
      },
      {
        path: "manage",
        element: <ManageProjectMilestones />,
        meta: {
          private: true,
          roles: ["Project Manager"],
        },
      },
      {
        path: "changes-request",
        element: <ProjectChangesRequest />,
        meta: {
          private: true,
          roles: ["Project Owner"],
        },
      },
      {
        path: "manage/positions",
        element: <ManageProjectPositions />,
        meta: {
          private: true,
          roles: ["Project Manager"],
        },
      },
      {
        path: "manage/contractors",
        element: <ManageProjectContractors />,
        meta: {
          private: true,
          roles: ["Project Manager"],
        },
      },
      {
        path: "chat",
        element: <ProjectChat />,
        meta: {
          private: true,
          roles: ["Project Owner", "Project Manager", "Contractor"],
          className: "chat-application",
          appLayout: true,
        },
      },
    ],
  },
  {
    path: "/project/:id/edit",
    element: <ProjectCreate />,
    meta: {
      private: true,
      roles: ["Project Owner"],
    },
  },
  {
    path: "/create-project",
    element: <ProjectCreate />,
    meta: {
      private: true,
      roles: ["Project Owner"],
    },
  },
];

export default projectRoutes;
