import { createAsyncThunk } from "@reduxjs/toolkit"
import axiosClient from "../../../api/client"

export const changePassword = async (
  oldPassword,
  password,
  passwordConfirmation
) => {
  const response = await axiosClient.put(`/change-password`, {
    old_password: oldPassword,
    password: password,
    password_confirmation: passwordConfirmation
  })
  return response
}
