import { createSlice } from "@reduxjs/toolkit"
import { explorePositions } from "./positions.actions"

const initialState = {
  positions: null,
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null
}

export const positionsSlice = createSlice({
  name: "positions",
  initialState,
  reducers: {
    clearPositions: (state, action) => {
      state.positions = null
      state.status = "loading"
    }
  },
  extraReducers(builder) {
    builder
      .addCase(explorePositions.pending, (state) => {
        state.status = "loading"
      })
      .addCase(explorePositions.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.positions = action.payload
      })
      .addCase(explorePositions.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
  }
})

// Action creators are generated for each case reducer function
export const { clearPositions } = positionsSlice.actions

export default positionsSlice.reducer
