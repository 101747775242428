import { lazy } from "react"
import { lazyRetry } from "../../../utility/Utils"

const ReportsAdminWrapper = lazy(() =>
  lazyRetry(() =>
    import("../components/ReportsAdminWrapper/ReportsAdminWrapper")
  )
)
const CustomerSupportRequestsAdmin = lazy(() =>
  lazyRetry(() =>
    import("../pages/CustomerSupportRequestsAdmin/CustomerSupportRequestsAdmin")
  )
)
const ProjectProblemReportsAdmin = lazy(() =>
  lazyRetry(() =>
    import("../pages/ProjectProblemReportsAdmin/ProjectProblemReportsAdmin")
  )
)

const reportsRoutes = [
  {
    path: "/admin/reports",
    element: <ReportsAdminWrapper />,
    meta: {
      private: true,
      roles: ["Admin"]
    },
    children: [
      {
        index: true,
        element: <CustomerSupportRequestsAdmin />,
        meta: {
          private: true,
          roles: ["Admin"]
        }
      },
      {
        path: "project",
        element: <ProjectProblemReportsAdmin />,
        meta: {
          private: true,
          roles: ["Admin"]
        }
      }
    ]
  }
]

export default reportsRoutes
