// ** React Imports
import { Fragment } from "react";

// ** Layouts

import LayoutWrapper from "layouts/components/LayoutWrapper/LayoutWrapper";

// ** Route Components
import PublicRoute from "router/types/PublicRoute";
import PrivateRoute from "router/types/PrivateRoute";
import ShieldRoute from "router/types/ShieldRoute";
import { Layout } from "react-feather";
import LayoutMiddleware from "router/middlewares/LayoutMiddleware/LayoutMiddleware";

function renderRoutes(routes, isChild) {
  return routes.filter((route) => {
    let isBlank = false;

    if (route.meta) {
      let RouteTag = PublicRoute;
      if (route.meta.private) {
        RouteTag = PrivateRoute;
      } else if (route.meta.shield) {
        RouteTag = ShieldRoute;
      }

      if (route.meta) {
        route.meta.layout === "blank" ? (isBlank = true) : (isBlank = false);
      }

      if (route.element) {
        // wraps top level routes with LayoutWrapper
        const Wrapper = !isBlank && !isChild ? LayoutWrapper : Fragment;
        const wrapperProps =
          !isBlank && !isChild ? { routeMeta: route.meta } : {};

        // Wraps all routes with LayoutMiddleware
        const layoutMiddlewareProps = { routeMeta: route.meta };

        route.element = (
          <LayoutMiddleware {...layoutMiddlewareProps}>
            <Wrapper {...wrapperProps}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          </LayoutMiddleware>
        );
      }

      if (route.children != null) {
        route.children = [...renderRoutes(route.children, true)];
      }
      return route;
    }
    return false;
  });
}

// ** Return Filtered Array of routes & Paths
const MergeLayoutRoutes = (routes, layout, defaultLayout) => {
  // Filter only routes that match layout
  let filteredRoutes = routes
    ? routes.filter((route) => {
        if (
          (route.meta && route.meta.layout && route.meta.layout === layout) ||
          ((route.meta === undefined || route.meta.layout === undefined) &&
            defaultLayout === layout)
        ) {
          return true;
        }
        return false;
      })
    : [];

  return filteredRoutes ? renderRoutes(filteredRoutes, false) : [];
};

export default MergeLayoutRoutes;
