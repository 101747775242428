import { createAsyncThunk } from "@reduxjs/toolkit"
import axiosClient from "../../../api/client"

export const getNotifications = createAsyncThunk(
  "notifications/get",
  async (page) => {
    try {
      const response = await axiosClient.get(
        `/notifications/my?page=${page || 1}`
      )
      return response.data.notifications
    } catch (err) {
      return err.message
    }
  }
)
export const loadNotifications = createAsyncThunk(
  "notifications/load",
  async (page) => {
    try {
      const response = await axiosClient.get(
        `/notifications/my?page=${page || 1}`
      )
      return response.data.notifications
    } catch (err) {
      return err.message
    }
  }
)
export const getNotificationsCount = createAsyncThunk(
  "notifications/getCount",
  async () => {
    try {
      const response = await axiosClient.get(`/notifications/count`)
      return response.data.count
    } catch (err) {
      return err.message
    }
  }
)
