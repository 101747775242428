import { lazy } from "react";
import { lazyRetry } from "utility/Utils";

const AffiliatePage = lazy(() =>
  lazyRetry(() => import("../pages/AffiliatePage/AffiliatePage"))
);

const affiliateRoutes = [
  {
    path: "/affiliate",
    element: <AffiliatePage />,
    meta: {
      private: true,
      title: "Affiliate",
      roles: ["Referrer"],
    },
  },
  // {
  //   path: "/transactions",
  //   element: <TransactionsPage />,
  //   meta: {
  //     private: true,
  //     roles: ["Project Owner"]
  //   }
  // },

  // {
  //   path: "/admin/payments",
  //   element: <AdminPaymentsWrapper />,
  //   meta: {
  //     private: true,
  //     roles: ["Admin"]
  //   },
  //   children: [
  //     {
  //       index: true,
  //       element: <AdminPaymentsWire />,
  //       meta: {
  //         private: true,
  //         roles: ["Admin"]
  //       }
  //     },
  //     {
  //       path: "payoneer",
  //       element: <AdminPaymentsPayoneer />,
  //       meta: {
  //         private: true,
  //         roles: ["Admin"]
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: "/admin/payouts",
  //   element: <AdminPayoutsWrapper />,
  //   meta: {
  //     private: true,
  //     roles: ["Admin"]
  //   },
  //   children: [
  //     {
  //       index: true,
  //       element: <AdminPayoutsWire />,
  //       meta: {
  //         private: true,
  //         roles: ["Admin"]
  //       }
  //     },
  //     {
  //       path: "payoneer",
  //       element: <AdminPayoutsPayoneer />,
  //       meta: {
  //         private: true,
  //         roles: ["Admin"]
  //       }
  //     }
  //   ]
  // }
];

export default affiliateRoutes;
