import { lazy } from "react";
import { lazyRetry } from "utility/Utils";

const Settings = lazy(() =>
  lazyRetry(() => import("../pages/Settings/Settings"))
);

const settingsRoutes = [
  {
    path: "/settings",
    element: <Settings />,
    meta: {
      private: true,
      roles: [
        "Project Owner",
        "Project Manager",
        "Referrer",
        "Contractor",
        "Admin",
      ],
    },
  },
];

export default settingsRoutes;
