import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../../api/client";
import { renderStatusValue } from "../common/proposals.functions";

export const getProposals = createAsyncThunk(
  "proposals/get",
  async ({ status, projectId, page }) => {
    try {
      let tempStatus = renderStatusValue(status);
      const response = await axiosClient.get(
        `/proposals/my?page=${page || 1}${
          tempStatus ? `&status=${tempStatus}` : ""
        }${projectId ? `&project_id=${projectId}` : ""}`
      );
      return response.data;
    } catch (err) {
      return err.message;
    }
  }
);

export const disableProposal = async (projectId, proposalId) =>
  await axiosClient.put(
    `/projects/${projectId}/proposals/${proposalId}/disable`
  );
