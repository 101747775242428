export function renderStatusValue(status) {
  switch (status) {
    case "pending":
      return "active";
    case "accepted":
      return "approved";
    default:
      return status;
  }
}
