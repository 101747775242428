import { createSlice } from "@reduxjs/toolkit"
import { getApplications } from "./applications.actions"

const initialState = {
  applications: null,
  loading: false,
  error: null
}

export const applicationsSlice = createSlice({
  name: "applications",
  initialState,
  reducers: {
    clearApplications: (state, action) => {
      state.applications = null
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getApplications.pending, (state) => {
        state.loading = true
      })
      .addCase(getApplications.fulfilled, (state, action) => {
        state.loading = false
        state.applications = action.payload
      })
      .addCase(getApplications.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  }
})

// Action creators are generated for each case reducer function
export const { clearApplications } = applicationsSlice.actions

export default applicationsSlice.reducer
