import { lazy } from "react";
import { lazyRetry } from "../../utility/Utils";

const PrivacyPolicy = lazy(() =>
  lazyRetry(() => import("../../views/PrivacyPolicy"))
);
const PrivacyPolicyAffiliate = lazy(() =>
  lazyRetry(() => import("../../views/PrivacyPolicyAffiliate"))
);
const NoInternet = lazy(() =>
  lazyRetry(() => import("../../views/NoInternet"))
);
const Error = lazy(() => lazyRetry(() => import("../../views/Error")));
const NotFound = lazy(() => lazyRetry(() => import("../../views/NotFound")));

const exceptionRoutes = [
  // EXCEPTION ROUTES
  {
    path: "/error",
    element: <Error />,
    meta: {
      layout: "blank",
      publicRoute: true,
    },
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
    meta: {
      layout: "blank",
      publicRoute: true,
    },
  },
  {
    path: "/privacy-policy-affiliate",
    element: <PrivacyPolicyAffiliate />,
    meta: {
      layout: "blank",
      publicRoute: true,
    },
  },
  {
    path: "/not-found",
    element: <NotFound />,
    meta: {
      layout: "blank",
      publicRoute: true,
    },
  },
  {
    path: "/no-internet",
    element: <NoInternet />,
    meta: {
      layout: "blank",
      publicRoute: true,
    },
  },
];

export default exceptionRoutes;
