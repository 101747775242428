import { createAsyncThunk } from "@reduxjs/toolkit"
import axiosClient from "../../../api/client"
import { objectToFormData } from "../../../utility/Utils"

export const getProject = createAsyncThunk(
  "projectCreate/getProject",
  async (id) => {
    try {
      const response = await axiosClient.get(`/projects/${id}`)
      return response.data.project
    } catch (err) {
      return err.message
    }
  }
)

export const createProject = async (data) => {
  const response = await axiosClient.post(
    `/projects`,
    objectToFormData({
      project_name: data.projectName,
      project_short_description: data.shortDescription,
      project_long_description: data.description,
      project_state_id: data.state.id,
      project_budget_id: data.budget.id,
      attachments: data.files,
      project_deadline_id: data.deadline.id,
      project_investor_role_id: data.role.id,
      project_visibility_id: data.visibility.id,
      industries: data.industry.map((i) => i.industry_name),
      invited_managers: data.invited_managers
        ? data.invited_managers.map((id, i) => {
            return {
              user_id: id
            }
          })
        : null
    })
  )
  return response
}
export const updateProject = async (data, id) => {
  const response = await axiosClient.put(`/projects/${id}`, {
    project_name: data.projectName,
    project_short_description: data.shortDescription,
    project_long_description: data.description,
    project_state_id: data.state.id,
    project_budget_id: data.budget.id,
    project_deadline_id: data.deadline.id,
    project_investor_role_id: data.role.id,
    project_visibility_id: data.visibility.id,
    industries: data.industry.map((i) => i.industry_name)
  })
  return response
}
export const deleteAllFiles = async (id) => {
  try {
    const response = await axiosClient.delete(`/projects/${id}/attachments`)
    return response.data
  } catch (err) {
    return err.message
  }
}
export const deleteFile = async (id, fileId) => {
  const response = await axiosClient.delete(
    `/projects/${id}/attachments/${fileId}`
  )
  return response.data
}
export const uploadFiles = async (data, id) => {
  let formData = new FormData()
  for (let i = 0; i < data.length; i++) {
    formData.append(`attachments[${i}]`, data[i])
  }

  const response = await axiosClient.post(
    `/projects/${id}/attachments`,
    formData
  )
  return response
}
