import {
  User,
  Home,
  Circle,
  Grid,
  MessageSquare,
  CheckSquare,
  DollarSign,
  BarChart2,
  Paperclip,
  FileText,
  Briefcase,
  Users,
  Award,
} from "react-feather";

export default [
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <Home size={20} />,
    navLink: "/dashboard",
  },
  {
    id: "career",
    title: "My career",
    icon: <User size={20} />,
    navLink: "/career",
    roles: ["Contractor", "Project Manager"],
    children: [
      {
        id: "updateProfile",
        title: "Update profile",
        icon: <Circle size={12} />,
        navLink: "/update-career",
        roles: ["Contractor", "Project Manager"],
      },
      {
        id: "myRatings",
        title: "My ratings",
        icon: <Circle size={12} />,
        navLink: "/my-ratings",
        roles: ["Project Manager", "Contractor"],
      },
    ],
  },
  {
    id: "projects",
    title: "Projects",
    icon: <Grid size={20} />,
    roles: ["Project Owner", "Project Manager", "Contractor"],
    children: [
      {
        id: "myProjects",
        title: "My projects",
        icon: <Circle size={12} />,
        navLink: "/projects",
        roles: ["Project Owner", "Project Manager", "Contractor"],
      },

      {
        id: "createNew",
        title: "Create new project",
        icon: <Circle size={12} />,
        navLink: "/create-project",
        roles: ["Project Owner"],
      },
      {
        id: "managers",
        title: "Browse managers",
        icon: <Circle size={12} />,
        navLink: "/managers",
        roles: ["Project Owner"],
      },

      {
        id: "myProposals",
        title: "My proposals",
        icon: <Circle size={12} />,
        navLink: "/my-proposals",
        roles: ["Project Manager"],
      },
      {
        id: "applications",
        title: "My applications",
        icon: <Circle size={12} />,
        navLink: "/applications",
        roles: ["Contractor"],
      },
      {
        id: "browseProjects",
        title: "Browse projects",
        icon: <Circle size={12} />,
        navLink: "/browse-projects",
        roles: ["Project Manager"],
      },
      {
        id: "contractors",
        title: "Browse contractors",
        icon: <Circle size={12} />,
        navLink: "/contractors",
        roles: ["Project Manager"],
      },
      {
        id: "browsePositions",
        title: "Browse positions",
        icon: <Circle size={12} />,
        navLink: "/positions",
        roles: ["Contractor"],
      },
      {
        id: "myRatingsOwner",
        title: "My ratings ",
        icon: <Circle size={12} />,
        navLink: "/my-ratings",
        roles: ["Project Owner"],
      },
      // {
      //   id: "activeProjects",
      //   title: "Active projects",
      //   icon: <Circle size={12} />,
      //   navLink: "/projects-active",
      //   roles: ["Project Manager"]
      // },
      // {
      //   id: "inactiveProjects",
      //   title: "Inactive projects",
      //   icon: <Circle size={12} />,
      //   navLink: "/projects-inactive",
      //   roles: ["Project Manager"]
      // }
    ],
  },
  {
    id: "allProjects",
    title: "Projects",
    icon: <Grid size={12} />,
    navLink: "/admin/projects",
    roles: ["Admin"],
  },

  {
    id: "users",
    title: "Users",
    icon: <User size={12} />,
    roles: ["Admin"],
    children: [
      {
        id: "allUsers",
        title: "All",
        icon: <Circle size={12} />,
        navLink: "/admin/users/",
        roles: ["Admin"],
      },
      {
        id: "managers",
        title: "Managers",
        icon: <Circle size={12} />,
        navLink: "/admin/users/managers",
        roles: ["Admin"],
      },
      {
        id: "affiliates",
        title: "Affiliates",
        icon: <Circle size={12} />,
        navLink: "/admin/users/affiliates",
        roles: ["Admin"],
      },
    ],
  },
  {
    id: "chat",
    title: "Chat",
    icon: <MessageSquare size={12} />,
    navLink: "/chat",
    roles: ["Project Owner", "Project Manager", "Contractor", "Admin"],
  },
  {
    id: "todos",
    title: "To-do",
    icon: <CheckSquare size={12} />,
    navLink: "/todos",
    roles: ["Project Owner", "Project Manager", "Contractor"],
  },
  {
    id: "payments",
    title: "Payments",
    icon: <DollarSign size={12} />,
    navLink: "/payments",
    roles: ["Project Manager", "Contractor"],
  },
  {
    id: "transactions",
    title: "Transactions",
    icon: <BarChart2 size={12} />,
    navLink: "/transactions",
    roles: ["Project Owner"],
  },
  {
    id: "adminPayments",
    title: "Payments",
    icon: <DollarSign size={12} />,
    roles: ["Admin"],
    children: [
      {
        id: "milestonePayments",
        title: "Milestone payments",
        icon: <Circle size={12} />,
        navLink: "/admin/payments",
        roles: ["Admin"],
      },
      {
        id: "payouts",
        title: "Payouts",
        icon: <Circle size={12} />,
        navLink: "/admin/payouts",
        roles: ["Admin"],
      },
    ],
  },
  {
    id: "reports",
    title: "Reports",
    icon: <FileText size={12} />,
    navLink: "/admin/reports",
    roles: ["Admin"],
  },
  {
    id: "occupations",
    title: "Occupations",
    icon: <Briefcase size={12} />,
    navLink: "/admin/occupations",
    roles: ["Admin"],
  },
  {
    id: "affiliate",
    title: "My affiliate overview",
    icon: <Users size={12} />,
    navLink: "/affiliate",
    roles: ["Referrer"],
  },
  {
    id: "manager-levels",
    title: "Project Manager levels",
    icon: <Award size={12} />,
    navLink: "/manager-levels",
    roles: ["Admin"],
  },
];
