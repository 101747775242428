// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import {
  changeStatus,
  handleLogin,
  handleUpdate,
  refreshUser,
  switchUserRole,
} from "./auth.actions";

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    userData: {},
    selectedRole: null,
    loggedIn: false,
    loading: false,
  },
  reducers: {
    handleUpdateUser: (state, action) => {
      state.userData = action.payload;
    },
    handleLogout: (state) => {
      state.userData = {};
      state.loggedIn = false;
      state.selectedRole = null;
      localStorage.removeItem("userData");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("selectedRole");
    },
  },
  extraReducers(builder) {
    builder
      .addCase(handleLogin.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(handleLogin.fulfilled, (state, action) => {
        state.userData = action.payload.userData;
        state.selectedRole = action.payload.selectedRole;
        state.loggedIn = true;
        state.loading = false;
      })
      .addCase(handleLogin.rejected, (state, action) => {
        state.error = action.error.message;
        state.loggedIn = false;
        state.loading = false;
      })
      .addCase(handleUpdate.fulfilled, (state, action) => {
        state.userData = action.payload.userData;
        state.loading = false;
      })
      .addCase(refreshUser.fulfilled, (state, action) => {
        state.userData = action.payload.userData;
        state.selectedRole = action.payload.selectedRole;
      })
      .addCase(changeStatus.fulfilled, (state, action) => {
        state.userData = action.payload;
      })
      .addCase(handleUpdate.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(switchUserRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(switchUserRole.fulfilled, (state, action) => {
        state.userData = action.payload.userData;
        state.selectedRole = action.payload.selectedRole;
        state.loading = false;
      });
  },
});

export const { handleLogout, handleUpdateUser } = authSlice.actions;

export default authSlice.reducer;
