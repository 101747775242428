import { formatFileDispatch } from "helpers/functions/helpers.functions";
import { isObjEmpty, isStringEmpty } from "utility/Utils";
import {
  formatMilestonesDispatch,
  formatMilestonesForm,
} from "views/project/common/project.functions";

export function formatProposalForm(proposal) {
  return {
    ...proposal,
    milestones: formatMilestonesForm(proposal.milestones),
    letter: {
      expiry: {
        ...proposal.expiry_period,
        value: proposal.expiry_period.expiry_period_name,
        label: proposal.expiry_period.expiry_period_name,
      },
      letter: proposal.cover_letter,
      ...(proposal.cover_letter_attachment?.file_path
        ? {
            letterFile: {
              id: proposal.cover_letter_attachment.id,
              path: proposal.cover_letter_attachment.file_path,
              name: proposal.cover_letter_attachment.file_filename,
              type: proposal.cover_letter_attachment.file_type,
              size: proposal.cover_letter_attachment.file_size,
            },
          }
        : null),
    },
  };
}

export function validateMilestonesInput(milestones) {
  for (let i = 0; i < milestones.length; i++) {
    let err = Object.keys(milestones[i]).some((key) => {
      if (
        key !== "files" &&
        key !== "attachments" &&
        key !== "minDate" &&
        key !== "budget_granted"
      ) {
        if (typeof milestones[i][key] === "number") {
          if (!milestones[i][key]) {
            return true;
          }
        } else if (typeof milestones[i][key] === "object") {
          if (isObjEmpty(milestones[i][key])) {
            return true;
          }
        } else if (isStringEmpty(milestones[i][key])) {
          return true;
        }
      }
      return false;
    });
    if (err) {
      return true;
    }
  }
  return false;
}

export function formatProposalDispatch(input) {
  return {
    milestones: formatMilestonesDispatch(input.milestones),
    expiry_period_id: input.letter.expiry.id,
    cover_letter: input.letter.letter,
    ...(input.letter.letterFile
      ? { cover_letter_attachment: formatFileDispatch(input.letter.letterFile) }
      : {}),
  };
}
