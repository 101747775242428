import { createSlice } from "@reduxjs/toolkit"
import { getCareer } from "./career.actions"

const initialState = {
  career: null,
  loading: false,
  error: null
}

export const projectsSlice = createSlice({
  name: "career",
  initialState,
  reducers: {
    handleUpdateCareer: (state, action) => {
      state.career = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getCareer.pending, (state) => {
        state.loading = true
      })
      .addCase(getCareer.fulfilled, (state, action) => {
        state.loading = false
        state.career = action.payload
      })
      .addCase(getCareer.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  }
})

// Action creators are generated for each case reducer function
export const { handleUpdateCareer } = projectsSlice.actions

export default projectsSlice.reducer
