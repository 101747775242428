import { createAsyncThunk } from "@reduxjs/toolkit"
import axiosClient from "../../../api/client"

export const getIncomingRates = createAsyncThunk("rates/get", async () => {
  try {
    const response = await axiosClient.get(`/rates/incoming/my`)
    return response.data.rates
  } catch (err) {
    return err.message
  }
})
