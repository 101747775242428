// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

const initialState = {
  error: {
    code: null,
    message: null
  },
  type: null
}

export const apiSlice = createSlice({
  name: "alert",
  initialState: initialState,

  reducers: {
    setErrorCode: (state, action) => {
      state.error.code = action.payload
    },
    clearApi: () => initialState
  },
  extraReducers: (builder) => {}
})

export const { setErrorCode, clearApi } = apiSlice.actions

export default apiSlice.reducer
