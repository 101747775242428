import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../../api/client";

export const getProjects = createAsyncThunk(
  "projects/get",
  async ({ page }) => {
    try {
      const response = await axiosClient.get(
        `/projects/my${page ? `?page=${page}` : ""}`
      );
      return response.data.projects;
    } catch (err) {
      return err.message;
    }
  }
);

export const getAdminProjects = createAsyncThunk(
  "projects/all",
  async ({ page, input }) => {
    let params = {};
    input &&
      Object.keys(input).forEach((key) => {
        if (input[key]) {
          // if (key === "skills" && input[key].length > 0) {
          //   params = { ...params, skill_ids: input[key].map((s) => s.id) }
          // }
          if (key === "duration") {
            params = { ...params, project_deadline_id: input[key].id };
          }
          if (key === "state") {
            params = { ...params, project_state_id: input[key].id };
          }
          if (key === "budget") {
            params = { ...params, project_budget_id: input[key].id };
          }
          if (key === "industry" && input[key].length > 0) {
            params = { ...params, industry: input[key].map((s) => s.id) };
          }
          if (key === "ownerLanguage" && input[key].length > 0) {
            params = {
              ...params,
              project_owner_language: input[key].map((s) => s.id),
            };
          }
          if (key === "ownerRate") {
            params = { ...params, po_avg_rate: input[key].value };
          }
          if (key === "ownerRole") {
            params = { ...params, project_investor_role_id: input[key].id };
          }
          if (key === "proposals") {
            params = { ...params, number_of_proposals: input[key].value };
          }
          if (key === "search") {
            params = { ...params, project_name: input[key] };
          }
        }
      });
    try {
      const response = await axiosClient.get(
        `/admin/projects${page ? `?page=${page}` : ""}`,
        {
          params: params,
        }
      );
      return response.data.projects;
    } catch (err) {
      return err.message;
    }
  }
);

// INPUT
// search: "",
// duration
// budget
// state
// industry
// ownerLanguage
// ownerRate
// ownerRole
// proposals

export const exploreProjects = createAsyncThunk(
  "projects/browse",
  async ({ page, input }) => {
    let params = {};
    input &&
      Object.keys(input).forEach((key) => {
        if (input[key]) {
          // if (key === "skills" && input[key].length > 0) {
          //   params = { ...params, skill_ids: input[key].map((s) => s.id) }
          // }
          if (key === "duration") {
            params = { ...params, project_deadline_id: input[key].id };
          }
          if (key === "state") {
            params = { ...params, project_state_id: input[key].id };
          }
          if (key === "budget") {
            params = { ...params, project_budget_id: input[key].id };
          }
          if (key === "industry" && input[key].length > 0) {
            params = { ...params, industry: input[key].map((s) => s.id) };
          }
          if (key === "ownerLanguage" && input[key].length > 0) {
            params = {
              ...params,
              project_owner_language: input[key].map((s) => s.id),
            };
          }
          if (key === "ownerRate") {
            params = { ...params, po_avg_rate: input[key].value };
          }
          if (key === "ownerRole") {
            params = { ...params, project_investor_role_id: input[key].id };
          }
          if (key === "proposals") {
            params = { ...params, number_of_proposals: input[key].value };
          }
          if (key === "search") {
            params = { ...params, project_name: input[key] };
          }
        }
      });

    try {
      const response = await axiosClient.get(
        `/projects/managers/browse${page ? `?page=${page}` : ""}`,
        {
          params: params,
        }
      );
      return response.data.projects;
    } catch (err) {
      return err.message;
    }
  }
);
