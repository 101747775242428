// ** Third Party Components
import classnames from "classnames";
import { Bell } from "react-feather";

// ** Reactstrap Imports
import {
  Badge,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getNotifications,
  loadNotifications,
} from "views/notifications/reducer/notifications.actions";
import { useLocation } from "react-router-dom";
import { formatDate, getTime } from "utility/Utils";
import { useState } from "react";
import { parseLinks } from "helpers/functions/helpers.functions";
import { clearNotifications } from "views/notifications/reducer/notifications";
import ComponentSpinner from "views/components/spinner/Loading-spinner";
import UserAvatar from "views/components/UserAvatar/UserAvatar";
import InfiniteScrollWrapper from "views/components/InfiniteScrollWrapper/InfiniteScrollWrapper";

const NotificationDropdown = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );
  const page = useSelector((state) => state.notifications.page);
  const notificationsLoading = useSelector(
    (state) => state.notifications.loading
  );
  const notificationsLoadingMore = useSelector(
    (state) => state.notifications.loadingMore
  );
  const newNotifications = useSelector((state) => state.notifications.new);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    if (dropdownOpen) {
      dispatch(getNotifications(page));
    } else {
      dispatch(clearNotifications());
    }
  }, [dropdownOpen]);

  useEffect(() => {
    if (dropdownOpen) {
      setDropdownOpen(false);
    }
  }, [location]);

  function handleScrollY() {
    if (
      notifications &&
      !notificationsLoadingMore &&
      notifications.length >= 20
    ) {
      dispatch(loadNotifications(page + 1));
    }
  }

  // ** Function to render Notifications
  /*eslint-disable */
  const renderNotificationItems = () => {
    return (
      <InfiniteScrollWrapper
        loading={notificationsLoadingMore}
        fetch={handleScrollY}
        className="media-list scrollable-container p-0"
        style={{ maxHeight: "550px", minHeight: "150px" }}
      >
        {!notifications || notificationsLoading ? (
          <div className="py-3">
            <ComponentSpinner />
          </div>
        ) : notifications.length > 0 ? (
          <>
            {notifications.map((item, index) => {
              return (
                <div key={index} className="d-flex">
                  <div
                    className={classnames(
                      "list-item d-flex align-items-start",
                      !item.is_seen && "new position-relative pe-3"
                    )}
                  >
                    {!item.is_seen && (
                      <i
                        className="fa-solid fa-circle text-primary"
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                      ></i>
                    )}
                    <div className="me-1">
                      {item.image_url ? (
                        <img src={item.image_url} width={32} height={32} />
                      ) : item?.notifiable ? (
                        <UserAvatar
                          {...(item.notifiable.avatar
                            ? {
                                img: item.notifiable.avatar,
                                imgHeight: 32,
                                imgWidth: 32,
                              }
                            : {
                                content: item.notifiable.name,
                                initials: item.notifiable.name,
                              })}
                        />
                      ) : null}
                    </div>
                    <div className="list-item-body flex-grow-1">
                      <p className="media-heading mb-0 text-black">
                        {parseLinks({
                          actions: item.actions,
                          colors: item.colors,
                          description: item.description,
                        })}
                      </p>

                      <small className="notification-text help-text">
                        {formatDate(item.created_at) +
                          " " +
                          getTime(item.created_at)}
                      </small>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div className="p-1">Nothing to see here yet!</div>
        )}
      </InfiniteScrollWrapper>
    );
  };
  /*eslint-enable */

  return (
    <Dropdown
      tag="li"
      className="dropdown-notification nav-item me-2"
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
    >
      <DropdownToggle
        tag="a"
        className="nav-link position-relative"
        href="/"
        onClick={(e) => e.preventDefault()}
      >
        <Bell size={21} className={dropdownOpen ? "text-primary" : ""} />
        {newNotifications ? (
          <Badge pill color="danger" className="badge-up">
            {newNotifications}
          </Badge>
        ) : (
          ""
        )}
      </DropdownToggle>

      <DropdownMenu end className="dropdown-menu-media mt-0">
        <div className="dropdown-menu-header">
          <DropdownItem className="d-flex" tag="div" header>
            <h4 className="notification-title mb-0 me-auto">Notifications</h4>
            {/* <Badge tag="div" color="light-primary" pill>
            6 New
          </Badge> */}
          </DropdownItem>
        </div>
        {renderNotificationItems()}

        {/* <div className="dropdown-menu-footer px-1 pb-1">
            <div
              className={classnames(
                "list-item d-flex gap-1 justify-content-between align-items-center pt-1 "
              )}
            >
              <h6 className="fw-bolder me-auto mb-0">
                Send notification by email
              </h6>
              <div className="form-check form-switch">
                <Input
                  type="switch"
                  name="customSwitch"
                  id="exampleCustomSwitch"
                  defaultChecked
                />
              </div>
            </div>
            <Button color="primary" block>
              Read all notifications
            </Button>
          </div> */}
      </DropdownMenu>
    </Dropdown>
  );
};

export default NotificationDropdown;
