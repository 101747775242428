import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "api/client";
import { objectToFormData } from "utility/Utils";
import { fetchProject } from "views/project/reducer/project.services";
import { setEditProposal } from "./proposal.reducer";
import {
  formatProposalDispatch,
  formatProposalForm,
} from "../common/proposal.functions";
import { setToast } from "redux/globalReducers/alert";

export const getProposal = createAsyncThunk(
  "proposal/get",
  async ({ projectId, proposalId, edit }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosClient.get(
        `/projects/${projectId}/proposals/${proposalId}`
      );
      if (edit) {
        dispatch(setEditProposal(formatProposalForm(response.data)));
      }
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response);
    }
  }
);
export const refreshProposal = createAsyncThunk(
  "proposal/refreshProposal",
  async ({ projectId, proposalId }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosClient.get(
        `/projects/${projectId}/proposals/${proposalId}`
      );

      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response);
    }
  }
);
export const getProjectForProposal = createAsyncThunk(
  "proposal/getProjectForProposal",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await fetchProject({ id: id });
      return response.data.project;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response);
    }
  }
);

export const createProposal = createAsyncThunk(
  "proposal/createProposal",
  async ({ projectId, data }, { rejectWithValue, dispatch }) => {
    try {
      let objForm = formatProposalDispatch(data);
      let objToform = objectToFormData(objForm);

      const response = await axiosClient.post(
        `/projects/${projectId}/proposals`,
        objToform
      );
      dispatch(setToast({ type: "success", message: response.data.message }));
      return response.data;
    } catch (err) {
      console.log(err.response);
      dispatch(
        setToast({
          type: "error",
          message:
            err.response.data.message ||
            "Error occured while creating a new proposal.",
        })
      );
      return rejectWithValue(err.response);
    }
  }
);
export const updateProposal = createAsyncThunk(
  "proposal/updateProposal",
  async ({ projectId, proposalId, data }, { rejectWithValue, dispatch }) => {
    try {
      let objForm = formatProposalDispatch(data);
      let objToform = objectToFormData(objForm);
      const response = await axiosClient.post(
        `/projects/${projectId}/proposals/${proposalId}/update`,
        objToform
      );

      dispatch(setToast({ type: "success", message: response.data.message }));

      return response.data;
    } catch (err) {
      console.log(err.response);
      dispatch(
        setToast({
          type: "error",
          message:
            err.response.data.message ||
            "Error occured while updating proposal.",
        })
      );
      return rejectWithValue(err.response);
    }
  }
);

export const acceptProposal = createAsyncThunk(
  "proposal/acceptProposal",
  async (
    { projectId, proposalId, updatedAt },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axiosClient.put(
        `/projects/${projectId}/proposals/${proposalId}/approve`,
        { updated_at: updatedAt }
      );

      dispatch(
        setToast({
          type: "success",
          message: response.data.message || "Proposal successfully accepted.",
        })
      );

      return response.data;
    } catch (err) {
      console.log(err.response);
      dispatch(
        setToast({
          type: "error",
          message:
            err.response.data.message ||
            "Error occured while accepting proposal.",
        })
      );
      if (err.response?.data?.code === "proposal_outdated") {
        dispatch(refreshProposal({ projectId, proposalId }));
      }
      return rejectWithValue(err.response);
    }
  }
);

export const rejectProposal = createAsyncThunk(
  "proposal/rejectProposal",
  async (
    { projectId, proposalId, updatedAt },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axiosClient.put(
        `/projects/${projectId}/proposals/${proposalId}/reject`,
        { updated_at: updatedAt }
      );

      dispatch(
        setToast({
          type: "success",
          message: response.data?.message || "Proposal successfully rejected.",
        })
      );

      return response.data;
    } catch (err) {
      console.log(err.response);
      dispatch(
        setToast({
          type: "error",
          message:
            err.response?.data?.message ||
            "Error occured while rejecting proposal.",
        })
      );
      if (err.response?.data?.code === "proposal_outdated") {
        dispatch(refreshProposal({ projectId, proposalId }));
      }
      return rejectWithValue(err.response);
    }
  }
);
