import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../../api/client";
import { setToast } from "../../../redux/globalReducers/alert";

export const getCSR = createAsyncThunk("reports/getCSR", async ({ page }) => {
  try {
    const response = await axiosClient.get(
      `/admin/reports/customer-support${page ? `?page=${page}` : ""}`
    );
    return response.data.reports;
  } catch (err) {
    return err.response.data.message;
  }
});

export const getPPR = createAsyncThunk("reports/getPPR", async ({ page }) => {
  try {
    const response = await axiosClient.get(
      `/admin/reports/project-problem${page ? `?page=${page}` : ""}`
    );
    return response.data.reports;
  } catch (err) {
    return err.response.data.message;
  }
});

export const getCSRCount = createAsyncThunk("reports/getCSRCount", async () => {
  try {
    const response = await axiosClient.get(
      `/admin/reports/customer-support/count`
    );
    return response.data;
  } catch (err) {
    return err.response.data.message;
  }
});

export const getProblemReportsCount = createAsyncThunk(
  "reports/getProblemReportsCount",
  async () => {
    try {
      const response = await axiosClient.get(
        `/admin/reports/project-problem/count`
      );
      return response.data;
    } catch (err) {
      return err.response.data.message;
    }
  }
);

export const updateReportStatus = createAsyncThunk(
  "reports/updateReportStatus",
  async ({ id, status, type }, { dispatch, getState }) => {
    try {
      const response = await axiosClient.put(`/admin/reports/${id}`, {
        status: status,
      });
      dispatch(setToast({ type: "success", message: response.message }));
      const reports = getState().reports;

      if (type === "csr") {
        dispatch(
          getCSR({
            page: reports?.csr?.value?.current_page
              ? reports.csr.value.current_page
              : 1,
          })
        );
      } else if (type === "ppr") {
        dispatch(
          getPPR({
            page: reports?.ppr?.value?.current_page
              ? reports.ppr.value.current_page
              : 1,
          })
        );
      }

      return response.data;
    } catch (err) {
      dispatch(
        setToast({
          type: "error",
          message: err.response.data.message,
        })
      );

      return err.response.data;
    }
  }
);
