export function authHeader() {
  // return authorization header with jwt token
  const token = JSON.parse(localStorage.getItem("accessToken"))

  if (token) {
    return `Bearer ${token}`
  } else {
    return null
  }
}
