import Avatar from "../avatar/Avatar";
import { useEffect } from "react";
import { useState } from "react";

const UserAvatar = (props) => {
  const [obj, setObj] = useState();

  useEffect(() => {
    let newObj = {};
    Object.keys(props).forEach(function (key, index) {
      if (props[key]) {
        if (key == "img") {
          if (props[key] && props[key] != "avatar.png") {
            newObj = {
              ...newObj,
              [key]: props[key],
            };
          } else {
            newObj = {
              ...newObj,
              initials: true,
            };
          }
        } else {
          newObj = { ...newObj, [key]: props[key] };
        }
      }
    });
    if (!props.img) {
      newObj = {
        ...newObj,
        content:
          newObj.content.split(" ").length > 3
            ? newObj.content.split(" ").slice(0, 3).join(" ")
            : newObj.content,
        initials: true,
      };
    }
    setObj(newObj);
  }, [props]);

  return <Avatar {...obj} />;
};

export default UserAvatar;
