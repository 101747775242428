import { createAsyncThunk } from "@reduxjs/toolkit"
import axiosClient from "../../../api/client"

export const getApplications = createAsyncThunk(
  "applications/get",
  async (inactive) => {
    try {
      const response = await axiosClient.get(
        `/applications/my?inactive=${inactive || false}`
      )
      return response.data.applications
    } catch (err) {
      return err.message
    }
  }
)

export const cancelApplication = async (
  applicationId,
  projectId,
  positionId
) => {
  try {
    const response = await axiosClient.put(
      `projects/${projectId}/positions/${positionId}/applications/${applicationId}/disable`
    )
    return response.data
  } catch (err) {
    return err.response.data.message
  }
}
