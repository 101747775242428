// ** React Imports
import { Suspense } from "react";

import { Navigate } from "react-router-dom";

import { isObjEmpty } from "utility/Utils";

import { useSelector } from "react-redux";

const ShieldRoute = ({ children, route }) => {
  const user = useSelector((state) => state.authentication.userData);

  if (isObjEmpty(user)) {
    return <Navigate to="/login" />;
  }

  // if (!checkStepsForUserRole(user, selectedRole, career)) {
  //   return <Navigate to={getHomeRouteForLoggedInUser(selectedRole)} />
  // }

  return <Suspense fallback={null}>{children}</Suspense>;
};

export default ShieldRoute;
