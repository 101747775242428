import { requiredWorkersOptions } from "helpers/constants/constants";
import { formatFileDispatch } from "helpers/functions/helpers.functions";

function getRequiredWorkers(value) {
  return value?.label && value?.value
    ? value
    : requiredWorkersOptions.find((r) => r.label.includes(value)) || {
        label: value || "undefined",
        value: value || "undefined",
      };
}

export function formatMilestonesForm(milestones) {
  if (!milestones || !milestones?.length) return [];

  return milestones.map((m, i) => {
    return {
      id: m.id,
      state:
        m.milestone_status?.status_name === "Completed"
          ? "disabled"
          : m.milestone_status?.status_name === "In progress"
          ? "ongoing"
          : "edit",
      milestone_status: m.milestone_status,
      budget_granted: m.budget_granted,
      milestone_name: m.milestone_name,
      milestone_budget: m.milestone_budget,
      completion_date: m.completion_date,
      milestone_description: m.milestone_description,
      // minDate: getTodaysDate(),
      required_workers: getRequiredWorkers(m.required_workers),
      files: m?.attachments?.length
        ? m.attachments.map((a) => {
            return {
              id: a.id,
              name: a.milestone_attachment_filename,
              path: a.milestone_attachment_path,
              type: a.milestone_attachment_type,
              size: a.milestone_attachment_size,
            };
          })
        : "",
    };
  });
}

// this function formats the milestones array for dispatch
export function formatMilestonesDispatch(milestones) {
  return milestones
    .filter((m) => m.state !== "removed")
    .map((milestone) => {
      return {
        ...(milestone?.state !== "new" ? { id: milestone.id } : {}),
        milestone_name: milestone.milestone_name,
        milestone_budget: milestone.milestone_budget,
        completion_date: milestone.completion_date,
        milestone_description: milestone.milestone_description,
        required_workers: milestone.required_workers.value,
        ...(milestone?.files?.length
          ? {
              attachments: milestone.files.map((f) => {
                return formatFileDispatch(f);
              }),
            }
          : {}),
      };
    });
}

// this function merges the changes in the milestones array
export function mergeMilestoneChanges(changes, oldMilestones) {
  let newMilestones = changes;
  if (newMilestones.length) {
    newMilestones = newMilestones.map((m, i) => {
      if (m.id && oldMilestones.some((milestone) => m.id === milestone.id)) {
        // check if a milestone has an id and exists in the oldMilestones array
        let elementIndex = null;
        let findM = oldMilestones.find((milestone, index) => {
          if (m.id === milestone.id) {
            elementIndex = index;
            return milestone;
          }
        });

        return {
          ...m,
          state: m.is_modified ? "edit" : "",
          milestone_status: findM.milestone_status,
          budget_granted: findM.budget_granted,
          ...(elementIndex !== i ? { indexFrom: elementIndex } : {}), // tracks when milestone is reorganized
          compareWith: findM,
        };
      }
      return { state: "new", ...m };
    });
  }
  // check for deleted milestones in the oldMilestones array
  // and insert them in the newMilestones array in appropriate order
  oldMilestones.forEach((milestone, i) => {
    if (!newMilestones.some((m) => m.id === milestone.id)) {
      newMilestones.splice(i, 0, {
        ...milestone,
        state: "removed",
      });
    }
  });

  return newMilestones.map((m) => {
    return {
      ...m,
      required_workers: getRequiredWorkers(m.required_workers),
    };
  });
}

// this function calculates total budget based on the milestones array
export function calculateMilestonesTotalBudget(milestones) {
  // first check if milestones is an array
  if (!Array.isArray(milestones)) return 0;
  // then filter removed milestones
  milestones = milestones.filter(
    (milestone) => milestone.state !== "removed" && milestone.milestone_budget
  );
  // then check if milestones is empty
  if (!milestones.length) return 0;
  // then reduce the array to get the total budget
  return milestones.reduce((acc, milestone) => {
    // check if budget is a number
    // transform to number if it is a string
    if (typeof milestone.milestone_budget === "string") {
      milestone.milestone_budget = parseFloat(milestone.milestone_budget);
    }
    return acc + milestone.milestone_budget;
  }, 0);
}

// this function gets delivery date based on the milestones array
export function getMilestonesDeliveryDate(milestones) {
  if (!milestones.length) return null;
  if (!milestones.filter((s) => s.state !== "removed").length) return null;
  let newMilestones = milestones.filter(
    (s) => s.state !== "removed" && s.completion_date
  );

  return newMilestones[newMilestones.length - 1].completion_date;
}

// function that checks if some of the attributes of a milestone have been modified
// returns true if modified, false if not
export function checkMilestoneChanges(milestone, compareWith) {
  if (!compareWith) return false;
  if (milestone.milestone_name !== compareWith.milestone_name) return true;
  if (milestone.milestone_budget !== compareWith.milestone_budget) return true;
  if (milestone.completion_date !== compareWith.completion_date) return true;
  if (milestone.milestone_description !== compareWith.milestone_description)
    return true;
  if (milestone.required_workers.value !== compareWith.required_workers.value)
    return true;
  return false;
}

// function that gets changes from milestone position and renders them appropriately
export function formatMilestonePositionChanges(mp) {
  if (
    mp.has_changes &&
    mp.waiting_for_approval &&
    mp.state === "new" &&
    mp.changes_approved === null &&
    mp.changes
  ) {
    return {
      ...mp,
      responsibilities: mp.changes.responsibilities,
      reward: mp.changes.reward,
    };
  }
  return mp;
}
// function that gets milestone position state
export function renderMilestonePositionState(mp) {
  if (mp.has_changes && mp.changes && mp.changes_approved === null) {
    if (mp.waiting_for_approval) {
      return "new";
    }
    if (mp.waiting_for_deletion) {
      return "removed";
    }
    if (mp.changes) {
      return "edit";
    }
  }
  return "";
}

// function that formats milestone object based on the milestone position
export function formatMilestoneForContractors(milestone, mp) {
  if (mp) {
    return {
      ...milestone,
      milestone_budget: mp.reward,
    };
  }
  return milestone;
}
// function that formats compareWith milestone object based on changes in the milestone position
export function formatMilestoneCompareWithFromMilestonePositionChanges(
  milestone,
  mp
) {
  // get reward from mp
  return milestone;
}

// function that renders css class when there are changes on the project milestones
export function getMilestoneChangesStateTextClassname(state) {
  if (!state) return "";
  switch (state) {
    case "new":
      return "text-primary";
    case "removed":
      return "text-danger text-decoration-line-through";
    // case "edit":
    //   return "text-warning";
    default:
      return "";
  }
}

// function that gets changes in milestone position reward
export function getMilestonePositionRewardChanges(mp) {
  return mp.state === "edit" &&
    mp.has_changes &&
    mp.changes &&
    mp.reward !== mp.changes.reward
    ? mp.changes.reward
    : null;
}

// function that gets changes in milestone position responsibilities
export function getMilestonePositionResponsibilitiesChanges(mp) {
  return mp.state === "edit" &&
    mp.has_changes &&
    mp.changes &&
    mp.responsibilities.some((r) =>
      mp.changes.responsibilities.some((r1) => r1.description !== r.description)
    )
    ? mp.changes.responsibilities
    : null;
}
