import { createSlice } from "@reduxjs/toolkit";
import { getProposals } from "./proposals.actions";

const initialState = {
  proposals: null,
  projectOptions: null,
  loading: false,
  error: null,
};

export const proposalsSlice = createSlice({
  name: "proposals",
  initialState,
  reducers: {
    clearProposals: (state, action) => {
      state.proposals = null;
    },
    removeProposal: (state, action) => {
      state.proposals = state.proposals.filter((p) => p.id !== action.payload);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getProposals.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProposals.fulfilled, (state, action) => {
        state.loading = false;
        state.proposals = action.payload.proposals;
        state.projectOptions = action.payload.projects;
      })
      .addCase(getProposals.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
export const { clearProposals, removeProposal } = proposalsSlice.actions;

export default proposalsSlice.reducer;
