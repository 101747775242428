// ** React Imports
import { createRoot } from "react-dom/client";
import { Suspense, lazy, useEffect } from "react";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

// ** Redux Imports
import { store } from "./redux/store";
import { Provider } from "react-redux";

// ** ThemeColors Context

import { ThemeContext } from "./utility/context/ThemeColors";

// ** ThemeConfig
import themeConfig from "./configs/themeConfig";

// ** Toast
import { Toaster } from "react-hot-toast";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

import "react-image-crop/dist/ReactCrop.css";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Hot Toast Styles
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";

// ** Service Worker
import * as serviceWorker from "./serviceWorker";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { MobileProvider } from "./utility/context/Mobile";
import { lazyRetry, setCookie } from "./utility/Utils";

// AFFILIATE COOKIE
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const referralCode = urlParams.get("referral");
if (referralCode) {
  setCookie("referral_code_qw", referralCode, 30);
}

// SENTRY INIT
if (
  process.env.REACT_APP_ENV !== "local" &&
  process.env.REACT_APP_ENV !== "development"
) {
  Sentry.init({
    dsn: "https://3eaadc3d1b124a359797929625b0bb3a@o4504009904160768.ingest.sentry.io/4504040724168704",
    tunnel: `${process.env.REACT_APP_BASENAME}/sentry`,
    release: `quantum-workflows@1.0.1`,
    environment: `${process.env.REACT_APP_ENV}`,
    initialScope: {
      tags: { user: "initial" },
      user: { id: "undefined", username: "unregistered" },
    },
    integrations: [
      new BrowserTracing({
        tracingOrigins: [`${process.env.REACT_APP_BASENAME}`],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

// ** Lazy load app
const LazyApp = lazy(() => lazyRetry(() => import("./App")));

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred in the app</p>}>
    <BrowserRouter>
      <Provider store={store}>
        <Suspense fallback={<Spinner />}>
          <MobileProvider>
            <ThemeContext>
              <LazyApp />
              <Toaster
                position={themeConfig.layout.toastPosition}
                toastOptions={{ className: "react-hot-toast" }}
              />
            </ThemeContext>
          </MobileProvider>
        </Suspense>
      </Provider>
    </BrowserRouter>
  </Sentry.ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
