import { createSlice } from "@reduxjs/toolkit"
import { getOccupations, getOccupationRequests } from "./occupations.actions"

const initialState = {
  all: {
    loading: false,
    value: null,
    error: null,
    new: 0
  },
  requests: {
    loading: false,
    value: null,
    error: null,
    new: 0
  },
  new: 0,
  error: null
}

export const occupationsSlice = createSlice({
  name: "occupations",
  initialState,
  reducers: {
    clearOccupations: (state, action) => initialState,
    newOccupation: (state, action) => {
      state.new = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getOccupations.pending, (state) => {
        state.all.loading = true
      })
      .addCase(getOccupations.fulfilled, (state, action) => {
        state.all.loading = false
        state.all.value = action.payload
        state.new = 0
      })
      .addCase(getOccupations.rejected, (state, action) => {
        state.all.loading = false
        state.all.error = action.error.message
      })
      .addCase(getOccupationRequests.pending, (state) => {
        state.requests.loading = true
      })
      .addCase(getOccupationRequests.fulfilled, (state, action) => {
        state.requests.loading = false
        state.requests.value = action.payload
        state.new = 0
      })
      .addCase(getOccupationRequests.rejected, (state, action) => {
        state.requests.loading = false
        state.requests.error = action.error.message
      })
  }
})

// Action creators are generated for each case reducer function
export const { clearOccupations, newOccupation } = occupationsSlice.actions

export default occupationsSlice.reducer
