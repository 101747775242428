import { createSlice } from "@reduxjs/toolkit"
import { getProject } from "./projectCreate.actions"

const initialState = {
  project: null,
  loading: false,
  error: null,
  form: {
    error: false,
    message: null,
    edit: false
  }
}

export const projectCreateSlice = createSlice({
  name: "projectCreate",
  initialState,
  reducers: {
    clearProjectCreate: () => initialState,
    refreshProject: (state, action) => {
      state.project = action.payload
    },
    editProject: (state, action) => {
      state.form = {
        ...state.form,
        edit: true
      }
    },
    failProjectCreate: (state, action) => {
      state.form = {
        error: true,
        message: action.payload
      }
    }
  },

  extraReducers(builder) {
    builder
      .addCase(getProject.pending, (state) => {
        state.loading = "loading"
      })
      .addCase(getProject.fulfilled, (state, action) => {
        state.loading = false
        state.project = action.payload
        state.form.edit = true
      })
      .addCase(getProject.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  }
})

// Action creators are generated for each case reducer function
export const {
  refreshProject,
  clearProjectCreate,
  failProjectCreate,
  editProject
} = projectCreateSlice.actions

export default projectCreateSlice.reducer
