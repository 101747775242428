import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "api/client";
import { setToast } from "redux/globalReducers/alert";
import { objectToFormData } from "utility/Utils";

import { fetchProject } from "./project.services";
import { formatMilestonesDispatch } from "../common/project.functions";

export const getProject = createAsyncThunk(
  "project/getProject",
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetchProject({ id: id });
      return response.data.project;
    } catch (err) {
      return rejectWithValue(err.response.status);
    }
  }
);
export const refreshProject = createAsyncThunk(
  "project/refreshProject",
  async (id) => {
    try {
      const response = await fetchProject({ id: id });
      return response.data.project;
    } catch (err) {
      return err.response.data.message;
    }
  }
);

export const getProjectLogs = createAsyncThunk(
  "project/getProjectLogs",
  async ({ id, page }) => {
    try {
      const response = await axiosClient.get(
        `/projects/${id}/logs?page=${page || 1}`
      );
      return response.data.logs;
    } catch (err) {
      return err.response.data.message;
    }
  }
);
export const getProjectProposals = createAsyncThunk(
  "project/proposals/get",
  async (id) => {
    try {
      const response = await axiosClient.get(`/projects/${id}/proposals`);
      return response.data.proposals;
    } catch (err) {
      return err.response.data.message;
    }
  }
);

// not in use
export const getProjectMilestones = createAsyncThunk(
  "project/milestones/get",
  async (id) => {
    try {
      const response = await axiosClient.get(`/projects/${id}/milestones`);
      return response.data;
    } catch (err) {
      return err.response.data.message;
    }
  }
);

export const getProjectOwner = createAsyncThunk(
  "project/getOwner",
  async (id) => {
    try {
      const response = await axiosClient.get(`/profiles/${id}/product-owner`);
      return response.data;
    } catch (err) {
      return err.response.data.message;
    }
  }
);
export const getProjectManager = createAsyncThunk(
  "project/getManager",
  async (id) => {
    try {
      const response = await axiosClient.get(`/profiles/${id}/manager`);
      return response.data;
    } catch (err) {
      return err.response.data.message;
    }
  }
);

export const getMilestonesChangesRequest = createAsyncThunk(
  "project/getMilestonesChangesRequest",
  async ({ id }) => {
    try {
      const response = await axiosClient.get(`/projects/${id}/changes`);
      return response.data.change_request;
    } catch (err) {
      console.log(err);

      return err.response;
    }
  }
);
export const refreshMilestonesChangesRequest = createAsyncThunk(
  "project/refreshMilestonesChangesRequest",
  async ({ id }) => {
    try {
      const response = await axiosClient.get(`/projects/${id}/changes`);
      return response.data.change_request;
    } catch (err) {
      console.log(err);

      return err.response;
    }
  }
);
export const requestMilestonesChanges = createAsyncThunk(
  "project/requestMilestonesChanges",
  async ({ id, review, milestones }, { dispatch }) => {
    try {
      const response = await axiosClient.post(`/projects/${id}/changes`, {
        milestones: formatMilestonesDispatch(milestones),
        description: review,
      });
      dispatch(
        setToast({
          message:
            response.data?.message ||
            "You have successfully requested changes.",
          type: "success",
        })
      );
      dispatch(refreshProject(id));
      dispatch(getMilestonesChangesRequest({ id }));
      return response.data;
    } catch (err) {
      console.log(err);
      dispatch(
        setToast({
          message: "Error requesting changes.",
          type: "error",
        })
      );

      return err.response;
    }
  }
);
export const approveMilestoneChanges = createAsyncThunk(
  "project/approveMilestoneChanges",
  async (
    { id, changeId, type, currency, email },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await axiosClient.patch(
        `/projects/${id}/changes/${changeId}/changes-approve`,
        type === "wire" && currency
          ? {
              deposit: {
                type: "wire",
                region_currency: "EUR",
              },
            }
          : type === "payoneer" && email
          ? {
              deposit: {
                type: "payoneer-card",
                email: email,
              },
            }
          : null
      );
      dispatch(refreshProject(id));
      dispatch(
        setToast({
          message:
            response.data?.message || "You have successfully accepted changes.",
          type: "success",
        })
      );
      dispatch(getMilestonesChangesRequest({ id }));
      return response.data;
    } catch (err) {
      console.log(err);
      dispatch(
        setToast({
          message: err.response?.data?.message || "Error accepting changes.",
          type: "error",
        })
      );
      if (err.response?.data?.code === "proposal_outdated") {
        dispatch(refreshMilestonesChangesRequest({ id }));
      }

      return rejectWithValue(err.response);
    }
  }
);
export const declineMilestoneChanges = createAsyncThunk(
  "project/declineMilestonesChanges",
  async ({ id, changeId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosClient.patch(
        `/projects/${id}/changes/${changeId}/changes-reject`
      );
      dispatch(
        setToast({
          message:
            response.data?.message || "You have successfully declined changes.",
          type: "success",
        })
      );
      dispatch(getMilestonesChangesRequest({ id }));
      return response.data;
    } catch (err) {
      console.log(err.response);
      dispatch(
        setToast({
          message: err.response?.data?.message || "Error declining changes.",
          type: "error",
        })
      );
      if (err.response?.data?.code === "proposal_outdated") {
        dispatch(refreshMilestonesChangesRequest({ id }));
      }

      return rejectWithValue(err.response);
    }
  }
);

export const withdrawRequestMilestonesChanges = createAsyncThunk(
  "project/withdrawRequestMilestonesChanges",
  async ({ id, changeId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosClient.patch(
        `/projects/${id}/changes/${changeId}/changes-cancel`
      );
      dispatch(
        setToast({
          message:
            response.data?.message ||
            "You have successfully withdrawn changes request.",
          type: "success",
        })
      );
      dispatch(refreshProject(id));
      dispatch(getMilestonesChangesRequest({ id }));
      return response.data;
    } catch (err) {
      dispatch(
        setToast({
          message: "Error withdrawing changes request.",
          type: "error",
        })
      );

      return rejectWithValue(err.response);
    }
  }
);
export const uploadProjectMilestoneFiles = createAsyncThunk(
  "project/uploadProjectMilestoneFiles",
  async (
    { projectId, milestoneId, attachments },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const formData = objectToFormData({ attachments });

      const response = await axiosClient.post(
        `/projects/${projectId}/milestones/${milestoneId}/attachments`,
        formData
      );

      dispatch(
        setToast({
          message: response.data?.message || "Files successfully uploaded.",
          type: "success",
        })
      );
      dispatch(refreshProject(projectId));
      return response.data;
    } catch (err) {
      dispatch(
        setToast({
          message: "Error uploading files.",
          type: "error",
        })
      );
      return rejectWithValue(err.response);
    }
  }
);
export const deleteProjectMilestoneFile = createAsyncThunk(
  "project/deleteProjectMilestoneFile",
  async ({ projectId, milestoneId, attachmentId }, { dispatch }) => {
    try {
      const response = await axiosClient.delete(
        `/projects/${projectId}/milestones/${milestoneId}/attachments/${attachmentId}`
      );
      dispatch(
        setToast({
          message: response.data?.message || "File successfully deleted.",
          type: "success",
        })
      );
      dispatch(refreshProject(projectId));
      return response.data;
    } catch (err) {
      console.log(err);
      dispatch(
        setToast({
          message: "Error deleting file.",
          type: "error",
        })
      );

      return err.response;
    }
  }
);

export const deleteProject = async ({ id }) => {
  try {
    const response = await axiosClient.delete(`/projects/${id}`);
    return response.data;
  } catch (err) {
    return err.response.data.message;
  }
};

export const hireContractor = async ({
  projectId,
  positionId,
  applicationId,
  managerComment,
}) => {
  const response = await axiosClient.put(
    `/projects/${projectId}/positions/${positionId}/applications/${applicationId}/approve`,
    {
      manager_comment: managerComment,
    }
  );
  return response;
};

export const denyContractor = async ({
  projectId,
  positionId,
  applicationId,
  managerComment,
}) => {
  const response = await axiosClient.put(
    `/projects/${projectId}/positions/${positionId}/applications/${applicationId}/reject`,
    {
      manager_comment: managerComment,
    }
  );
  return response;
};

export const requestBankTransferPayment = async ({
  id,
  milestoneId,
  currency,
}) => {
  const response = await axiosClient.post(
    `/projects/${id}/milestones/${milestoneId}/grant/wire`,
    {
      currency: currency === "REST" ? "USD" : currency,
      region_currency: currency,
    }
  );
  return response.data;
};

export const requestPayoneerPayment = async ({
  id,
  milestoneId,
  email,
  emailSave,
}) => {
  const response = await axiosClient.post(
    `/projects/${id}/milestones/${milestoneId}/grant/payoneer-card`,
    {
      email: email,
      is_email_saved: emailSave,
    }
  );
  return response.data;
};

export const requestLeaveProject = async ({ id, when, positionId }) => {
  try {
    const response = await axiosClient.post(
      `/projects/${id}/positions/${positionId}/leave-requests`,
      {
        when: when, // enum('immediate', 'after_milestone')
        // milestone_id: milestoneId // enum('immediate', 'after_milestone')
        // manager_description: "", // if sent, will be approven automatically (manager send this)
        // leave_type: "", // enum('bad_leave', 'good_leave')
        // leaver_id: "", // MANDATORY for manager
      }
    );
    return response.data;
  } catch (err) {
    return err.response.data.message;
  }
};

export const fireContractor = async ({
  id,
  when,
  positionId,
  leaverId,
  rate,
  description,
  leaveType,
}) => {
  try {
    let obj = {
      when: when,
      leaver_id: leaverId,
      manager_description: description || "",
    };

    if (leaveType) {
      obj = { ...obj, leave_type: leaveType };
    }
    if (rate) {
      obj = { ...obj, rate_value: rate };
    }
    const response = await axiosClient.post(
      `/projects/${id}/positions/${positionId}/leave-requests`,
      obj
    );
    return response.data;
  } catch (err) {
    return err.response.data.message;
  }
};

export const resolveLeaveProject = async ({
  id,
  positionId,
  leaveId,
  leaveType,
  description,
  rate,
}) => {
  try {
    let obj = {};
    if (description) {
      obj = { ...obj, manager_description: description };
    }
    if (leaveType) {
      obj = { ...obj, leave_type: leaveType };
    }
    if (rate) {
      obj = { ...obj, rate: rate };
    }
    const response = await axiosClient.put(
      `/projects/${id}/positions/${positionId}/leave-requests/${leaveId}/approve`,
      obj
    );
    return response.data;
  } catch (err) {
    return err.response.data.message;
  }
};

export const cancelLeaveProject = async ({ id, positionId, requestId }) => {
  try {
    const response = await axiosClient.delete(
      `/projects/${id}/positions/${positionId}/leave-requests/${requestId}`
    );
    return response.data;
  } catch (err) {
    return err.response.data.message;
  }
};

export const reportProjectProblem = createAsyncThunk(
  "project/reportProjectProblem",
  async ({ id, description }, { dispatch }) => {
    try {
      const response = await axiosClient.post(
        `/reports/problems/projects/${id}`,
        {
          problem_description: description,
        }
      );
      dispatch(
        setToast({
          message: "You have successfully submitted a report.",
          type: "success",
        })
      );
      return response.data;
    } catch (err) {
      dispatch(
        setToast({
          message: "Error submitting a report.",
          type: "error",
        })
      );

      return err.response.data.message;
    }
  }
);

export const changeContractorStatus = async ({
  id,
  milestoneId,
  positionId,
  statusId,
}) => {
  const response = await axiosClient.put(
    `/projects/${id}/milestones/${milestoneId}/positions/${positionId}/change-status`,
    {
      position_status_id: statusId,
    }
  );
  return response;
};

export const requestMilestoneApproval = async ({ id, milestoneId, review }) => {
  try {
    const response = await axiosClient.put(
      `/projects/${id}/milestones/${milestoneId}/approve`,
      {
        milestone_review: review,
      }
    );

    return response.data;
  } catch (err) {
    return err.response.data.message;
  }
};

export const verifyMilestoneApproval = async ({ id, milestoneId }) => {
  try {
    const response = await axiosClient.put(
      `/projects/${id}/milestones/${milestoneId}/verify-accept`
    );

    return response.data;
  } catch (err) {
    return err.response.data.message;
  }
};

// export const declineMilestoneApproval = async ({ id, milestoneId }) => {
//   try {
//     const response = await axiosClient.put(
//       `/projects/${id}/milestones/${milestoneId}/verify-decline`
//     )

//     return response.data
//   } catch (err) {
//     return err.response.data.message
//   }
// }
export const declineMilestoneApproval = createAsyncThunk(
  "project/declineMilestoneApproval",
  async ({ id, milestoneId }, { dispatch }) => {
    try {
      const response = await axiosClient.put(
        `/projects/${id}/milestones/${milestoneId}/verify-decline`
      );
      dispatch(refreshProject(id));
      return response.data;
    } catch (err) {
      return err.response.data.message;
    }
  }
);

export const rateUser = async ({
  userId,
  milestoneId,
  description,
  rate,
  positionId,
}) => {
  let obj = {
    rated_user_id: userId,
    rate_value: rate,
    milestone_id: milestoneId,
  };
  if (description) {
    obj = { ...obj, description: description };
  }
  if (positionId) {
    obj = { ...obj, position_id: positionId };
  }
  const response = await axiosClient.post(`/rates`, {
    ...obj,
  });

  return response;
};

export const starContractor = createAsyncThunk(
  "project/starContractor",
  async ({ id, positionId, applicationId }, { dispatch }) => {
    try {
      const response = await axiosClient.put(
        `/projects/${id}/positions/${positionId}/applications/${applicationId}/toggle-star`
      );
      dispatch(refreshProject(id));
      return response.data;
    } catch (err) {
      return err.response.data.message;
    }
  }
);
