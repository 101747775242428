import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../../api/client";
import { setToast } from "../../../redux/globalReducers/alert";
import { refreshProject } from "./project.actions";
import { writeProjectPosition } from "./project.services";

// not in use
export const getProjectPositions = createAsyncThunk(
  "projectPositions/getProjectPositions",
  async (id) => {
    try {
      const response = await axiosClient.get(
        `/projects/${id}/positions?open=true&closed=true`
      );
      return response.data.positions;
    } catch (err) {
      return err.response.data.message;
    }
  }
);

export const addProjectPosition = createAsyncThunk(
  "projectPositions/addProjectPosition",
  async (
    { id, primarySkill, secondarySkill, additionalSkills, position, bonus },
    { dispatch }
  ) => {
    try {
      const response = await writeProjectPosition({
        id,
        primarySkill,
        secondarySkill,
        additionalSkills,
        position,
        bonus,
        method: "post",
      });

      dispatch(refreshProject(id));
      dispatch(
        setToast({
          message:
            response.data.message ||
            "You have successfully created a new position.",
          type: "success",
        })
      );
      return response.data;
    } catch (err) {
      let errorMessage =
        err?.response?.data?.message || "Error creating a new position.";
      dispatch(
        setToast({
          message: errorMessage,
          type: "error",
        })
      );
      return errorMessage;
    }
  }
);

export const editProjectPosition = createAsyncThunk(
  "projectPositions/editProjectPosition",
  async (
    {
      id,
      positionId,
      primarySkill,
      secondarySkill,
      additionalSkills,
      position,
      bonus,
    },
    { dispatch }
  ) => {
    try {
      const response = await writeProjectPosition({
        id,
        positionId,
        primarySkill,
        secondarySkill,
        additionalSkills,
        position,
        bonus,
        method: "put",
      });

      dispatch(refreshProject(id));
      dispatch(
        setToast({
          message: response.data.message || "Position successfully updated.",
          type: "success",
        })
      );

      return response.data.position;
    } catch (err) {
      let errorMessage =
        err?.response?.data?.message || "Error changing position info.";
      dispatch(
        setToast({
          message: errorMessage,
          type: "error",
        })
      );
      return errorMessage;
    }
  }
);

export const editProjectMilestonePositions = createAsyncThunk(
  "projectPositions/editProjectMilestonePositions",
  async ({ id, position }, { dispatch }) => {
    try {
      let milestonePositions = position.milestone_positions.filter((mp) => {
        if (mp.state) {
          return mp;
        }
      });
      const response = await axiosClient.put(
        `/projects/${id}/positions/${position.id}`,
        {
          changes: milestonePositions.map((mp) => {
            return {
              milestone_id: mp.milestone_id,
              reward: mp.reward,
              ...(mp.state === "removed" ? { toDelete: true } : {}),
              responsibilities: mp.responsibilities.map((responsibility) => {
                return {
                  description: responsibility.description,
                };
              }),
            };
          }),
        }
      );
      dispatch(refreshProject(id));

      dispatch(
        setToast({
          type: "success",
          message: response.data.message || "Position successfully updated.",
        })
      );

      return response.data.position;
    } catch (err) {
      console.log(err.response.data);
      dispatch(
        setToast({
          type: "error",
          message:
            err.response.data.message ||
            "Error updating a position. Please try again later.",
        })
      );

      return err.response.data;
    }
  }
);

export const addProjectMilestonePosition = createAsyncThunk(
  "projectPositions/addProjectMilestonePosition",
  async (
    { id, positionId, milestoneId, reward, responsibilities },
    { dispatch }
  ) => {
    try {
      const response = await axiosClient.post(
        `/projects/${id}/milestones/${milestoneId}/positions/${positionId}`,
        {
          reward: reward,
          responsibilities: responsibilities.map((responsibility) => {
            return {
              description: responsibility.text,
            };
          }),
        }
      );
      dispatch(refreshProject(id));

      dispatch(
        setToast({
          message:
            response.data.message ||
            "You have successfully created a new milestone position.",
          type: "success",
        })
      );

      return response.data;
    } catch (err) {
      console.log(err);
      dispatch(
        setToast({
          message:
            err.response.data.message ||
            "Error creating a new milestone position.",
          type: "error",
        })
      );
      return err.response.data;
    }
  }
);

export const publishProjectPosition = createAsyncThunk(
  "projectPositions/publishProjectPosition",
  async ({ id, positionId }, { dispatch }) => {
    try {
      const response = await axiosClient.put(
        `/projects/${id}/positions/${positionId}/toggle-publish`
      );
      dispatch(refreshProject(id));
      dispatch(
        setToast({
          type: "success",
          message: response.data.message || "Position successfully published.",
        })
      );
      return response.data.position;
    } catch (err) {
      console.log(err.response.data);
      dispatch(
        setToast({
          type: "error",
          message:
            err.response.data.message ||
            "Error publishing a position. Please try again later.",
        })
      );
      return err.response.data;
    }
  }
);

export const deleteProjectPosition = createAsyncThunk(
  "projectPositions/deleteProjectPosition",
  async ({ id, positionId }, { dispatch }) => {
    try {
      const response = await axiosClient.delete(
        `/projects/${id}/positions/${positionId}`
      );
      dispatch(refreshProject(id));
      dispatch(
        setToast({
          type: "success",
          message: response.data.message || "Position successfully deleted.",
        })
      );
      return { id };
    } catch (err) {
      console.log(err.response.data);
      dispatch(
        setToast({
          type: "error",
          message:
            err.response.data.message ||
            "Error deleting a position. Please try again later.",
        })
      );
      return err.response.data;
    }
  }
);

export const clearMilestonePositionChanges = createAsyncThunk(
  "projectPositions/clearMilestonePositionChanges",
  async ({ id, milestoneId, positionId }, { dispatch }) => {
    try {
      const response = await axiosClient.put(
        `/projects/${id}/milestones/${milestoneId}/positions/${positionId}/changes-clear`
      );
      dispatch(refreshProject(id));
      dispatch(
        setToast({
          type: "success",
          message:
            response.data.message || "You have successfully discarded changes.",
        })
      );
      return { id };
    } catch (err) {
      console.log(err.response.data);
      dispatch(
        setToast({
          type: "error",
          message:
            err.response.data.message ||
            "Error discarding changes. Please try again later.",
        })
      );
      return err.response.data;
    }
  }
);

export const publishProjectPositions = async ({ id }) => {
  const response = await axiosClient.put(`/projects/${id}/positions/publish`);
  return response;
};
