import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "api/client";
import { setToast } from "redux/globalReducers/alert";
import { objectToFormData } from "utility/Utils";
import { refreshProject } from "./project.actions";

export const acceptMilestonePositionChanges = createAsyncThunk(
  "projectPositions/acceptMilestonePositionChanges",
  async ({ id, milestoneId, positionId }, { dispatch }) => {
    try {
      const response = await axiosClient.put(
        `/projects/${id}/milestones/${milestoneId}/positions/${positionId}/changes-accept`
      );
      dispatch(refreshProject(id));
      dispatch(
        setToast({
          type: "success",
          message: response.data.message || "Changes successfully accepted.",
        })
      );
      return response.data;
    } catch (err) {
      console.log(err.response.data);
      dispatch(
        setToast({
          type: "error",
          message:
            err.response.data.message ||
            "Error accepting changes. Please try again later.",
        })
      );
      return err.response.data;
    }
  }
);
export const denyMilestonePositionChanges = createAsyncThunk(
  "projectPositions/denyMilestonePositionChanges",
  async ({ id, milestoneId, positionId }, { dispatch }) => {
    try {
      const response = await axiosClient.put(
        `/projects/${id}/milestones/${milestoneId}/positions/${positionId}/changes-decline`
      );
      dispatch(refreshProject(id));
      dispatch(
        setToast({
          type: "success",
          message: response.data.message || "Changes successfully declined.",
        })
      );
      return response.data;
    } catch (err) {
      console.log(err.response.data);
      dispatch(
        setToast({
          type: "error",
          message:
            err.response.data.message ||
            "Error declining changes. Please try again later.",
        })
      );
      return err.response.data;
    }
  }
);
