import { createSlice } from "@reduxjs/toolkit"
import { getPosition, refreshPosition } from "./position.actions"

const initialState = {
  position: null,
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null
}

export const positionSlice = createSlice({
  name: "position",
  initialState,
  reducers: {
    clearPosition: (state) => {
      state.position = null
      state.status = "idle"
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getPosition.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getPosition.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.position = action.payload
      })
      .addCase(getPosition.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(refreshPosition.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.position = action.payload
      })
  }
})

// Action creators are generated for each case reducer function
export const { clearPosition } = positionSlice.actions

export default positionSlice.reducer
