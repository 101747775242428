import { lazy } from "react"
import { lazyRetry } from "../../../utility/Utils"

const PaymentsPage = lazy(() =>
  lazyRetry(() => import("../pages/PaymentsPage/PaymentsPage"))
)
const TransactionsPage = lazy(() =>
  lazyRetry(() => import("../pages/TransactionsPage/TransactionsPage"))
)
const AdminPaymentsWrapper = lazy(() =>
  lazyRetry(() =>
    import("../wrappers/AdminPaymentsWrapper/AdminPaymentsWrapper")
  )
)
const AdminPayoutsWrapper = lazy(() =>
  lazyRetry(() => import("../wrappers/AdminPayoutsWrapper/AdminPayoutsWrapper"))
)
const AdminPayoutsWire = lazy(() =>
  lazyRetry(() => import("../pages/AdminPayoutsWire/AdminPayoutsWire"))
)
const AdminPayoutsPayoneer = lazy(() =>
  lazyRetry(() => import("../pages/AdminPayoutsPayoneer/AdminPayoutsPayoneer"))
)
const AdminPaymentsWire = lazy(() =>
  lazyRetry(() => import("../pages/AdminPaymentsWire/AdminPaymentsWire"))
)
const AdminPaymentsPayoneer = lazy(() =>
  lazyRetry(() =>
    import("../pages/AdminPaymentsPayoneer/AdminPaymentsPayoneer")
  )
)

const paymentRoutes = [
  {
    path: "/payments",
    element: <PaymentsPage />,
    meta: {
      private: true,
      roles: ["Contractor", "Project Manager"]
    }
  },
  {
    path: "/transactions",
    element: <TransactionsPage />,
    meta: {
      private: true,
      roles: ["Project Owner"]
    }
  },

  {
    path: "/admin/payments",
    element: <AdminPaymentsWrapper />,
    meta: {
      private: true,
      roles: ["Admin"]
    },
    children: [
      {
        index: true,
        element: <AdminPaymentsWire />,
        meta: {
          private: true,
          roles: ["Admin"]
        }
      },
      {
        path: "payoneer",
        element: <AdminPaymentsPayoneer />,
        meta: {
          private: true,
          roles: ["Admin"]
        }
      }
    ]
  },
  {
    path: "/admin/payouts",
    element: <AdminPayoutsWrapper />,
    meta: {
      private: true,
      roles: ["Admin"]
    },
    children: [
      {
        index: true,
        element: <AdminPayoutsWire />,
        meta: {
          private: true,
          roles: ["Admin"]
        }
      },
      {
        path: "payoneer",
        element: <AdminPayoutsPayoneer />,
        meta: {
          private: true,
          roles: ["Admin"]
        }
      }
    ]
  }
]

export default paymentRoutes
