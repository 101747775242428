import { createSlice } from "@reduxjs/toolkit"
import { getIncomingRates } from "./rates.actions"

const initialState = {
  rates: null,
  loading: false,
  error: null
}

export const ratesSlice = createSlice({
  name: "rates",
  initialState,
  reducers: {
    clearRates: (state, action) => initialState
  },
  extraReducers(builder) {
    builder
      .addCase(getIncomingRates.pending, (state) => {
        state.loading = true
      })
      .addCase(getIncomingRates.fulfilled, (state, action) => {
        state.loading = false
        state.rates = action.payload
      })
      .addCase(getIncomingRates.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  }
})

// Action creators are generated for each case reducer function
export const { clearRates } = ratesSlice.actions

export default ratesSlice.reducer
