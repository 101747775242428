import { createSlice } from "@reduxjs/toolkit";
import {
  getProject,
  getProjectOwner,
  getProjectProposals,
  getProjectManager,
  getProjectLogs,
  refreshProject,
  requestMilestonesChanges,
  getMilestonesChangesRequest,
  withdrawRequestMilestonesChanges,
  approveMilestoneChanges,
  declineMilestoneChanges,
  refreshMilestonesChangesRequest,
} from "./project.actions";

import {
  getProjectPositions,
  editProjectPosition,
  editProjectMilestonePositions,
  addProjectPosition,
  addProjectMilestonePosition,
  deleteProjectPosition,
  publishProjectPosition,
  clearMilestonePositionChanges,
} from "./manageProjectPositions.actions";
import { apiErrorValue } from "redux/constants";
import {
  acceptMilestonePositionChanges,
  denyMilestonePositionChanges,
} from "./projectMilestones.actions";

const initialState = {
  project: null,
  owner: null,
  manager: null,
  milestones: {
    value: null,
    changes: null,
    loading: false,
    processing: false,
    error: apiErrorValue,
  },
  activeMilestone: {
    value: null,
    changes: null,
    loading: false,
    processing: false,
    error: apiErrorValue,
  },
  activeMilestonePosition: {
    value: null,
    changes: null,
    loading: false,
    processing: false,
    error: apiErrorValue,
  },
  proposals: {
    value: null,
    loading: false,
    processing: false,
    error: null,
  },
  positions: {
    value: null,
    loading: false,
    processing: false,
    error: null,
  },
  logs: {
    value: null,
    page: 0,
    loading: false,
    processing: false,
    error: null,
  },
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  errorCode: null,
};

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProject: (state, action) => {
      return {
        ...state,
        project: action.payload,
      };
    },
    clearProject: () => initialState,
    clearMilestonesChangesRequest: (state) => {
      state.milestones.changes = initialState.milestones.changes;
    },
    clearProjectLogs: (state) => {
      return {
        ...state,
        logs: {
          loading: false,
          value: null,
          page: 0,
        },
      };
    },
    updatePositionStatus: (state) => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getProject.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProject.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.project = action.payload;
        state.positions.value = action.payload.positions;
        state.milestones.value = action.payload.milestones;
        state.error = null;
        state.errorCode = null;
      })
      .addCase(getProject.rejected, (state, action) => {
        state.status = "failed";
        state.error = true;
        state.errorCode = action.payload;
      })
      .addCase(refreshProject.fulfilled, (state, action) => {
        state.project = action.payload;
        state.positions.value = action.payload.positions;
        state.milestones.value = action.payload.milestones;
      })
      .addCase(getProjectOwner.fulfilled, (state, action) => {
        state.owner = action.payload;
      })
      .addCase(getProjectProposals.pending, (state, action) => {
        state.proposals.loading = true;
      })
      .addCase(getProjectProposals.fulfilled, (state, action) => {
        state.proposals.loading = false;
        state.proposals.value = action.payload;
      })
      .addCase(getProjectProposals.rejected, (state, action) => {
        state.proposals.loading = false;
        state.proposals.error = true;
      })
      .addCase(getProjectPositions.fulfilled, (state, action) => {
        state.positions = action.payload;
      })
      .addCase(getProjectManager.fulfilled, (state, action) => {
        state.manager = action.payload;
      })
      .addCase(getProjectLogs.pending, (state, action) => {
        state.logs.loading = true;
      })
      .addCase(getProjectLogs.fulfilled, (state, action) => {
        state.logs.loading = false;
        state.logs.page =
          action.payload && action.payload.length > 0
            ? state.logs.page + 1
            : state.logs.page;
        state.logs.value =
          state.logs.value && Array.isArray(state.logs.value)
            ? state.logs.value.concat(action.payload)
            : action.payload;
      })
      .addCase(getProjectLogs.rejected, (state, action) => {
        state.logs.loading = false;
        state.logs.error = true;
      })
      .addCase(addProjectPosition.pending, (state, action) => {
        state.positions.processing = true;
        state.positions.error = false;
      })
      .addCase(addProjectPosition.fulfilled, (state, action) => {
        state.positions.processing = false;
        state.positions.value = [
          ...state.positions.value,
          action.payload.position,
        ];
        state.positions.error = false;
      })
      .addCase(addProjectPosition.rejected, (state, action) => {
        state.positions.processing = false;
        state.positions.error = true;
      })
      .addCase(deleteProjectPosition.pending, (state, action) => {
        state.positions.processing = true;
        state.positions.error = false;
      })
      .addCase(deleteProjectPosition.fulfilled, (state, action) => {
        state.positions.processing = false;
        state.positions.value = state.positions.value.filter(
          (p) => p.id !== action.payload.id
        );
        state.positions.error = false;
      })
      .addCase(deleteProjectPosition.rejected, (state, action) => {
        state.positions.processing = false;
        state.positions.error = true;
      })
      .addCase(editProjectPosition.pending, (state, action) => {
        state.positions.processing = true;
        state.positions.error = false;
      })
      .addCase(editProjectPosition.fulfilled, (state, action) => {
        state.positions.processing = false;
        state.positions.value = state.positions.value.map((p) => {
          if (p.id === action.payload?.id) {
            return action.payload;
          }
          return p;
        });
        state.positions.error = false;
      })
      .addCase(editProjectPosition.rejected, (state, action) => {
        state.positions.processing = false;
        state.positions.error = true;
      })
      .addCase(publishProjectPosition.pending, (state, action) => {
        state.positions.processing = true;
        state.positions.error = false;
      })
      .addCase(publishProjectPosition.fulfilled, (state, action) => {
        state.positions.processing = false;
        state.positions.value = state.positions.value.map((p) => {
          if (p.id === action.payload?.id) {
            return action.payload;
          }
          return p;
        });
        state.positions.error = false;
      })
      .addCase(publishProjectPosition.rejected, (state, action) => {
        state.positions.processing = false;
        state.positions.error = true;
      })
      .addCase(editProjectMilestonePositions.pending, (state, action) => {
        state.positions.processing = true;
        state.positions.error = false;
      })
      .addCase(editProjectMilestonePositions.fulfilled, (state, action) => {
        state.positions.processing = false;
        state.positions.value = state.positions.value.map((p) => {
          if (p.id === action.payload?.id) {
            return action.payload;
          }
          return p;
        });
        state.positions.error = false;
      })
      .addCase(editProjectMilestonePositions.rejected, (state, action) => {
        state.positions.processing = false;
        state.positions.error = true;
      })
      .addCase(addProjectMilestonePosition.pending, (state, action) => {
        state.positions.processing = true;
        state.positions.error = false;
      })
      .addCase(addProjectMilestonePosition.fulfilled, (state, action) => {
        state.positions.processing = false;
        state.positions.error = false;
      })
      .addCase(addProjectMilestonePosition.rejected, (state, action) => {
        state.positions.processing = false;
        state.positions.error = true;
      })
      .addCase(getMilestonesChangesRequest.pending, (state) => {
        state.milestones.loading = true;
        state.milestones.error = initialState.milestones.error;
      })
      .addCase(getMilestonesChangesRequest.fulfilled, (state, { payload }) => {
        state.milestones.loading = false;
        state.milestones.changes = payload;
        state.milestones.error = initialState.milestones.error;
      })
      .addCase(getMilestonesChangesRequest.rejected, (state, action) => {
        state.milestones.loading = false;
        state.milestones.error = {
          isError: true,
          statusCode: action.payload.status,
          message: action.payload.data.message,
        };
      })
      .addCase(
        refreshMilestonesChangesRequest.fulfilled,
        (state, { payload }) => {
          state.milestones.changes = payload;
        }
      )
      .addCase(requestMilestonesChanges.pending, (state) => {
        state.milestones.processing = true;
        state.milestones.error = initialState.milestones.error;
      })
      .addCase(requestMilestonesChanges.fulfilled, (state) => {
        state.milestones.processing = false;
        state.milestones.error = initialState.milestones.error;
      })
      .addCase(requestMilestonesChanges.rejected, (state, action) => {
        state.milestones.processing = false;
        state.milestones.error = {
          isError: true,
          statusCode: action.payload.status,
          message: action.payload.data.message,
        };
      })
      .addCase(approveMilestoneChanges.pending, (state) => {
        state.milestones.processing = true;
        state.milestones.error = initialState.milestones.error;
      })
      .addCase(approveMilestoneChanges.fulfilled, (state) => {
        state.milestones.processing = false;
        state.milestones.error = initialState.milestones.error;
      })
      .addCase(approveMilestoneChanges.rejected, (state, { payload }) => {
        state.milestones.processing = false;
        state.milestones.error = {
          isError: true,
          statusCode: payload.status,
          code: payload.data.code,
          message: payload.data.message,
        };
      })
      .addCase(declineMilestoneChanges.pending, (state) => {
        state.milestones.processing = true;
        state.milestones.error = initialState.milestones.error;
      })
      .addCase(declineMilestoneChanges.fulfilled, (state) => {
        state.milestones.processing = false;
        state.milestones.error = initialState.milestones.error;
      })
      .addCase(declineMilestoneChanges.rejected, (state, { payload }) => {
        console.log(payload);
        state.milestones.processing = false;
        state.milestones.error = {
          isError: true,
          statusCode: payload.status,
          code: payload.data.code,
          message: payload.data.message,
        };
      })
      .addCase(withdrawRequestMilestonesChanges.pending, (state) => {
        state.milestones.processing = true;
        state.milestones.error = initialState.milestones.error;
      })
      .addCase(withdrawRequestMilestonesChanges.fulfilled, (state) => {
        state.milestones.processing = false;
        state.milestones.error = initialState.milestones.error;
      })
      .addCase(
        withdrawRequestMilestonesChanges.rejected,
        (state, { payload }) => {
          state.milestones.processing = false;
          state.milestones.error = {
            isError: true,
            statusCode: payload.status,
            code: payload.data.code,
            message: payload.data.message,
          };
        }
      )
      .addCase(acceptMilestonePositionChanges.pending, (state) => {
        state.activeMilestonePosition.processing = true;
        state.activeMilestonePosition.error =
          initialState.activeMilestonePosition.error;
      })
      .addCase(acceptMilestonePositionChanges.fulfilled, (state) => {
        state.activeMilestonePosition.processing = false;
        state.activeMilestonePosition.error =
          initialState.activeMilestonePosition.error;
      })
      .addCase(
        acceptMilestonePositionChanges.rejected,
        (state, { payload }) => {
          state.activeMilestonePosition.processing = false;
          state.activeMilestonePosition.error = {
            isError: true,
            statusCode: payload.status,
            code: payload.data.code,
            message: payload.data.message,
          };
        }
      )
      .addCase(denyMilestonePositionChanges.pending, (state) => {
        state.activeMilestonePosition.processing = true;
        state.activeMilestonePosition.error =
          initialState.activeMilestonePosition.error;
      })
      .addCase(denyMilestonePositionChanges.fulfilled, (state) => {
        state.activeMilestonePosition.processing = false;
        state.activeMilestonePosition.error =
          initialState.activeMilestonePosition.error;
      })
      .addCase(denyMilestonePositionChanges.rejected, (state, { payload }) => {
        state.activeMilestonePosition.processing = false;
        state.activeMilestonePosition.error = {
          isError: true,
          statusCode: payload.status,
          code: payload.data.code,
          message: payload.data.message,
        };
      })
      .addCase(clearMilestonePositionChanges.pending, (state) => {
        state.positions.processing = true;
        state.positions.error = initialState.positions.error;
      })
      .addCase(clearMilestonePositionChanges.fulfilled, (state) => {
        state.positions.processing = false;
        state.positions.error = initialState.positions.error;
      })
      .addCase(clearMilestonePositionChanges.rejected, (state, { payload }) => {
        state.positions.processing = false;
        state.positions.error = {
          isError: true,
          statusCode: payload.status,
          code: payload.data.code,
          message: payload.data.message,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  clearProject,
  clearMilestonesChangesRequest,
  clearProjectLogs,
  setProject,
} = projectSlice.actions;

export default projectSlice.reducer;
