import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../../api/client";
import { setToast } from "../../../redux/globalReducers/alert";

export const getOccupations = createAsyncThunk(
  "occupations/getOccupations",
  async ({ page }) => {
    try {
      const response = await axiosClient.get(
        `/admin/occupations${page ? `?page=${page}` : ""}`
      );
      return response.data.occupations;
    } catch (err) {
      return err.response.data.message;
    }
  }
);

export const getOccupationRequests = createAsyncThunk(
  "occupations/getOccupationRequests",
  async ({ page }) => {
    try {
      const response = await axiosClient.get(
        `/admin/occupations/requests${page ? `?page=${page}` : ""}`
      );
      return response.data.users;
    } catch (err) {
      return err.response.data.message;
    }
  }
);

export const createNewOccupation = createAsyncThunk(
  "occupations/createNew",
  async ({ name }, { dispatch }) => {
    try {
      const response = await axiosClient.post(`/admin/occupations/`, {
        occupation_name: name,
      });
      dispatch(setToast({ type: "success", message: response.data.message }));
      dispatch(getOccupations({ page: 1 }));

      return response.data.occupations;
    } catch (err) {
      dispatch(
        setToast({
          type: "error",
          message: err.response.data.message,
        })
      );
      return err.response.data.message;
    }
  }
);

export const approveOccupationRequest = createAsyncThunk(
  "occupations/approveRequest",
  async ({ id, name }, { dispatch, getState }) => {
    try {
      var body = {
        occupation_name: name,
      };
      body = { ...body };
      const response = await axiosClient.post(
        `/admin/occupations/${id}/approve`,
        body
      );
      dispatch(setToast({ type: "success", message: response.data.message }));

      dispatch(
        getOccupationRequests({
          page: 1,
        })
      );

      return response.data;
    } catch (err) {
      dispatch(
        setToast({
          type: "error",
          message: err.response.data.message,
        })
      );

      return err.response.data;
    }
  }
);
export const rejectOccupationRequest = createAsyncThunk(
  "occupations/rejectRequest",
  async ({ id, occupation_id }, { dispatch, getState }) => {
    try {
      const response = await axiosClient.post(
        `/admin/occupations/${id}/reject`,
        {
          new_occupation_id: occupation_id,
        }
      );
      dispatch(setToast({ type: "success", message: response.data.message }));

      dispatch(
        getOccupationRequests({
          page: 1,
        })
      );

      return response.data;
    } catch (err) {
      dispatch(
        setToast({
          type: "error",
          message: err.response.data.message,
        })
      );

      return err.response.data;
    }
  }
);
