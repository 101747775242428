import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../../api/client";

export const getUser = createAsyncThunk("user/get", async (id) => {
  try {
    const response = await axiosClient.get(`/profiles/${id}`);
    return response.data;
  } catch (err) {
    return err.message;
  }
});

export const inviteManager = async ({ projectId, userId, description }) => {
  const response = await axiosClient.post(
    `/projects/${projectId}/invitations/managers`,
    {
      invitations: [
        {
          user_id: userId,
          description: description,
        },
      ],
      append: true,
    }
  );
  return response;
};

export const inviteContractor = async ({
  projectId,
  positionId,
  userId,
  description,
}) => {
  const response = await axiosClient.post(
    `/projects/${projectId}/invitations/contractors`,
    {
      invitations: [
        {
          user_id: userId,
          position_id: positionId,
          description: description,
        },
      ],
      append: true,
    }
  );
  return response;
};
