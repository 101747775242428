import {
  handleLayoutClassname,
  handlePageType,
} from "layouts/reducer/layout.reducer";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

// layout middleware component is a wrapper for all routes
// it accepts route meta and dispatches layout actions
// each route can have classname and layout type

const LayoutMiddleware = ({ children, routeMeta }) => {
  const dispatch = useDispatch();

  // ** Clean Up Function
  const cleanUp = () => {
    if (routeMeta) {
      if (routeMeta.className) {
        dispatch(handleLayoutClassname(""));
      }
      if (routeMeta.pageType) {
        dispatch(handlePageType(null));
      }
      if (routeMeta.contentWidth) {
        dispatch(handleContentWidth("full"));
      }
      if (routeMeta.menuCollapsed) {
        dispatch(handleMenuCollapsed(!routeMeta.menuCollapsed));
      }
      if (routeMeta.menuHidden) {
        dispatch(handleMenuHidden(!routeMeta.menuHidden));
      }
    }
  };

  // ** ComponentDidMount
  useEffect(() => {
    if (routeMeta) {
      dispatch(handleLayoutClassname(routeMeta.className || ""));

      dispatch(handlePageType(routeMeta.appLayout ? "chat" : "scrollable"));
      if (routeMeta.contentWidth) {
        dispatch(handleContentWidth(routeMeta.contentWidth));
      }
      if (routeMeta.menuCollapsed) {
        dispatch(handleMenuCollapsed(routeMeta.menuCollapsed));
      }
      if (routeMeta.menuHidden) {
        dispatch(handleMenuHidden(routeMeta.menuHidden));
      }
    }
    return () => cleanUp();
  }, [routeMeta]);

  return children;
};

export default LayoutMiddleware;
