import { createSlice } from "@reduxjs/toolkit";
import {
  getAdminDeposits,
  getAdminWithdraws,
  getBalance,
  getDeposits,
  getMyTransactions,
  getRewards,
  getTransactions,
  getUserTransactions,
  updateDepositStatus,
  updateWithdrawStatus,
  withdrawPayoneerRequest,
  withdrawRequest,
  withdrawWireRequest,
} from "./payments.actions";

const initialState = {
  balance: {
    loading: false,
    value: null,
    error: null,
  },
  transactions: {
    loading: false,
    value: null,
    error: null,
  },
  rewards: {
    loading: false,
    value: null,
    error: null,
  },
  withdraws: {
    loading: false,
    value: null,
    processing: false,
    error: null,
  },
  deposits: {
    loading: false,
    value: null,
    processing: false,
    error: null,
  },
  new: 0,
  error: null,
};

export const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    clearPayments: (state, action) => initialState,
    newPayment: (state, action) => {
      state.new = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getRewards.pending, (state) => {
        state.rewards.loading = true;
      })
      .addCase(getRewards.fulfilled, (state, action) => {
        state.rewards.loading = false;
        state.rewards.value = action.payload;
        state.new = 0;
      })
      .addCase(getRewards.rejected, (state, action) => {
        state.rewards.loading = false;
        state.rewards.error = action.error.message;
      })
      .addCase(getBalance.pending, (state) => {
        state.balance.loading = true;
      })
      .addCase(getBalance.fulfilled, (state, action) => {
        state.balance.loading = false;
        state.balance.value = action.payload;
        state.new = 0;
      })
      .addCase(getBalance.rejected, (state, action) => {
        state.balance.loading = false;
        state.balance.error = action.error.message;
      })
      .addCase(getTransactions.pending, (state) => {
        state.transactions.loading = true;
      })
      .addCase(getTransactions.fulfilled, (state, action) => {
        state.transactions.loading = false;
        state.transactions.value = action.payload;
        state.new = 0;
      })
      .addCase(getTransactions.rejected, (state, action) => {
        state.transactions.loading = false;
        state.transactions.error = action.error.message;
      })
      .addCase(getMyTransactions.pending, (state) => {
        state.transactions.loading = true;
      })
      .addCase(getMyTransactions.fulfilled, (state, action) => {
        state.transactions.loading = false;
        state.transactions.value = action.payload.transactions;
        state.new = 0;
      })
      .addCase(getMyTransactions.rejected, (state, action) => {
        state.transactions.loading = false;
        state.transactions.error = action.error.message;
      })
      .addCase(getUserTransactions.pending, (state) => {
        state.transactions.loading = true;
      })
      .addCase(getUserTransactions.fulfilled, (state, action) => {
        state.transactions.loading = false;
        state.transactions.value = action.payload.transactions;
        state.new = 0;
      })
      .addCase(getUserTransactions.rejected, (state, action) => {
        state.transactions.loading = false;
        state.transactions.error = action.error.message;
      })
      .addCase(getDeposits.pending, (state) => {
        state.transactions.loading = true;
      })
      .addCase(getDeposits.fulfilled, (state, action) => {
        state.transactions.loading = false;
        state.transactions.value = action.payload.deposits;
        state.new = 0;
      })
      .addCase(getDeposits.rejected, (state, action) => {
        state.transactions.loading = false;
        state.transactions.error = action.error.message;
      })
      .addCase(getAdminWithdraws.pending, (state) => {
        state.withdraws.loading = true;
      })
      .addCase(getAdminWithdraws.fulfilled, (state, action) => {
        state.withdraws.loading = false;
        state.withdraws.value = action.payload.withdraws;
        state.new = 0;
      })
      .addCase(getAdminWithdraws.rejected, (state, action) => {
        state.withdraws.loading = false;
        state.withdraws.error = action.error.message;
      })
      .addCase(getAdminDeposits.pending, (state) => {
        state.deposits.loading = true;
      })
      .addCase(getAdminDeposits.fulfilled, (state, action) => {
        state.deposits.loading = false;
        state.deposits.value = action.payload.deposits;
        state.new = 0;
      })
      .addCase(getAdminDeposits.rejected, (state, action) => {
        state.deposits.loading = false;
        state.deposits.error = action.error.message;
      })
      .addCase(updateDepositStatus.pending, (state) => {
        state.deposits.processing = true;
      })
      .addCase(updateDepositStatus.fulfilled, (state, action) => {
        state.deposits.processing = false;
      })
      .addCase(updateDepositStatus.rejected, (state, action) => {
        state.deposits.processing = false;
      })
      .addCase(updateWithdrawStatus.pending, (state) => {
        state.withdraws.processing = true;
      })
      .addCase(updateWithdrawStatus.fulfilled, (state, action) => {
        state.withdraws.processing = false;
      })
      .addCase(updateWithdrawStatus.rejected, (state, action) => {
        state.withdraws.processing = false;
      })
      .addCase(withdrawWireRequest.pending, (state) => {
        state.withdraws.processing = true;
      })
      .addCase(withdrawWireRequest.fulfilled, (state, action) => {
        state.withdraws.processing = false;
      })
      .addCase(withdrawWireRequest.rejected, (state, action) => {
        state.withdraws.processing = false;
      })
      .addCase(withdrawPayoneerRequest.pending, (state) => {
        state.withdraws.processing = true;
      })
      .addCase(withdrawPayoneerRequest.fulfilled, (state, action) => {
        state.withdraws.processing = false;
      })
      .addCase(withdrawPayoneerRequest.rejected, (state, action) => {
        state.withdraws.processing = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const { clearPayments, newPayment } = paymentsSlice.actions;

export default paymentsSlice.reducer;
