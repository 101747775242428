// ** React Imports
import { Fragment } from "react";
// ** Layouts
import BlankLayout from "@layouts/BlankLayout";
import VerticalLayout from "@src/layouts/VerticalLayout";
import HorizontalLayout from "@src/layouts/HorizontalLayout";

// ** Routes Imports
import authenticationRoutes from "../../views/auth/routes";
import appRoutes from "./appRoutes";

// ** Utils
import exceptionRoutes from "./exceptionRoutes";
import MergeLayoutRoutes from "./MergeLayoutRoutes";

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
  horizontal: <HorizontalLayout />,
};

// ** Default Route
const DefaultRoute = "/dashboard";

// ** Merge Routes
const routes = [...exceptionRoutes, ...authenticationRoutes, ...appRoutes];

const getRoutes = () => {
  const defaultLayout = "vertical";
  const layouts = ["vertical", "horizontal", "blank"];

  const allRoutes = [];

  layouts.forEach((layoutItem) => {
    const LayoutRoutes = MergeLayoutRoutes(routes, layoutItem, defaultLayout);

    allRoutes.push({
      path: "/",
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes,
    });
  });
  return allRoutes;
};

export { DefaultRoute, getRoutes };
