import { createAsyncThunk } from "@reduxjs/toolkit"
import axiosClient from "../../../api/client"

export const getTodos = createAsyncThunk(
  "todos/get",
  async ({ page }, { dispatch }) => {
    try {
      const response = await axiosClient.get(`/todos/my?page=${page || 1}`)
      dispatch(getTodosCount())
      return response.data.todos
    } catch (err) {
      return err.message
    }
  }
)
export const getTodosCount = createAsyncThunk("todos/getCount", async () => {
  try {
    const response = await axiosClient.get(`/todos/count`)
    return response.data.count
  } catch (err) {
    return err.message
  }
})
