import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../../api/client";
import { isStringEmpty } from "../../../utility/Utils";

// INPUT
// search: "",
//     position: null,
//     skills: [],
//     seniority: null,
//     industry: null,
//     job_value: null,
//     number_of_milestones: null,
//     first_milestone_date_after: "",
//     last_milestone_date_before: "",
//     proposalsNumber: "",
//     language: null,
//     pmSeniority: null,
//     pmTimezone: null,
//     projectStatus: null,
//     projectDeadline: null

export const explorePositions = createAsyncThunk(
  "positions/browse",
  async ({ page, input }) => {
    try {
      let params = {
        // position_id: "",
        // skill_ids: ["1"],
        // seniority_id: "",
        // job_value: "",
        // pm_seniority_id: "",
        // pm_timezone: "",
        // project_status_id: "",
        // number_of_milestones: "",
        // industry: [],
        // project_deadline_id: "",
        // pm_language_id: "",
        // first_milestone_date_after: "",
        // last_milestone_date_before: ""
      };
      input &&
        Object.keys(input).forEach((key) => {
          if (input[key]) {
            if (key === "skills" && input[key].length > 0) {
              params = { ...params, skill_ids: input[key].map((s) => s.id) };
            }
            if (key === "position") {
              params = { ...params, position_id: input[key].id };
            }
            if (key === "seniority") {
              params = { ...params, seniority_id: input[key].id };
            }
            if (key === "job_value") {
              params = { ...params, job_value: input[key].value };
            }
            if (key === "industry" && input[key].length > 0) {
              params = { ...params, industry: input[key].map((s) => s.id) };
            }
            if (key === "number_of_milestones") {
              params = { ...params, number_of_milestones: input[key].value };
            }

            if (
              key === "first_milestone_date_after" &&
              !isStringEmpty(input[key])
            ) {
              params = { ...params, first_milestone_date_after: input[key] };
            }
            if (
              key === "last_milestone_date_before" &&
              !isStringEmpty(input[key])
            ) {
              params = { ...params, last_milestone_date_before: input[key] };
            }
            if (key === "language") {
              params = { ...params, pm_language_id: input[key].id };
            }
            if (key === "pmSeniority") {
              params = { ...params, pm_seniority_id: input[key].id };
            }
            if (key === "projectDeadline") {
              params = { ...params, project_deadline_id: input[key].id };
            }
            if (key === "projectStatus") {
              params = { ...params, project_status_id: input[key].id };
            }
            if (key === "search") {
              params = { ...params, project_name: input[key] };
            }
          }
        });

      const response = await axiosClient.get(
        `/projects/contractors/browse${page ? `?page=${page}` : ""}`,
        {
          params: params,
        }
      );
      return response.data.positions;
    } catch (err) {
      return err.message;
    }
  }
);
