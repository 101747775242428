import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../../api/client";
import { setToast } from "../../../redux/globalReducers/alert";
import {
  getAffiliateRewards,
  getAffiliateStats,
} from "../../affiliate/reducer/affiliate.actions";

export const getRewards = createAsyncThunk("payments/getRewards", async () => {
  try {
    const response = await axiosClient.get(`/rewards`);
    return response.data;
  } catch (err) {
    return err.response.data.message;
  }
});
export const getBalance = createAsyncThunk(
  "payments/getTransactionsEscrow",
  async () => {
    try {
      const response = await axiosClient.get(`/transactions/escrow`);
      return response.data;
    } catch (err) {
      return err.response.data.message;
    }
  }
);
export const getTransactions = createAsyncThunk(
  "payments/getTransactions",
  async () => {
    try {
      const response = await axiosClient.get(`/transactions/wire`);
      return response.data;
    } catch (err) {
      return err.response.data.message;
    }
  }
);

export const getDeposits = createAsyncThunk(
  "payments/getDeposits",
  async (page) => {
    try {
      const response = await axiosClient.get(`/transactions/deposits`);
      return response.data;
    } catch (err) {
      return err.response.data.message;
    }
  }
);

export const getMyTransactions = createAsyncThunk(
  "payments/getMyTransactions",
  async ({ page, date }) => {
    try {
      let dateFrom = date ? date.split("to")[0]?.trim() : null;
      let dateTo = date ? date.split("to")[1]?.trim() : null;
      if (dateTo) {
        let newDate = new Date();
        dateTo = new Date(dateTo);
        dateTo = new Date(newDate.setDate(dateTo.getDate() + 1));
        dateTo = dateTo.toISOString();
      }
      const response = await axiosClient.get(
        `/transactions/my?page=${page}${
          dateFrom && dateTo ? `&between=created_at,${dateFrom},${dateTo}` : ""
        }`
      );
      return response.data;
    } catch (err) {
      console.log(err);
      return err.response.data.message;
    }
  }
);
export const getUserTransactions = createAsyncThunk(
  "payments/getUserTransactions",
  async ({ id, page, date }) => {
    try {
      let dateFrom = date ? date.split("to")[0]?.trim() : null;
      let dateTo = date ? date.split("to")[1]?.trim() : null;
      if (dateTo) {
        let newDate = new Date();
        dateTo = new Date(dateTo);
        dateTo = new Date(newDate.setDate(dateTo.getDate() + 1));
        dateTo = dateTo.toISOString();
      }
      const response = await axiosClient.get(
        `/admin/users/${id}/transactions?page=${page}${
          dateFrom && dateTo ? `&between=created_at,${dateFrom},${dateTo}` : ""
        }`
      );
      return response.data;
    } catch (err) {
      return err.response.data.message;
    }
  }
);

export const withdrawWireRequest = createAsyncThunk(
  "payments/withdrawWireRequest",
  async (
    { amount, instructions, instructionsSave },
    { dispatch, getState }
  ) => {
    try {
      const selectedRole = getState().authentication.selectedRole;
      const id = getState().authentication.userData.id;
      const response = await axiosClient.post(`/transactions/withdraws/wire`, {
        amount: amount,
        instructions: instructions,
        is_instructions_saved: instructionsSave,
      });
      if (selectedRole === "Referrer") {
        dispatch(getAffiliateStats({ id: id }));
      } else {
        dispatch(getRewards());
      }
      dispatch(getMyTransactions({ page: 1 }));
      return response.data;
    } catch (err) {
      console.log(err);
      console.log(err?.response?.data?.message);
      return err?.response?.data?.message;
    }
  }
);
export const withdrawPayoneerRequest = createAsyncThunk(
  "payments/withdrawPayoneerRequest",
  async ({ amount, email, emailSave }, { dispatch, getState }) => {
    try {
      const selectedRole = getState().authentication.selectedRole;
      const id = getState().authentication.userData.id;
      const response = await axiosClient.post(
        `/transactions/withdraws/payoneer`,
        {
          email,
          amount,
          is_email_saved: emailSave,
        }
      );
      if (selectedRole === "Referrer") {
        dispatch(getAffiliateStats({ id: id }));
      } else {
        dispatch(getRewards());
      }
      dispatch(getMyTransactions({ page: 1 }));

      return response.data;
    } catch (err) {
      console.log(err);
      console.log(err?.response?.data?.message);
      return err?.response?.data?.message;
    }
  }
);

export const getAdminWithdraws = createAsyncThunk(
  "payments/getAdminWithdraws",
  async ({ page, type }) => {
    try {
      const response = await axiosClient.get(
        `/admin/transactions/withdraws?${type && "type=" + type}`
      );
      return response.data;
    } catch (err) {
      return err.response.data.message;
    }
  }
);
export const getAdminDeposits = createAsyncThunk(
  "payments/getAdminDeposits",
  async ({ page, type }) => {
    try {
      const response = await axiosClient.get(
        `/admin/transactions/deposits?page=${page || 1}${
          type && "&type=" + type
        }`
      );
      return response.data;
    } catch (err) {
      return err.response.data.message;
    }
  }
);

export const updateDepositStatus = createAsyncThunk(
  "payments/updateDepositStatus",
  async ({ id, type, requestNumber, cancellationReason }, { dispatch }) => {
    try {
      let body;
      if (requestNumber) {
        body = {
          ...body,
          request_number: requestNumber,
        };
      }
      if (cancellationReason) {
        body = {
          ...body,
          cancellation_reason: cancellationReason,
        };
      }
      const response = await axiosClient.put(
        `/admin/transactions/deposits/${id}/${type}`,
        body
      );
      dispatch(setToast({ type: "success", message: response.message }));

      return response.data;
    } catch (err) {
      dispatch(
        setToast({
          type: "error",
          message: err.response.data.message,
        })
      );

      return err.response.data;
    }
  }
);
export const updateWithdrawStatus = createAsyncThunk(
  "payments/updateWithdrawStatus",
  async ({ id, type, requestNumber, cancellationReason }, { dispatch }) => {
    try {
      let body;
      if (requestNumber) {
        body = {
          ...body,
          request_number: requestNumber,
        };
      }
      if (cancellationReason) {
        body = {
          ...body,
          cancellation_reason: cancellationReason,
        };
      }
      const response = await axiosClient.put(
        `/admin/transactions/withdraws/${id}/${type}`,
        body
      );
      dispatch(setToast({ type: "success", message: response.message }));

      return response.data;
    } catch (err) {
      dispatch(
        setToast({
          type: "error",
          message: err.response.data.message,
        })
      );

      return err.response.data;
    }
  }
);
