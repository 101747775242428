import { lazy } from "react";
import { lazyRetry } from "../../../utility/Utils";

const ProposalPage = lazy(() =>
  lazyRetry(() => import("../pages/ProposalPage/ProposalPage"))
);

const ProposalCreate = lazy(() =>
  lazyRetry(() => import("../pages/ProposalCreate/ProposalCreate"))
);

const proposalRoutes = [
  {
    path: "/project/:id/proposals/:proposalId",
    element: <ProposalPage />,
    meta: {
      private: true,
      roles: ["Project Owner"],
    },
  },
  {
    path: "/project/:id/proposals/:proposalId/edit",
    element: <ProposalCreate isEdit={true} />,
    meta: {
      private: true,
      roles: ["Project Manager"],
    },
  },
  {
    path: "/create-proposal/:id",
    element: <ProposalCreate />,
    meta: {
      private: true,
      roles: ["Project Manager"],
    },
  },
];

export default proposalRoutes;
