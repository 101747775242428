import { Navigate } from "react-router-dom";

const RoleMiddleware = ({ route, selectedRole, children }) => {
  if (route.meta.roles) {
    for (let i = 0; i < route.meta.roles.length; i++) {
      if (route.meta.roles[i] === selectedRole) {
        return children;
      }
    }
    return <Navigate to="/auth/not-auth" />;
  }
  return children;
};

export default RoleMiddleware;
